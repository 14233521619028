/*
 * Program: MroNotes.jsx
 * Application: MroNotes
 * Option: to List out of MroNotes Information
 * Developer: Chandra Shekhar
 * Date: 18-04-2022
 */
import React, { Component } from "react";
import Moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import DailyLogService from "../../services/DailyLogService";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomToolbar from "../CustomToolbar";
import LinkModal from "../LinkModal";
import FileWidget from "../fileUploader/FileWidget";
import placeholderImage from "./../../assets/images/image-placeholder.png";
import ActionItemService from "../../services/ActionItemService";
import DeleteImageModal from "../DeleteImageModal";
import ReactImageMagnify from "react-image-magnify";

export class DailyLogDetail extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.quillRef = React.createRef();
    this.modalQuillRef = React.createRef();
    this.state = {
      showModal: false,
      showModalImage: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      showAlertModal1: false,
      alertModalType1: "",
      alertModalTitle1: "",
      alertModalMessage1: "",
      input: {},
      errors: {},
      isSubmit: false,
      total_record: 0,
      records: [],
      slug: this.props.match.params.slug,
      selectedPropertyList: [],
      propertyList: [],
      property_slug: "",
      inputDataDailyLog: {},
      note: "",
      daily_log_note: "",
      activeEditor: null,
      dailyLogImages: [],
      deletedFileList: [],
    };
    this.saveDailyLogs = this.saveDailyLogs.bind(this);
    this.saveDailyLogThread = this.saveDailyLogThread.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeModal = this.handleChangeModal.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleLink = this.handleLink.bind(this);
    this.handleModalEditorLink = this.handleModalEditorLink.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.opendeleteDailyLogModal = this.opendeleteDailyLogModal.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getPropertyList();
    if (this.state.slug === undefined) {
      const quill = this.quillRef.current
        ? this.quillRef.current.getEditor()
        : null;
      if (quill) {
        quill.root.removeEventListener("click", this.handleLinkClick);
      }

      const modalQuill = this.modalQuillRef.current
        ? this.modalQuillRef.current.getEditor()
        : null;
      if (modalQuill) {
        modalQuill.root.removeEventListener("click", this.handleLinkClick);
      }
    }
  }

  limitTextTo50Words(htmlContent) {
    // Parse HTML content to extract text
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const textContent = doc.body.textContent || "";

    // Split the text content into words and limit to 50 words
    const words = textContent.split(/\s+/).slice(0, 50).join(" ");

    return words;
  }

  componentWillUnmount() {
    if (this.state.slug === undefined) {
      const quill = this.quillRef.current
        ? this.quillRef.current.getEditor()
        : null;
      if (quill) {
        quill.root.removeEventListener("click", this.handleLinkClick);
      }

      const modalQuill = this.modalQuillRef.current
        ? this.modalQuillRef.current.getEditor()
        : null;
      if (modalQuill) {
        modalQuill.root.removeEventListener("click", this.handleLinkClick);
      }
    }
  }

  handleLink() {
    const quill = this.quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
        activeEditor: "main",
      });
    }
  }

  handleModalEditorLink() {
    const quill = this.modalQuillRef.current?.getEditor();
    const range = quill?.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range,
        url: "",
        text: "",
        isEditing: false,
        activeEditor: "modal",
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const { range, activeEditor } = this.state;
    let quill = null;

    if (activeEditor === "main") {
      quill = this.quillRef.current?.getEditor();
    } else if (activeEditor === "modal") {
      quill = this.modalQuillRef.current?.getEditor();
    }

    if (range && url && text && quill) {
      quill.deleteText(range.index, range.length);
      quill.insertText(range.index, text, "link", url);
      quill.setSelection(range.index + text.length);
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      activeEditor: null,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      event.preventDefault();
      const quill = this.quillRef.current.getEditor();
      const range = quill.getSelection();
      const url = event.target.getAttribute("href");
      const text = event.target.innerText;

      // Prevent Quill's tooltip from showing
      quill.blur();

      this.setState({
        showModal: true,
        range: { index: range.index, length: text.length },
        url,
        text,
        isEditing: true,
      });
    }
  }

  handleModalRemove() {
    const quill = this.quillRef.current.getEditor();
    const textLength = quill.getLength();
    // let range = { index: 0, length: textLength };

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  async getData() {
    this.setState({ loading: true });
    let list = [];
    let res = await DailyLogService.getDetails(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
      list.title = list ? list.title : "";
      list.note = list ? list.note : "";
      list.full_name = list && list.user ? list.user.full_name : "";
      list.created_at = list ? list.created_at : "";
      list.is_thread_enable = list ? list.is_thread_enable : "";
      list.daily_log_thread = list ? list.daily_log_thread : [];
      list.propertyName =
        list && list.property ? list.property.property_name : [];
      list.dailyLogImages = list && list.images ? list.images : [];
    }
    this.setState(list);
    let daily_log_thread_length =
      list && list.daily_log_thread ? list.daily_log_thread.length : 0;
    this.setState({ daily_log_thread_length: daily_log_thread_length });
  }
  async saveDailyLogThread() {
    this.setState({
      alertModalMessage: "",
    });
    //Image Section Start

    const FileWidgetObj = new FileWidget();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
    } else {
      this.setState({ loading: false, isSubmit: false });
    }
    const output = await FileWidgetObj.fileUploadFun("daily_log_thread_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid() && this.customValidate()) {
        let inputDataDailyLog = {
          title: this.state.daily_log_title,
          comment: this.state.daily_log_note,
          dailyLog_slug: this.props.match.params.slug,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
        };
        this.setState({ loading: true, isSubmit: true });
        let res = await DailyLogService.createDailyLogThread(inputDataDailyLog);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal1: true,
            addModal: false,
            daily_log_title : '',
            daily_log_note : '', 
            isSubmit:false,
            alertModalType1: "success",
            alertModalTitle1: "Success",
            alertModalMessage1: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/daily-log/" + this.state.slug),
            global.redirect_time
          );
          setTimeout(() => {
            this.setState({ showAlertModal1: false });
          }, global.redirect_time);
          this.getData();
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType1: "error",
            alertModalTitle1: res.data.message ? res.data.message : "Error!",
            alertModalMessage1:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.validator.showMessages();
      }
    } else {
      this.setState({
        showAlertModal1: true,
        alertModalType1: "error",
        alertModalTitle1: "Error!",
        alertModalMessage1: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal1: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
  }

  async deleteImage(slug) {
    this.setState({ loading: true, isSubmit: true });
    let res = await ActionItemService.deleteImage(slug);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
        showModalImage: false,
      });
      this.getData();
      //setTimeout(() => this.props.history.push("/mros"), global.redirect_time);
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isSubmit: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  openModal(data) {
    this.setState({
      addModal: true,
      showAlertModal: false,
      errors: {},
    });
    setTimeout(() => {
      this.nameInput.focus();
    }, 0);
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    this.setState({ loading: true });
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    this.setState({ propertyList: propertyListData });
    this.setState({ loading: false });
  }
  saveDailyLogs = async (e) => {
    e.preventDefault();
    this.setState({
      alertModalMessage: "",
      loading: true,
      isSubmit: true,
    });
    const FileWidgetObj = new FileWidget();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
    } else {
      this.setState({ loading: false, isSubmit: false });
    }
    const output = await FileWidgetObj.fileUploadFun("daily_log_form");
    let inputFilesData = [];
    if (output.status !== false) {
      if (output.response[0].filesData) {
        if (output.response[0].filesData.length > 0) {
          inputFilesData = {
            availableFiles: output.response,
            deletedFiles: this.state.deletedFileList,
          };
        }
      }
      if (this.validator.allValid() && this.customValidate()) {
        this.setState({ loading: true, isSubmit: true });
        let inputData = {
          title: this.state.title,
          note: this.state.note,
          property_slug: this.state.property_slug,
          imageData: inputFilesData,
          deletedFiles: this.state.deletedFileList,
        };
        let res = await DailyLogService.createDailyLog(inputData);
        let resSlug = res?.data?.slug;
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            slug : resSlug,
            daily_log_title : '',
            daily_log_note : '', 
            isSubmit:false,
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/daily-log/" + resSlug),
            global.redirect_time
          );
          setTimeout(() => {
            this.setState({ showAlertModal: false });
          }, global.redirect_time);
          this.getData();
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        this.validator.showMessages();
      }
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: "Error!",
        alertModalMessage: output.response[0].validationMessage[0][0],
        loading: false,
        isSubmit: false,
      });
      setTimeout(
        () =>
          this.setState({
            showAlertModal: false,
            loading: false,
            isSubmit: false,
          }),
        global.alert_close_time
      );
    }
  };

  handleChange(value) {
    this.setState({ note: value }, () => {
      this.customValidate();
    });
  }

  handleChangeModal(value) {
    this.setState({ daily_log_note: value }, () => {
      this.customValidate();
    });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  // Property Change Handler
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }

    this.setState({
      selectedPropertyList: value,
      property_slug: value.value,
    });
    this.setState({ confirm_back: 1 });
  }
  closeModal() {
    this.validator.hideMessages();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.setState({ showAlertModal: false });
    this.setState({ addModal: false });
    this.setState({
      building_name: "",
      sort_order: "",
      errors: {},
    });
  }
  customValidate() {
    let isValid = true;
    return isValid;
  }

  toggleModal(slug) {
    this.setState({
      showModalImage: !this.state.showModalImage,
      imageSlug: slug,
    });
  }

  opendeleteDailyLogModal(slug) {
    this.setState({ deleteDailyLogModal: true });
  }

  closedeleteDailyLogModal() {
    this.setState({ deleteDailyLogModal: false });
  }

  // Delete Method Start
  async deleteDailyLog(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await DailyLogService.deleteDailyLog(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteDailyLogModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/daily-logs/"),
        global.redirect_time
      );
    } else {
      this.closedeleteDailyLogModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    const modules = {
      toolbar: {
        container: "#toolbar",
        handlers: {
          link: this.handleLink,
        },
      },
    };
    const modulesmodal = {
      toolbar: {
        container: "#toolbar-modal",
        handlers: {
          link: this.handleModalEditorLink,
        },
      },
    };
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-md-12 p-0">
                <div className="dashbaord-pageHeadertitle heading_alignment">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Daily Log</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        <Link to="/daily-logs">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                    </div>
                    <span>Daily Log Detail</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-0 pr-0">
              <Alerts
                show={this.state.showAlertModal}
                type={this.state.alertModalType}
                title={this.state.alertModalTitle}
                message={this.state.alertModalMessage}
              />
            </div>
            {/* for inspection info */}
            <div className="col-md-12 mg-t-0 p-5 dashboardRightcard">
              <div className="tabNavigation">
              <div className="row row-xs align-items-center justify-content-end flex-wrap m-0 pd-b-0">
                <div className="col-md-12 btnNavigate d-flex justify-content-end align-items-center flex-wrap-bothBtn p-0">
                    {this.state.is_thread_enable === 1 && (
                        <Link to="#" className="float-right">
                          <button
                            type="button"
                            className="gf-primary-btn-small-one"
                            onClick={this.openModal}
                          >
                            Add to Daily Log
                          </button>
                      </Link>
                    )}

                    {this.state.slug !== "add" && (
                      this.state.slug !== undefined &&
                      global.userPermissions.checkPermission("daily_logs-delete") && (
                        <button
                          type="button"
                          onClick={() => this.opendeleteDailyLogModal(this.state.slug)}
                          className="btn btn-danger btn-block common_delete_btn ml-2"
                        >
                          Delete Daily Log
                        </button>
                      )
                    )}
                </div>
              </div>
                <form
                  id="action_item_form"
                  onSubmit={this.onSubmitFun}
                  method="post"
                  encType="multipart/form-data"
                >
                  <div className="m-0">
                    <div className="mg-t-0 mg-b-0">
                      <div className="az-content-label titleLable">Info</div>
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                        {this.state.slug !== "add" ? (
                          <>
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left">
                                Title
                              </label>
                              <p>{this.state.title} </p>
                            </div>
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left">
                                Property
                              </label>
                              <p>{this.state.propertyName} </p>
                            </div>
                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left">
                                Date Time
                              </label>
                              <p>
                                {Moment(this.state.created_at).format(
                                  "DD MMM, YY - hh:mm A"
                                )}
                              </p>
                            </div>

                            <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left">
                                Technician
                              </label>
                              <p>{this.state.full_name} </p>
                            </div>
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <label className="form-label text-left">
                                Note
                              </label>
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: this.modifyTaskName(
                                      this.state.note
                                    ),
                                  }}
                                />
                              </p>
                            </div>
                            {/* Image Preview */}
                            <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                              <div className="daily_log_info_imgbox">
                                {this.state.dailyLogImages
                                  ? this.state.dailyLogImages.map(
                                      (record, i) => {
                                        return (
                                          <div key={i} className="img_close">
                                            <a
                                              href={
                                                record.uri_web
                                                  ? record.uri_web
                                                  : ""
                                              }
                                              rel="noreferrer"
                                              target="_blank"
                                            >
                                              {/* <img
                                                src={
                                                  record.uri_web
                                                    ? record.uri_web
                                                    : ""
                                                }
                                                className="brand_list_image"
                                                alt={record.slug}
                                                key={"image" + i}
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    placeholderImage;
                                                }}
                                              /> */}
                                              <div className="pop-up-image-megnify">
                                              <ReactImageMagnify
                                                {...{
                                                  smallImage: {
                                                    alt: record.slug,
                                                    isFluidWidth: true,
                                                    src: record.uri_web ? record.uri_web : "",
                                                    onError: ({ currentTarget }) => {
                                                      currentTarget.onerror = null; // prevents looping
                                                      currentTarget.src = placeholderImage;
                                                    },
                                                  },
                                                  largeImage: {
                                                    src: record.uri_web ? record.uri_web : "",
                                                    width: 200,
                                                    height: 200,
                                                  },
                                                  enlargedImagePosition: 'beside',
                                                  enlargedImageContainerDimensions: {
                                                    width: '300%',
                                                    height: '300%',
                                                  },
                                                  className: 'custom-image-magnify',
                                                }}
                                              />
                                              </div>
                                            </a>
                                            {global.userPermissions.checkPermission(
                                              "daily_logs-delete"
                                            ) && (
                                              <>
                                                <button
                                                  type="button"
                                                  key={"button" + i}
                                                  className="btn btn-danger btn_remove"
                                                  onClick={() =>
                                                    this.toggleModal(
                                                      record.slug
                                                    )
                                                  }
                                                >
                                                  <span className="fa fa-times"></span>
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        );
                                      }
                                    )
                                  : ""}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <form
                                id="daily_log_form"
                                onSubmit={this.onSubmitFun}
                                method="post"
                                encType="multipart/form-data"
                              >
                                <div className="row">
                                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                                    <label className="form-label text-left">
                                      Title
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="title"
                                      value={this.state.title}
                                      maxLength="50"
                                      tabIndex="1"
                                      autoComplete="off"
                                    />
                                    {this.state.errors.title ? (
                                      <div className="text-danger">
                                        {this.state.errors.title}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "title",
                                        this.state.title,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                                    <label className="form-label text-left mg-b-5">
                                      Property
                                    </label>

                                    <Select
                                      className="multiselect new_drop"
                                      menuPlacement="auto"
                                      value={this.state.selectedPropertyList}
                                      options={this.state.propertyList}
                                      onChange={(value) =>
                                        this.handleChangeProperty(value)
                                      }
                                      defaultValue={
                                        this.state.selectedPropertyList
                                      }
                                      autoComplete="off"
                                      getOptionValue={(option) =>
                                        `${option.label}`
                                      }
                                      styles={customStyles}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary75: "#808080",
                                          primary50: "#808080",
                                          primary25: "#808080",
                                        },
                                      })}
                                    />
                                    {this.state.errors.property_slug ? (
                                      <div className="text-danger">
                                        {this.state.errors.property_slug}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "Property",
                                        this.state.property_slug,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                                    <label className="form-label text-left">
                                      Note
                                    </label>
                                    {/* <Form.Control
                              as="textarea"
                              onChange={this.changeHandler}
                              name="note"
                              value={this.state.note}
                              // maxLength="2000"
                              tabIndex="1"
                              autoComplete="off"
                              className="daily_log_text"
                              placeholder="Type here..."
                            />
                            {this.state.errors.note ? (
                              <div className="text-danger">
                                {this.state.errors.note}
                              </div>
                            ) : (
                              this.validator.message(
                                "note",
                                this.state.note,
                                "required",
                                { className: "text-danger" }
                              )
                            )} */}
                                    <CustomToolbar />
                                    <ReactQuill
                                      ref={this.quillRef}
                                      theme="snow"
                                      name="note"
                                      value={this.state.note || ""}
                                      onChange={this.handleChange}
                                      modules={modules}
                                      formats={global.formats}
                                      className="custom_editor_style"
                                    />
                                    {this.state.showModal && (
                                      <LinkModal
                                        onClose={this.handleModalClose}
                                        onSubmit={this.handleModalSubmit}
                                        onRemove={this.handleModalRemove}
                                        url={this.state.url}
                                        text={this.state.text}
                                        isEditing={this.state.isEditing}
                                        showModal={this.state.showModal}
                                      />
                                    )}
                                    {this.state.errors.note ? (
                                      <div className="text-danger">
                                        {this.state.errors.note}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "note",
                                        this.state.note,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  {/* Images Section Start */}
                                  <div className="col-md-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-20 pd-r-70">
                                    <div className="row m-0">
                                      <FileWidget
                                        fieldName="file_name"
                                        fieldTitle=""
                                        fieldLabel=""
                                        checkValidation="true"
                                        allowedType="jpeg,jpg,png,JPEG,JPG,PNG,GIF,gif"
                                        maxFileSize={5}
                                        maxFileSizeType="mb"
                                        maxFileAllowed={10}
                                        uploadOn="s3"
                                        refTableId="6"
                                        displayFileName=""
                                        documentTypeSlug=""
                                        mediaType={1}
                                        sortOrder=""
                                        statusId=""
                                        helpText="To download sample file."
                                        sampleFileUrl="/sample.jpg"
                                        drag="false"
                                        dragText=""
                                        inputClassName="form-control"
                                        labelClassName="form-label"
                                        formID="daily_log_form"
                                        uploadType="multiple"
                                        resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                                        destinationFolder="daily-log"
                                        uploadedFiles={
                                          this.state.profile_image2
                                        }
                                        deletedFiles={
                                          this.state.deletedFileList
                                        }
                                        acceptOnly="image/*"
                                        displayPreviewStyle="vertical"
                                        newRelatedFilesDescChange={
                                          this.newRelatedFilesDescChange
                                        }
                                        currentFile={
                                          this.state.dailyLogImages.length
                                        }
                                      />
                                    </div>
                                    {/* Image Preview end*/}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </>
                        )}
                      </div>

                      {this.state.is_thread_enable === 1 ? (
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <Link to={"#"} className="float-right">
                              <button
                                type="button"
                                className="gf-primary-btn-small-one "
                                onClick={this.openModal}
                              >
                               Add to Daily Log
                              </button>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.daily_log_thread_length > 0 ? (
                        <>
                          <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                            <div className="col-md-12 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-0 pd-r-70">
                              <div className="az-content-label titleLable pl-0">
                                <span className="pd-t-10 d-block">
                                  Daily Log Thread
                                </span>
                              </div>
                              <hr className="mg-t-30 mg-b-20 pd-t-1 pd-b-3 w-max-100"></hr>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.is_thread_enable == 1 ? (
                        <>
                          <Modal
                            show={this.state.addModal}
                            centered
                            className="daily_log_modal"
                          >
                            <Modal.Body className="width_402 p-4 border_radius_14">
                            <Alerts
                              show={this.state.showAlertModal1}
                              type={this.state.alertModalType1}
                              title={this.state.alertModalTitle1}
                              message={this.state.alertModalMessage1}
                            />
                              <span
                                className="welcomeClose"
                                onClick={() => this.closeModal()}
                              >
                                {global.closee}
                              </span>

                              <h3 className="text-center">
                                {"Add Daily Logs"}
                              </h3>
                              <label>Title</label>
                              <form
                                id="daily_log_thread_form"
                                onSubmit={this.onSubmitFun}
                                method="post"
                                encType="multipart/form-data"
                              >
                                <Form.Control
                                  onChange={this.changeHandler}
                                  ref={(input) => {
                                    this.nameInput = input;
                                  }}
                                  name="daily_log_title"
                                  value={this.state.daily_log_title}
                                  className="form-control max_width_100 "
                                  id="inlineFormInput"
                                  type="text"
                                  maxLength="50"
                                  autoComplete="off"
                                  autoFocus={true}
                                />
                                {this.state.errors.daily_log_title ? (
                                  <div className="text-danger">
                                    {this.state.errors.daily_log_title}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "title",
                                    this.state.daily_log_title,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                                <label className="form-label text-left mg-b-0 mg-t-15">
                                  Note
                                </label>
                                {/* <Form.Control
                        as="textarea"
                        onChange={this.changeHandler}
                        name="daily_log_note"
                        value={this.state.daily_log_note}
                        className="form-control max_width_100 daily_log_text"
                        type="text"
                        // maxLength="2000"
                        placeholder="Type here..."
                        /> */}
                                <div id="toolbar-modal">
                                  <button className="ql-bold"></button>
                                  <button className="ql-italic"></button>
                                  <button className="ql-underline"></button>
                                  <button className="ql-link"></button>
                                </div>
                                <ReactQuill
                                  ref={this.modalQuillRef}
                                  theme="snow"
                                  name="daily_log_note"
                                  value={this.state.daily_log_note || ""}
                                  onChange={this.handleChangeModal}
                                  modules={modulesmodal}
                                  formats={global.formats}
                                  className="custom_editor_style"
                                />
                                {this.state.showModal && (
                                  <LinkModal
                                    onClose={this.handleModalClose}
                                    onSubmit={this.handleModalSubmit}
                                    onRemove={this.handleModalRemove}
                                    url={this.state.url}
                                    text={this.state.text}
                                    isEditing={this.state.isEditing}
                                    showModal={this.state.showModal}
                                  />
                                )}
                                {this.state.errors.daily_log_note ? (
                                  <div className="text-danger">
                                    {this.state.errors.daily_log_note}
                                  </div>
                                ) : (
                                  this.validator.message(
                                    "notes",
                                    this.state.daily_log_note,
                                    "required",
                                    { className: "text-danger" }
                                  )
                                )}
                                <div className="daily_log_image_upload">
                                  <div className="row m-0">
                                    <FileWidget
                                      fieldName="file_name"
                                      fieldTitle=""
                                      fieldLabel=""
                                      checkValidation="true"
                                      allowedType="jpeg,jpg,png,JPEG,JPG,PNG,GIF,gif"
                                      maxFileSize={5}
                                      maxFileSizeType="mb"
                                      maxFileAllowed={10}
                                      uploadOn="s3"
                                      refTableId="7"
                                      displayFileName=""
                                      documentTypeSlug=""
                                      mediaType={1}
                                      sortOrder=""
                                      statusId=""
                                      helpText="To download sample file."
                                      sampleFileUrl="/sample.jpg"
                                      drag="false"
                                      dragText=""
                                      inputClassName="form-control"
                                      labelClassName="form-label"
                                      formID="daily_log_thread_form"
                                      uploadType="multiple"
                                      resizeOptions="width:200,height:200,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:300,height:300,maintainAspectRatio:false,resizeDestinationFolder:thumb22"
                                      destinationFolder="daily-log-threads"
                                      uploadedFiles={this.state.profile_image2}
                                      deletedFiles={this.state.deletedFileList}
                                      acceptOnly="image/*"
                                      displayPreviewStyle="vertical"
                                      newRelatedFilesDescChange={
                                        this.newRelatedFilesDescChange
                                      }
                                      currentFile={
                                        this.state.daily_log_thread_length
                                      }
                                    />
                                  </div>
                                  {/* Image Preview end*/}
                                </div>
                              </form>
                              <div className="d-flex cnt_center mt-3 row">
                                <button
                                  type="button"
                                  className="m-0 btn btn-outline-light btn-block"
                                  onClick={() => this.closeModal()}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="m-0 btn btn btn-success btn-block"
                                  onClick={this.saveDailyLogThread}
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  {this.state.isSubmit ? global.loader : "ADD"}
                                </button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="row row-xs align-items-center flex-wrap m-0 pd-t-15 pd-b-0 pd-l-140 pd-r-80">
                        <div className="col-md-12 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                          {this.state.slug === "add" ? (
                            <div className="d-flex align-items-center">
                              <Link to="/daily-logs">
                                <button
                                  className="gf-btn-lg-outline mg-r-7"
                                  type="button"
                                  disabled={this.state.isSubmit ? true : false}
                                >
                                  Cancel
                                </button>
                              </Link>
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveDailyLogs}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit
                                  ? global.loader
                                  : this.state.slug !== undefined
                                  ? "Add"
                                  : ""}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* for task */}
                  {this.state.slug !== "add" ? (
                    <>
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70 daily_logy_cnt">
                        <div className="col-md-12 p-0">
                          <div className="brandSection">
                            <div className="brandList dashboardRightcard d-flex flex-wrap justify-content-between align-items-center pt-0 pd-l-70 pd-r-70 ">
                              {/* <div className="form-group col-md-3 mb-2">
                        <h3 className="title_daily_log">Daily Logs</h3>
                      </div> */}
                              {this.state.daily_log_thread
                                ? this.state.daily_log_thread.map(
                                    (element, index) => (
                                      <div
                                        className="form-group col-md-12 pd-l-70 pd-r-70 "
                                        key={index}
                                      >
                                        <div className="az-content-label titleLable pl-0 mg-cust-b-5">
                                          {element.title ? element.title : ""}
                                        </div>
                                        {/* <p className="az-daily-mid_text p-0 mg-cust-b-5">15 July | 05:09 PM BY CHRIS ADMIN</p> */}
                                        <p className="az-daily-mid_text p-0 mg-cust-b-5">
                                          {Moment(element.created_at).format(
                                            "DD MMM, YY - hh:mm A"
                                          )}{" "}
                                          |{" "}
                                          {element.created_by &&
                                          element.created_by.full_name
                                            ? element.created_by.full_name
                                            : ""}
                                        </p>
                                        <p className="az-daily-para-text">
                                          {element.comment ? (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: this.modifyTaskName(
                                                  element.comment
                                                ),
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {/* Image Preview */}
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="daily_log_info_imgbox">
                                                {element.images
                                                  ? element.images.map(
                                                      (record, i) => {
                                                        return (
                                                          <div
                                                            key={i}
                                                            className="img_close"
                                                          >
                                                            <a
                                                              href={
                                                                record.uri_web
                                                                  ? record.uri_web
                                                                  : ""
                                                              }
                                                              rel="noreferrer"
                                                              target="_blank"
                                                            >
                                                              {/* <img
                                                                src={
                                                                  record.uri_web
                                                                    ? record.uri_web
                                                                    : ""
                                                                }
                                                                className="brand_list_image"
                                                                alt={
                                                                  record.slug
                                                                }
                                                                key={
                                                                  "image" + i
                                                                }
                                                                onError={({
                                                                  currentTarget,
                                                                }) => {
                                                                  currentTarget.onerror =
                                                                    null; // prevents looping
                                                                  currentTarget.src =
                                                                    placeholderImage;
                                                                }}
                                                              /> */}
                                                              <div className="pop-up-image-megnify">
                                                                <ReactImageMagnify
                                                                  {...{
                                                                    smallImage: {
                                                                      alt: record.slug,
                                                                      isFluidWidth: true,
                                                                      src: record.uri_web ? record.uri_web : "",
                                                                      onError: ({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = placeholderImage;
                                                                      },
                                                                    },
                                                                    largeImage: {
                                                                      src: record.uri_web ? record.uri_web : "",
                                                                      width: 200,
                                                                      height: 200,
                                                                    },
                                                                    enlargedImageContainerDimensions: {
                                                                      width: '300%',
                                                                      height: '300%',
                                                                    },
                                                                    enlargedImagePosition: 'beside',
                                                                    className: 'custom-image-magnify',
                                                                  }}
                                                                />
                                                              </div>
                                                            </a>
                                                            {global.userPermissions.checkPermission(
                                                              "daily_logs-delete"
                                                            ) && (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  key={
                                                                    "button" + i
                                                                  }
                                                                  className="btn btn-danger btn_remove"
                                                                  onClick={() =>
                                                                    this.toggleModal(
                                                                      record.slug
                                                                    )
                                                                  }
                                                                >
                                                                  <span className="fa fa-times"></span>
                                                                </button>
                                                              </>
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                        </p>
                                        <hr className="mg-t-30 mg-b-20 mr-0 ml-0"></hr>
                                      </div>
                                    )
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </form>
                {this.state.slug !== "add" ? (
                  <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-80">
                    <div className="col-md-6 btnNavigate d-flex align-items-center flex-wrap-bothBtn p-0">
                      {global.userPermissions.checkPermission(
                        "daily_logs-delete"
                      ) &&
                        (this.state.slug !== undefined ? (
                          <>
                            <button
                              type="button"
                              onClick={() => {
                                this.opendeleteDailyLogModal(this.state.slug);
                              }}
                              className="detlet-text mg-r-20"
                            >
                              Delete Daily log
                            </button>
                          </>
                        ) : (
                          ""
                        ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
        {this.state.showModalImage && (
          <DeleteImageModal
            showModalImage={this.state.showModalImage}
            slug={this.state.imageSlug}
            onRemove={() => this.deleteImage(this.state.imageSlug)}
          />
        )}
        <Modal show={this.state.deleteDailyLogModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Daily Log?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this Daily Log? This process cannot
                be undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteDailyLogModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteDailyLog(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default DailyLogDetail;

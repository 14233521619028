import React, { Component } from "react";
import { Link } from "react-router-dom";
import back from "./../../assets/images/back.svg";
import AuditTemplateService from "../../services/AuditTemplateService";
import { Button } from "react-bootstrap";
import AuditService from "../../services/AuditService";
import Collapsible from "react-collapsible";

export class StartAuditList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cheklist_slug: this.props.match.params.checklist_slug,
      property_slug: this.props.match.params.property_slug,
      resData: [],
      openCollapsibles: [], // Changed to an array
    };
  }

  componentDidMount() {
    this.getChecklistData();
  }

  handleToggle = (index) => {
    this.setState((prevState) => {
      const openCollapsibles = [...prevState.openCollapsibles];
      openCollapsibles[index] = !openCollapsibles[index]; // Toggle the state for the clicked category
      return { openCollapsibles };
    });
  };

  getChecklistData = async (e) => {
    var checklistSlug = this.state.cheklist_slug ?? "";
    if (checklistSlug != undefined) {
      var resData = [];
      let res = await AuditTemplateService.getAuditTemplateRowBySlug(
        checklistSlug
      );
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data.data : {};
      }
      this.setState({
        category: resData.category,
        checklist_name: resData.audit_name,
        checklist_slug: resData.slug ? resData.slug : "",
        openCollapsibles: Array(resData.category?.length || 0).fill(true),
      });

      localStorage.setItem("ChecklistName", this.state.checklist_name);
    }
  };

  modifyTaskName(taskName) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = taskName;

    const links = tempElement.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return tempElement.innerHTML;
  }

  startAudit = async (e) => {
    localStorage.removeItem("auditCategory");
    let inputData = {
      property_slug: this.state.property_slug,
      template_slug: this.state.checklist_slug,
      user_slug: global.userData.slug,
      in_time: this.state.in_time,
      category: [],
      out_time: null,
      is_image: false,
      status_id: 1,
    };
    if (inputData != undefined) {
      let res = await AuditService.createAudit(inputData);
      if (global.successStatus.includes(res.status)) {
        let responceData = res.data && res.data.data ? res.data.data : [];
        this.setState({ audit_slug: responceData.slug });
        window.open(
          `/audit-perform/${this.state.property_slug}/${this.state.checklist_slug}/${this.state.audit_slug}`,
          "_self"
        );
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
    }
  };

  render() {
    const { openCollapsibles } = this.state;
    return (
      <div>
        <main className="start_inspectionlist_section">
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle heading_alignment">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        Audit Checklists
                        <Link to="/audit-templates">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                      <span>
                        {localStorage.getItem("propertyNameAuditPerform")}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mg-t-18 p-5 dashboardRightcard mb-5">
              <div className="start_insp_title">
                <div className="insp_innerBox">
                  <h4>
                    {this.state.checklist_name != undefined
                      ? this.state.checklist_name
                      : ""}
                  </h4>
                </div>
                <Button
                  onClick={() => this.startAudit()}
                  className="gf-primary-btn-small-one"
                >
                  Start Audit
                </Button>
              </div>
              {this.state.category !== undefined &&
                this.state.category.length > 0 &&
                this.state.category.map((el, index) => {
                  return (
                    <div
                      className="start_insp_category_list_box"
                      key={"cate_" + index}
                    >
                      {/* Collapsible Component */}
                      <Collapsible
                        key={index}
                        trigger={
                          <div
                            className={`bedroom_title d-flex justify-content-between ${
                              openCollapsibles[index] ? "open" : ""
                            }`}
                            onClick={() => this.handleToggle(index)} // Pass the index
                          >
                            <span className="cate_per_title">
                              {el.category_name}
                            </span>
                            <span
                              className={`Collapsible-arrow ${
                                openCollapsibles[index] ? "open" : ""
                              }`}
                            >
                               <i className={`fa ${openCollapsibles[index]  ? "fa-angle-up"
                                : "fa-angle-down"}`}></i>
                            </span>
                          </div>
                        }
                        open={openCollapsibles[index]} // Control each collapsible individually
                      >
                        {el.task_name
                          ? el.task_name.map((e, indexes) => (
                              <ul key={"task_" + indexes}>
                                <li>
                                  <span className="mr-1">{`${
                                    indexes + 1
                                  }. `}</span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: this.modifyTaskName(e.task_name),
                                    }}
                                  />
                                </li>
                              </ul>
                            ))
                          : ""}
                      </Collapsible>
                    </div>
                  );
                })}
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default StartAuditList;

/*
 * Program: MroNotesService.js
 * Application: MroCategory Service
 * Option: Used for MroCategory Service Information
 * Developer: Chandra Shekhar
 * Date: 18-04-2022
 */
import ApiService from "./ApiService";

class NotificationService {
  getNotificationList(queryString) {
    let endPoint = "notification-list" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
    }
    notificationRead(slug) {
        let endPoint = "read-notification/" + slug;
        let addditionalHeaderData = {};
        let postData = {};   
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }
    notificationReadAll(queryString) {
        let endPoint = "notification-read" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }
    notificationClearAll(queryString) {
        let endPoint = "clear-notification" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
    }

    
    getNotificationListModel(queryString) {
        let endPoint = "notification-list-model" + (queryString ? "?" + queryString : "");
        let addditionalHeaderData = {};
        let postData = {};
        let inputData = {
          endPoint: endPoint,
          addditionalHeaderData: addditionalHeaderData,
          postData: postData,
        };
        let response = ApiService.getCall(inputData);
        return response;
        }

}

export default new NotificationService();

import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
// import Landing from "./dashboard/Landing";
import Signin from "./authentication/Signin";
import ForgotPassword from "./authentication/ForgotPassword";
import ResetPassword from "./authentication/ResetPassword";
import Logout from "./authentication/Logout";
import Page403 from "./authentication/Page403";
import UpdateProfile from "./authentication/UpdateProfile";
import ChangePassword from "../components/authentication/ChangePassword";
// import RolePermissionList from "./authentication/RolePermissionList";
// import RolePermission from "./authentication/RolePermission";
import Company from "./company/Company";
import CompanyForm from "./company/CompanyForm";
import Brand from "./brands/Brand";
import BrandForm from "./brands/BrandForm";
import BrandView from "./brands/BrandView";
import User from "./users/User";
import UserForm from "./users/UserForm";
import UserView from "./users/UserView";
import Properties from "./properties/Properties";
import PropertiesAddForm from "./properties/PropertiesAddForm";
import PropertiesView from "./properties/PropertiesView";
import Buildings from "./properties/Buildings";
import Floors from "./properties/Floor";
import Rooms from "./properties/Rooms";
import CommercialsArea from "./properties/CommercialsArea";
import EquipmentZones from "./properties/EquipmentZones";
import Equipment from "./properties/Equipment";
import EquipmentForm from "./properties/EquipmentForm";
import DailyLog from "./daily-logs/DailyLog";
import DailyLogDetail from "./daily-logs/DailyLogDetail";
// import DailyLogForm from "./daily-logs/DailyLogForm";
import Mro from "./mro/Mro";
import MroCategory from "./mro/MroCategory";
import MroForm from "./mro/MroForm";
import Templates from "./templates/Templates";
import TemplatesForm from "./templates/TemplatesForm";
import Checklist from "./checklist/Checklist";
import ChecklistForm from "./checklist/ChecklistForm";
import ChecklistAdd from "./checklist/ChecklistAdd";
import Inspections from "./inspection/Inspections";
import InspectionForm from "./inspection/InspectionForm";
import TrackerView from "./tracker/TrackerView";
import ActionItem from "./action-item/ActionItem";
import ActionItemForm from "./action-item/ActionItemForm";
import AuditTemplate from "./audit/AuditTemplate";
import AuditTemplateAdd from "./audit/AuditTemplateAdd";
import Audit from "./audit/Audit";
import AuditDetail from "./audit/AuditDetail";
import PrivacyNotice from "./others/PrivacyNotice";
import QuickLink from "./quick-links/QuickLinks";
import QuickLinkManage from "./quick-links/QuickLinkManage";
import QuickLinkForm from "./quick-links/QuickLinkForm";
import Notification from "./notification/Notification";
import AppDownload from "./others/AppDownload";
import InspectionPerform from "./inspection/InspectionPerform";
import AuditPerform from "./audit/AuditPerform";
import AuditResume from "./audit/AuditResume";
import PerformChecklistForm from './checklist/PerformChecklist';
import StartInspectionList from "./checklist/StartInspectionList";
import StartAuditList from "./audit/StartAuditList";
import Roles from "../components/users/Roles";
import RolePermission from "../components/users/RolePermission";

export class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Add routes name to bypass login
      byPassLoginRoutes: [
        "/",
        "/signin",
        "/2fa-signin",
        "/logout",
        "/page-403",
        "/forgot-password",
        "/reset-password",
        "/privacy-notice",
        "/app-download"
      ],
    };
  }
  render() {
    // Check headerToken exist or not
    let pathname = window.location.pathname;
    let items = pathname.split("/").slice(-2);
    let routeName = items[0] ? "/" + items[0] : pathname;
    if (
      !global.headerToken &&
      !this.state.byPassLoginRoutes.includes(routeName)
    ) {
      return <Redirect to="/logout" />;
    }
    return (
      <Suspense fallback="">
        <Switch>
          <Route exact path="/">
            <Redirect to="/signin"></Redirect>
          </Route>
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/update-profile"
            component={() => (
              <UpdateProfile imageUpload={this.props.updateProfileImage} />
            )}
          />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/page-403" component={Page403} />

          {/* <Route
            exact
            path="/role-permission"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermissionList
                : Page403
            }
          />
          <Route
            exact
            path="/role-permission/add"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermission
                : Page403
            }
          /> */}
          {/* <Route
            exact
            path="/role-permission/update/:slug"
            component={
              global.userPermissions.checkPermission("manage-role")
                ? RolePermission
                : Page403
            }
          /> */}
          <Route
            exact
            path="/dashboard"
            component={Dashboard}
          />
          <Route exact path="/company" component={Company} />
          <Route exact path="/company/update/:slug" component={CompanyForm} />
          <Route exact path="/brands" component={Brand} />
          <Route exact path="/brands/add" component={BrandForm} />
          <Route exact path="/brands/view/:slug" component={BrandView} />
          <Route exact path="/brands/update/:slug" component={BrandForm} />
          {/*-------Routes END by Sunny Raj------*/}
          <Route exact path="/user" component={User} />
          <Route exact path="/user/add" component={UserForm} />
          <Route exact path="/user/view/:slug" component={UserView} />
          <Route exact path="/user/update/:slug" component={UserForm} />
           {/*-------Routes started by Sunny Raj------*/}
           
          <Route exact path={["/all-quick-links"]} component={QuickLink} />
          <Route exact path={["/quick-links"]} component={QuickLinkManage} />
          <Route exact path={["/quick-links/add","/quick-links/update/:slug"]} component={QuickLinkForm} />
          {/*-------Routes END by Sunny Raj------*/}
          {/* ====== START:: Routes by Kiran  PROPERTIES  ============== */}
          <Route exact path="/properties" component={Properties} />
          <Route exact path="/properties/add" component={PropertiesAddForm} />
          <Route
            exact
            path="/properties/view/:propertySlug/basic"
            component={PropertiesView}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/buildings"
            component={Buildings}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/floors"
            component={Floors}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/rooms"
            component={Rooms}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/commercials"
            component={CommercialsArea}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/equipmentzone"
            component={EquipmentZones}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/equipment"
            component={Equipment}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/equipment/add"
            component={EquipmentForm}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/equipment/add"
            component={EquipmentForm}
          />
          <Route
            exact
            path="/properties/view/:propertySlug/equipment/update/:slug"
            component={EquipmentForm}
          />

          <Route
            exact
            path="/properties/view/:propertySlug/basic/equipment"
            component={PropertiesView}
          />

          {/* ====== END:: Routes by Kiran   ============== */}

          {/* MRO Routing Start */}
          <Route exact path="/mros" component={Mro} />
          <Route exact path="/mros/filter/:status" component={Mro} />
          <Route exact path="/mros/add" component={MroForm} />
          <Route exact path="/mros/update/:slug" component={MroForm} />
          <Route exact path="/mros/category" component={MroCategory} />
          <Route exact path="/templates" component={Templates} />
          <Route exact path="/templates/add" component={TemplatesForm} />
          <Route
            exact
            path="/templates/update/:slug"
            component={TemplatesForm}
          />
          {/* ====== START:: Routes by Kiran  CHECKLIST  ============== */}
          <Route exact path="/checklists" component={Checklist} />
          <Route exact path="/checklist/add" component={ChecklistAdd} />
          <Route exact path="/checklist/import" component={ChecklistForm} />
          <Route
            exact
            path="/checklist/import/:slug"
            component={ChecklistForm}
          />
          <Route
            exact
            path="/checklist/update/:slug"
            component={ChecklistForm}
          />
          <Route exact path="/checklist/edit/:slug" component={ChecklistAdd} />
          <Route exact path="/daily-logs" component={DailyLog} />
          <Route exact path="/daily-log/:slug" component={DailyLogDetail} />
          {/* <Route exact path="/daily-log/add" component={DailyLogDetail} /> */}
          <Route exact path="/inspections" component={Inspections} />
          <Route
            exact
            path="/inspection/update/:slug"
            component={InspectionForm}
          />
          <Route exact path="/tracker" component={TrackerView} />
          <Route exact path="/action-item" component={ActionItem} />
          <Route exact path="/action-item/filter/:status" component={ActionItem} />
          <Route
            exact
            path="/action-item/update/:slug"
            component={ActionItemForm}
          />
          <Route exact path="/action-item/add" component={ActionItemForm} />
          <Route exact path="/audit-templates" component={AuditTemplate} />
          <Route
            exact
            path="/audit-template/add"
            component={AuditTemplateAdd}
          />
          <Route
            exact
            path="/audit-template/update/:slug"
            component={AuditTemplateAdd}
          />
          <Route exact path="/audits" component={Audit} />
          <Route exact path="/audits/:slug" component={AuditDetail} />
          <Route exact path="/privacy-notice" component={PrivacyNotice} />
          <Route exact path="/app-download" component={AppDownload} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/inspection-perform/:property_slug/:zone_type/:checklist_slug" component={InspectionPerform} />
          <Route exact path="/audit-perform/:property_slug/:checklist_slug/:audit_slug" component={AuditPerform} />
          <Route exact path="/audit-resume/:slug" component={AuditResume} />
          <Route exact path="/perform-checklist" component={PerformChecklistForm} />
          <Route exact path="/start-inspection-list/:slug" component={StartInspectionList} />
          <Route exact path="/start-audit-list/:property_slug/:checklist_slug" component={StartAuditList} />
          <Route exact path="/roles" component={Roles} />
          <Route exact path="/role-permission/:slug" component={RolePermission} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;

import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import DashboardService from "../../services/DashboardService";
import Moment from "moment";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused ? "gray" : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),

  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: "#cdd4e0",
    boxShadow: "none",
    padding: "5px 10px",
    borderRadius: "0",
    "&:hover": {
      borderColor: "#cdd4e0",
    },
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    lineHeight: "normal",
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "#1b1b1b",
  }),

  singleValue: (provided) => ({
    ...provided,
    color: "gray",
  }),
};

export class PropertySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
    this.handleChangePropertyList = this.handleChangePropertyList.bind(this);
  }

  componentDidMount() {
    let startDate, endDate;
        if([4, 3, 6].includes(global.roleTypeId)) {
          startDate = Moment().startOf('year').toDate();
          endDate = Moment().endOf('year').toDate();
        } else {
          startDate = Moment().subtract(6, 'months').toDate(); // 6 months before today
          endDate = new Date();
        }
        const year = new Date().getFullYear();
        const outSidePropertySlug = localStorage.getItem('outSidePorpertySlug') || '';
        const singlePropertySlug = localStorage.getItem('singlePorpertySlug') || '';
        const selectedPropertyName = localStorage.getItem('selectedPropertyName') || '';
        const singlePropertyName = localStorage.getItem('singlePropertyName') || 'All Properties';
    
        const filterValue = `{
          "start_date": "${startDate}",
          "end_date": "${endDate}",
          "year": "${year}",
          "property_slug": "${outSidePropertySlug !== '' ? outSidePropertySlug : singlePropertySlug}",
          "property_name": "${outSidePropertySlug !== '' ? selectedPropertyName : singlePropertyName}"
        }`;
        localStorage.setItem("globleTracker", filterValue);

    this.getProperty();
  }

  async getDashboardData() {
    this.setState({ isLoaded: false });
    let propertySlug =
      localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
      localStorage.getItem("outSidePorpertySlug") !== ""
        ? localStorage.getItem("outSidePorpertySlug")
        : "";
    var queryString = "";
    if (propertySlug) {
      queryString = "property_slug=" + propertySlug;
    }

    var resData = [];
    let res = await DashboardService.getDashboardData(queryString);
    if (global.successStatus.includes(res.status)) {
      resData = res.data ? res.data : [];
      resData.inspection =
        resData.data && resData.data.inspection ? resData.data.inspection : 0;
      resData.audit = resData.data ? resData.data.audit : 0;
      resData.pendingmro = resData.data ? resData.data.pendingmro : 0;
      resData.ongoingmro = resData.data ? resData.data.ongoingmro : 0;
      resData.completemro = resData.data ? resData.data.completemro : 0;
      resData.overduesmro = resData.data ? resData.data.overduesmro : 0;
      resData.cancelledmro = resData.data ? resData.data.cancelledmro : 0;
      resData.pendingaction = resData.data ? resData.data.pendingaction : 0;
      resData.overduesaction = resData.data ? resData.data.overduesaction : 0;
      resData.completedaction = resData.data ? resData.data.completedaction : 0;
      resData.backlogmro = resData.data ? resData.data.backlogmro : 0;
      resData.inspMonth =
        resData.data && resData.data.inspMonth
          ? resData.data.inspMonth.split(",")
          : [];
      resData.auditMonth =
        resData.data && resData.data.auditMonth
          ? resData.data.auditMonth.split(",")
          : [];
      resData.auditlist = resData.data ? resData.data.auditlist : [];
      resData.inspectionList = resData.data ? resData.data.inspectionList : [];
      resData.dailyLogList = resData.data ? resData.data.dailyLogList : [];
      resData.activeUsers = resData.data ? resData.data.activeUsers : "";
      resData.lastAudit = resData.data ? resData.data.lastAudit : [];
      resData.lastInspection = resData.data ? resData.data.lastInspection : [];
    }
    this.setState({
      inspection: resData.inspection,
      audit: resData.audit,
      auditlist: resData.auditlist,
      inspectionList: resData.inspectionList,
      dailyLogList: resData.dailyLogList,
      activeUsers: resData.activeUsers,
      lastAudit: resData.lastAudit,
      lastInspection: resData.lastInspection,
      inspectionData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Inspection",
            data: resData.inspMonth,
            backgroundColor: "#6610f2",
            borderWidth: 1,
            fill: true,
          },
          {
            label: "Audit",
            data: resData.auditMonth,
            backgroundColor: "#00cccc",
            borderWidth: 1,
            fill: true,
          },
        ],
      },
      mroPieData: {
        labels: [
          "Pending",
          "On-going",
          "Overdue",
          "Completed",
          "Backlog",
          "Cancelled",
        ],
        datasets: [
          {
            data: [
              resData.pendingmro,
              resData.ongoingmro,
              resData.overduesmro,
              resData.completemro,
              resData.backlogmro,
              resData.cancelledmro,
            ],

            backgroundColor: [
              "#97a3b9",
              "#007bff",
              "#f56b62",
              "#74cb80",
              "#ff7bd7",
              "#c5a070",
            ],
            options: {
              plugins: {
                datalabels: {
                  display: true,
                  backgroundColor: "#ccc",
                  borderRadius: 3,
                  font: {
                    color: "red",
                    weight: "bold",
                  },
                },
                legend: {
                  position: "right",
                },
                doughnutlabel: {
                  labels: [
                    {
                      text: "550",
                      font: {
                        size: 20,
                        weight: "bold",
                      },
                    },
                    {
                      text: "total",
                    },
                  ],
                },
              },
            },
          },
        ],
      },
      actionItemPieData: {
        labels: ["Pending", "Overdue", "Completed"],
        datasets: [
          {
            data: [
              resData.pendingaction,
              resData.overduesaction,
              resData.completedaction,
            ],
            backgroundColor: ["#97a3b9 ", "#f56b62", "#74cb80"],
            hoverBackgroundColor: ["#7b8cb5"],
          },
        ],
      },
    });
    this.setState({ isLoaded: true });
  }

  async getProperty(queryString = "") {
    this.setState({ isLoaded: false });
    var propertyList = [];
    var propertyListTotal = 0;
    var selectedPropertyList = [{ value: "", label: "All Properties" }];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_name }) => ({
            value: slug,
            label: property_name,
          }))
        : [];
    if (
      localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
      localStorage.getItem("outSidePorpertySlug") !== ""
    ) {
      if (propertyListTotal > 0) {
        propertyList.map((opt, i) => {
          if (opt.slug === localStorage.getItem("outSidePorpertySlug")) {
            selectedPropertyList = [
              {
                value: opt.slug,
                label: opt.property_name,
              },
            ];
          }
        });
      }
    }
    const customOptions = [
      {
        value: "",
        label: "All Properties",
      },
      ...newPropertyList,
    ];
    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: customOptions,
      selectedPropertyList: selectedPropertyList,
      isLoaded: true,
    });
    this.setState({
      propertyList: propertyList,
      propertyListTotal: propertyListTotal,
      newPropertyList: customOptions,
      selectedPropertyList: selectedPropertyList,
    });
  }

  handleChangePropertyList(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    const property_slug_value = value !== null ? value.value : [];
    const property_slug_label = value !== null ? value.label : [];

    this.props.propertySlug(property_slug_value);
    localStorage.setItem("outSidePorpertySlug", property_slug_value);
    localStorage.setItem("selectedPropertyName", property_slug_label);
    localStorage.setItem("singlePorpertySlug", property_slug_value);
    localStorage.setItem("singlePropertyName", property_slug_label);
    /* For Tracker */
    let startDate, endDate;
    if([4, 3, 6].includes(global.roleTypeId)) {
      startDate = Moment().startOf('year').toDate();
      endDate = Moment().endOf('year').toDate();
    } else {
      startDate = Moment().subtract(6, 'months').toDate(); // 6 months before today
      endDate = new Date();
    }
    const filterValue = {
      start_date: startDate,
      end_date: endDate,
      property_slug: property_slug_value,
      property_name: property_slug_label,
    };
    localStorage.setItem("globleTracker", JSON.stringify(filterValue));

    this.setState(
      {
        selectedPropertyList: value,
        property_slug: property_slug_value,
        confirm_back: 1,
        isLoaded: false,
        is_filter: 1,
        formattedData: [`Property: ${property_slug_label}`],
      },
      () => {
        this.getDashboardData();
      }
    );
  }

  render() {
    return (
      <div>
        
          {localStorage.getItem("activePropertyCount") == 1 ? (
            ""
          ) : (
            <>
                <div className="row mb-4">
                  <div className="col-md-4">
                    <div className="dashboard_prop_dropdown">
                      <label className="mg-b-5">Property</label>
                      {this.state.isLoaded ? (
                        <Select
                          className="multiselect new_drop"
                          menuPlacement="auto"
                          value={this.state.selectedPropertyList}
                          options={this.state.newPropertyList}
                          isSearchable={true}
                          onChange={(value) =>
                            this.handleChangePropertyList(value)
                          }
                          defaultValue={this.state.selectedPropertyList}
                          autoComplete="off"
                          getOptionValue={(option) => `${option.label}`}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary75: "#808080",
                              primary50: "#808080",
                              primary25: "#808080",
                            },
                          })}
                        />
                      ) : (
                        <Skeleton height={40} />
                      )}
                    </div>
                  </div>
                </div>
            </>
          )}
      </div>
    );
  }
}

export default PropertySelection;
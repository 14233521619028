////////////////////////////////////////////////////////////
//     							                                      //
//  Program: App.jsx                                      //
//  Application: Components                               //
//  Option: App component                                 //
//  Developer: CV  						                            //
//  Date: 2021-03-01                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Header from "./common/Header";
import HeaderTopBar from "./common/HeaderTop";
import Footer from "./common/Footer";
import OnKeyPressEvent from "./common/OnKeyPressEvent";
import Moment from "moment";

// Set data as a global for common use
global.spinnerLoader = (
  <div className="spinnerLoader">
    <div className="toploader">
      <img src={require("../assets/images/loader.svg").default} alt="" />
    </div>
  </div>
);
global.loader = (
  <span className="load_text">
    <span
      className="spinner-border spinner-border-sm mg-r-5"
      role="status"
      aria-hidden="true"
    ></span>
    Loading...
  </span>
);
// To check user's access permissions
global.userPermissions = new OnKeyPressEvent();
// Use for datatable
global.page_length_menu = [10, 20, 50, 100];
global.page_size = process.env.REACT_APP_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_PAGE_SIZE)
  : 10;
global.show_filter =
  process.env.REACT_APP_SHOW_FILTER === "true" ? true : false;
global.show_pagination_info =
  process.env.REACT_APP_SHOW_PAGINATION_INFO === "true" ? true : false;
global.show_pagination =
  process.env.REACT_APP_SHOW_PAGINATION === "true" ? true : false;
global.pagination = process.env.REACT_APP_PAGINATION
  ? process.env.REACT_APP_PAGINATION
  : "basic";
global.excel_button =
  process.env.REACT_APP_SHOW_EXCEL_BUTTON === "true" ? true : false;
global.print_button =
  process.env.REACT_APP_SHOW_PRINT_BUTTON === "true" ? true : false;
global.filter_time = process.env.REACT_APP_FILTER_TIME
  ? parseInt(process.env.REACT_APP_FILTER_TIME)
  : 0;
// User for datatble to display all records without pagination
global.all_page_length_menu = [];
global.max_page_size = process.env.REACT_APP_MAX_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_MAX_PAGE_SIZE)
  : 1000000;
global.hide_filter =
  process.env.REACT_APP_HIDE_FILTER === "true" ? true : false;
global.hide_pagination_info =
  process.env.REACT_APP_HIDE_PAGINATION_INFO === "true" ? true : false;
global.hide_pagination =
  process.env.REACT_APP_HIDE_PAGINATION === "true" ? true : false;
// Use for countdown timer
global.countdown_time = process.env.REACT_APP_TIMER_VALUE
  ? parseFloat(process.env.REACT_APP_TIMER_VALUE)
  : 0;
// Use for page redirection after save/update data
global.redirect_time = process.env.REACT_APP_AUTO_REDIRECT_TIME
  ? parseFloat(process.env.REACT_APP_AUTO_REDIRECT_TIME)
  : 0;
// Use for auto alert popup close time after display
global.alert_close_time = process.env.REACT_APP_ALERT_CLOSE_TIME
  ? parseFloat(process.env.REACT_APP_ALERT_CLOSE_TIME)
  : 0;
// Use for authentication
global.headerToken = localStorage.getItem("headerToken")
  ? localStorage.getItem("headerToken")
  : "";
global.userData = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : {};
// Data from setting apis
global.currentPeriod = localStorage.getItem("currentPeriod")
  ? localStorage.getItem("currentPeriod")
  : "";
global.finYearEnd = localStorage.getItem("finYearEnd")
  ? localStorage.getItem("finYearEnd")
  : "";
global.lastUpdate = localStorage.getItem("lastUpdate")
  ? localStorage.getItem("lastUpdate")
  : "";
global.prevPeriodDate = localStorage.getItem("prevPeriodDate")
  ? localStorage.getItem("prevPeriodDate")
  : "";
global.currentPeriodDate = localStorage.getItem("currentPeriodDate")
  ? localStorage.getItem("currentPeriodDate")
  : "";
global.permissions = localStorage.getItem("permissions")
  ? localStorage.getItem("permissions")
  : "";
global.yearDropdownItemNumber = 3;
global.successStatus = [200, 201, 202];
global.showOpen = false;
global.current_year = Moment(new Date()).format("YYYY");
// Use for prevent values on keypress
global.onKeyPressEvent = new OnKeyPressEvent();
//day list

global.selectRoleList = [
  { value: "1", name: "System Admin" },
  { value: "2", name: "Above Property" },
  { value: "3", name: "Property Admin" },
  { value: "6", name: "Property Manager" },
  { value: "4", name: "Property User" },
  { value: "5", name: "Corporate Admin" },
];
global.selectMonth = [
  { value: "01", name: "Jan" },
  { value: "02", name: "Feb" },
  { value: "03", name: "March" },
  { value: "04", name: "April" },
  { value: "05", name: "May" },
  { value: "06", name: "June" },
  { value: "07", name: "july" },
  { value: "08", name: "Aug" },
  { value: "09", name: "Sep" },
  { value: "10", name: "Oct" },
  { value: "11", name: "Nov" },
  { value: "12", name: "Dec" },
];

global.zoneTypeList = [
  { value: "5", label: "All" },
  { value: "4", label: "Equipment" },
  { value: "1", label: "Room" }, 
  { value: "2", label: "Commercial Area" },
  { value: "3", label: "Equipment Zone" },
];
global.templateOrCheckListzoneTypeList = [  
  { value: "5", label: "All" },
  { value: "2", label: "Commercial Area" },
  { value: "4", label: "Equipment" },
  { value: "3", label: "Equipment Zone" },
  { value: "1", label: "Room" },
];
global.zoneTypeListproperty = [ 
  { value: "1", label: "Room" }, 
  { value: "2", label: "Commercial Area" },
  { value: "3", label: "Equipment Zone" },
];

global.priorityType = [
  { value: "1", label: "High" },
  { value: "2", label: "Medium" },
  { value: "3", label: "Low" },
];
 
global.mroStatusList = [
  { value: "1", label: "Backlog" },
  { value: "2", label: "Pending" },
  { value: "3", label: "Ongoing" },
  { value: "4", label: "Completed" },
  { value: "5", label: "Cancelled" },
  { value: "6", label: "Overdue" },
];
global.actionItemStatus = [
  { value: "1", label: "Pending" },
  { value: "2", label: "Completed" },
  { value: "3", label: "Overdue" },
];
global.mroAnswer = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];
global.auditStatus = [
  { value: "1", label: "In progress" },
  { value: "2", label: "Follow up" },
  { value: "3", label: "Completed" },
];
global.auditStatusWeb = [
  { value: 1, label: "In progress" },
  { value: 2, label: "Follow up" },
  { value: 3, label: "Completed" },
];
global.activeStatusWeb = [
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
  { value: 3, label: "Blocked" },
];
// To login user role ID
global.roleTypeId = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData")).role_id
  : "";
global.roleTypeName = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData")).role_type_name
  : "";
// For by default Show/Hide Custom Filter
global.showFilter = 0;

// Define the modules and formats as static properties of the class
global.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline"],
    // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ["link"],
    // ['clean']
  ],
};

global.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileImage: "",
    };
    this.updateProfile = this.updateProfile.bind(this);

    // Initialize state
    const currentUserToken = localStorage.getItem("headerToken");
    this.state = {
      currentUser: currentUserToken ? currentUserToken : null,
    };
  }

  componentDidMount() {
    const getUserToken = localStorage.getItem("headerToken");
    if (getUserToken) {
      this.setState({ currentUser: getUserToken });
    }
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    this.onRouteChanged();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  updateProfile(imagePath) {
    this.setState({ profileImage: imagePath });
  }

  toggleHeaderMenu(e) {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      const userHeaderToken = localStorage.getItem("headerToken");
      const { currentUser } = this.state;
      if (currentUser) {
        if (currentUser !== userHeaderToken) {
          this.props.history.push("/signin");
        }
      } else {
        if (userHeaderToken) {
          this.setState({ currentUser: userHeaderToken });
        }
      }
    }
  };

  render() {
    let pathArray = ["/privacy-notice", "/app-download"];
    let headerComponent = !this.state.isFullPageLayout ? <Header /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    let HeaderTopComponent = !this.state.isFullPageLayout ? (
      <HeaderTopBar image={this.state.profileImage} />
    ) : (
      ""
    );

    if (
      this.props.location.pathname == "/privacy-notice" ||
      this.props.location.pathname == "/app-download"
    ) {
      return (
        <>
          {" "}
          <div className="">
            <div className="az-sidebar" style={{ display: "none" }}></div>
            <div className="">
              <div className="">
                <AppRoutes />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return pathArray.includes(this.props.location.pathname) === false ? (
        <div className="dashboard d-flex flex-wrap">
          <div className="az-sidebar">{headerComponent}</div>
          <div className="az-rightbar">
            <div className="az-content-wrapper">
              {HeaderTopComponent}
              <AppRoutes updateProfileImage={this.updateProfile} />
            </div>
            {footerComponent}
            <div
              className="az-navbar-backdrop"
              onClick={(event) => this.toggleHeaderMenu(event)}
            ></div>
          </div>
        </div>
      ) : (
        <>
          <div className="dashboard d-flex flex-wrap">
            <div className="az-sidebar" style={{ display: "none" }}></div>
            <div className="az-rightbar">
              <div className="az-content-wrapper">
                <AppRoutes />
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      const getUser = JSON.parse(localStorage.getItem("userData"));
      if (getUser) {
        this.setState({ loginUser: getUser });
      }
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/signin",
      "/2fa-signin",
      "/logout",
      "/page-403",
      "/forgot-password",
      "/2fa-forgot-password",
      "/reset-password",
      "/account-activation",
      "/view-report",
      "/view-reports",
      "/clear-cache",
      "/landing",
    ];
    const halfSidebarRoutes = [
      "/signin",
      "/forgot-password",
      "/reset-password",
      // "/2fa-signin",
      // "/2fa-forgot-password",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname.includes(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".az-sidebar").classList.add("d-none");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".az-sidebar").classList.remove("d-none");
      }
    }
    for (let i = 0; i < halfSidebarRoutes.length; i++) {
      if (this.props.location.pathname.includes(halfSidebarRoutes[i])) {
        document
          .querySelector(".az-rightbar")
          .classList.add("az-login-rightbar");
        break;
      } else {
        // document
        // .querySelector(".az-rightbar")
        // .classList.remove("az-login-rightbar");

        const rightbar = document.querySelector(".az-rightbar");
        if (rightbar) {
          rightbar.classList.remove("az-login-rightbar");
        }
      }
    }
  }
}

export default withRouter(App);

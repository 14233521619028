////////////////////////////////////////////////////////////
//  Program: Template List.jsx                            //
//  Application:  Template  List                          //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Button, Row, Col } from "react-bootstrap";
import TrackeService from "../../services/TrackeService";
import MroService from "../../services/MroService";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import back from "./../../assets/images/back.svg";
import exportIcon from "./../../assets/images/export-icon.svg";
import ReactDatatable from "@ashvin27/react-datatable";
import ChecklistService from "../../services/ChecklistService";
import UserService from "../../services/UserService";
import { Modal, Form } from "react-bootstrap";
// import PropertySelection from "../dashboard/PropertySelection";

export class RoomTracker extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    let firstDayOfMonth, lastDayOfMonth;
    if ([4, 3, 6].includes(global.roleTypeId)) {
      firstDayOfMonth = Moment().startOf("year").toDate();
      lastDayOfMonth = Moment().endOf("year").toDate();
    } else {
      firstDayOfMonth = Moment().subtract(6, "months").toDate();
      lastDayOfMonth = new Date();
    }
    this.state = {
      is_month_wise: false,
      TrackerList: [],
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      start_date: firstDayOfMonth,
      end_date: lastDayOfMonth,
      property_slug: "",
      selectedPropertyList: [],
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear(),
      propertySlug: "",
      propertyList: [],
      zone_type: "",
      zoneTypeList: [],
      selectedZoneTypeList: [],
      location_slug: "",
      locationList: [],
      selecteLocationList: [],
      trackerTypeText: "",
      trackerType: "",
      records: [],
      globalTrackerState: {},
      propertyLable: "",
      isFilterTrue: false,
      singleScreen: false,
      status_id: "",
      showSpinner: false,
      exportType: "pdf",
      statusList: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      config: {
        key_column: "unique_slug",
        show_info: false,
        per_page: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: "advance",
        language: {
          no_data_text: "No record found",
        },
      },
      config2: {
        sort: {
          column: "room_name",
          order: "asc",
        },
        key_column: "slug",
        show_info: false,
        per_page: false,
        show_length_menu: false,
        show_filter: false,
        show_pagination: false,
        pagination: "advance",
        language: {
          no_data_text: "No record found",
        },
      },
      extraButtons2: [],
      columns: [
        {
          key: "room_name",
          text: "Rooms",
          className: "hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.name ? "#" + record.name : "";
          },
        },
        {
          key: "Jan",
          text: "Jan",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.jan ? record.jan : "";
          },
        },
        {
          key: "Feb",
          text: "Feb",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.feb ? record.feb : "";
          },
        },
        {
          key: "Mar",
          text: "Mar",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.march ? record.march : "";
          },
        },
        {
          key: "Apr",
          text: "Apr",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.april ? record.april : "";
          },
        },
        {
          key: "May",
          text: "May",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.may ? record.may : "";
          },
        },
        {
          key: "Jun",
          text: "Jun",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.june ? record.june : "";
          },
        },
        {
          key: "Jul",
          text: "Jul",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.july ? record.july : "";
          },
        },
        {
          key: "Aug",
          text: "Aug",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.aug ? record.aug : "";
          },
        },
        {
          key: "Sep",
          text: "Sep",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.sep ? record.sep : "";
          },
        },
        {
          key: "Oct",
          text: "Oct",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.oct ? record.oct : "";
          },
        },
        {
          key: "Nov",
          text: "Nov",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.nov ? record.nov : "";
          },
        },
        {
          key: "Dec",
          text: "Dec",
          className: "Inspection hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.dec ? record.dec : "";
          },
        },
      ],
      columns2: [
        {
          key: "room_name",
          text: "Rooms",
          className: "Room",
          sortable: true,
          cell: (record) => {
            return record.room_name ? "#" + record.room_name : "";
          },
        },
        {
          key: "inspection_no",
          text: "Inspection No",
          className: "Room",
          sortable: true,
          cell: (record) => {
            return record.inspection_no ? "#" + record.inspection_no : "";
          },
        },
        {
          key: "full_name",
          text: "Performed by",
          className: "technician",
          sortable: true,
          cell: (record) => {
            return record.full_name ? record.full_name : "";
          },
        },
        {
          key: "completed_on",
          text: "Date",
          className: "date",
          sortable: true,
          cell: (record) => {
            return record.completed_on
              ? Moment(record.completed_on).format("DD MMM Y")
              : "";
          },
        },
        {
          key: "checklist_name",
          text: "Checklist",
          className: "Inspection",
          sortable: true,
          cell: (record) => {
            return record.checklist_name ? record.checklist_name : "";
          },
        },
        {
          key: "slug_status",
          text: "Inspection",
          className: "Inspection",
          sortable: true,
          cell: (record) => {
            if (record.slug) {
              let slug = record.slug;
              return (
                <a
                  href={`/inspection/update/${slug}`}
                  target="_blank"
                  className="hand-cursor"
                  rel="noopener noreferrer"
                >
                  Yes
                </a>
              );
            } else {
              return "No";
            }
          },
        },
      ],
    };
    this.filterToggle = this.filterToggle.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.resetData = this.resetData.bind(this);
    this.openExportModal = this.openExportModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
    this.changeExport = this.changeExport.bind(this);
    this.exportData = this.exportData.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
  }

  async insPdfGenerator(queryString = "", type = "") {
    var isPdf = 1;
    if (type == "csv") {
      isPdf = 2;
    }
    this.setState({ loadingexport: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterRoomData = JSON.parse(
      localStorage.getItem("roomLocationTracker")
    );
    let filterChecklistRoomData = JSON.parse(
      localStorage.getItem("roomTrackerChecklist")
    );
    let filterPropertyData = JSON.parse(
      localStorage.getItem("globleTrackerProperty")
    );
    let filterStatus = JSON.parse(
      localStorage.getItem("globleTrackerInsepection")
    );
    const propertySlugG =
      filterData && filterData.property_name !== "All Properties"
        ? filterData?.property_slug
        : filterPropertyData?.property_slug
        ? filterPropertyData.property_slug
        : this.state.propertyList.length > 0
        ? this.state.propertyList[0].value
        : "";
    if (filterData) {
      queryString =
        (this.state.is_month_wise
          ? "year=" +
            (filterData.year ? filterData.year : "")
          : "start_date=" +
            Moment(filterData.start_date).format("YYYY-MM-DD") +
            "&end_date=" +
            (filterData.end_date
              ? Moment(filterData.end_date).format("YYYY-MM-DD")
              : "")) +
        "&property_slug=" +
        propertySlugG +
        "&user_slug=" +
        (filterData && filterData.user_slug ? filterData.user_slug : "") +
        "&zone_type_id=" +
        (filterRoomData && filterRoomData.zone_type_id
          ? filterRoomData.zone_type_id
          : "") +
        "&location_slug=" +
        (filterRoomData && filterRoomData.location_slug
          ? filterRoomData.location_slug
          : "") +
        "&zone_name=" +
        (filterRoomData && filterRoomData.zone_name
          ? filterRoomData.zone_name
          : "") +
        "&checklist_id=" +
        (filterChecklistRoomData && filterChecklistRoomData.checklist_id
          ? filterChecklistRoomData.checklist_id
          : "") +
        "&status_id=" +
        (filterStatus && filterStatus.selectedStatusList
          ? filterStatus.selectedStatusList.value
          : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_pdf=" +
        isPdf +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
    } else {
      if (this.state.propertyList.length > 0) {
        var firstProperty = filterPropertyData
          ? {
              label: filterPropertyData.property_name,
              value: filterPropertyData.property_slug,
            }
          : this.state.propertyList[0];
      }
      queryString =
      (this.state.is_month_wise
        ? "year=" +
          (this.state.year ? this.state.year : "")
        : "start_date=" +
          Moment(this.state.start_date).format("YYYY-MM-DD") +
          "&end_date=" +
          (this.state.end_date
            ? Moment(this.state.end_date).format("YYYY-MM-DD")
            : "")) +
        "&property_slug=" +
        firstProperty.value +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_pdf=" +
        isPdf +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
    }
    if (isPdf == 2) {
      queryString = queryString + "&request_type=1";
      let response = await TrackeService.trackerCsvExport(queryString);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "rooms_tracker_export.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("Error exporting CSV:", response.message);
      }
      this.setState({
        loadingexport: false,
        list: list,
      });
    } else {
      let res = await TrackeService.getRoomTrackerData(queryString);
      if (global.successStatus.includes(res.status)) {
        list = res.data.report["0"] ? res.data.report["0"] : [];
      }
      this.setState({
        loadingexport: false,
        list: list,
      });
      window.open(this.state.list.filePath, "_blank");
    }
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };
  componentDidMount() {
    this.globalDataSet();
    this.getPropertyList();
    this.getUserList();
    this.setState({ zoneTypeList: global.zoneTypeList });
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterRoomData = JSON.parse(
      localStorage.getItem("roomLocationTracker")
    );
    let filterChecklistRoomData = JSON.parse(
      localStorage.getItem("roomTrackerChecklist")
    );

    if (
      filterData &&
      filterData.property_slug != undefined &&
      filterData.property_slug != ""
    ) {
      this.getLocation("property_slug=" + filterData.property_slug);
      this.getCheckList("property_slug=" + filterData.property_slug);
      if (
        filterData.selectePerformedList != undefined &&
        filterData.selectePerformedList != ""
      ) {
        this.setState({
          selectePerformedList: {
            label: filterData.selectePerformedList.label,
            value: filterData.selectePerformedList.value,
          },
        });
      }
    }
    if (
      filterChecklistRoomData &&
      filterChecklistRoomData.selectedCheckList != undefined &&
      filterChecklistRoomData.selectedCheckList != ""
    ) {
      this.setState({
        selectedCheckList: {
          label: filterChecklistRoomData.selectedCheckList.label,
          value: filterChecklistRoomData.selectedCheckList.value,
          checklist_id: filterChecklistRoomData.selectedCheckList.checklist_id,
        },
      });
    }
    if (
      filterData &&
      filterData.property_slug != undefined &&
      filterData.property_slug != ""
    ) {
      this.getLocation("property_slug=" + filterData.property_slug);
      this.getCheckList("property_slug=" + filterData.property_slug);
      if (
        filterRoomData &&
        filterRoomData.location_slug != undefined &&
        filterRoomData.location_slug != "" &&
        filterRoomData.zone_name != undefined &&
        filterRoomData.zone_name != ""
      ) {
        this.setState({
          selecteLocationList: {
            label: filterRoomData.zone_name,
            value: filterRoomData.location_slug,
            zone_type_id: filterRoomData.zone_type_id,
          },
        });
      }
    }
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.globalDataSet();
    this.getPropertyList();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  globalDataSet = () => {
    var singleUser =
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
        ? parseInt(localStorage.getItem("activePropertyCount"))
        : "";
    if (singleUser === 1) {
      const outSidePropertySlug =
        localStorage.getItem("outSidePorpertySlug") || "";
      const singlePropertySlug =
        localStorage.getItem("singlePorpertySlug") || "";
      const selectedPropertyName =
        localStorage.getItem("selectedPropertyName") || "";
      const singlePropertyName =
        localStorage.getItem("singlePropertyName") || "All Properties";
      var singlePropertyName1 =
        outSidePropertySlug !== "" ? selectedPropertyName : singlePropertyName;
      var singlePropertySlug1 =
        outSidePropertySlug !== "" ? outSidePropertySlug : singlePropertySlug;
      let filterData = JSON.parse(localStorage.getItem("globleTracker"));
      let selectedStartDate, selectedEndDate;
      if ([4, 3, 6].includes(global.roleTypeId)) {
        selectedStartDate = filterData
          ? Moment(filterData.start_date).format("YYYY-MM-DD")
          : Moment().startOf("year").toDate();
        selectedEndDate = filterData
          ? Moment(filterData.end_date).format("YYYY-MM-DD")
          : Moment().endOf("year").toDate();
      } else {
        selectedStartDate = Moment().subtract(6, "months").toDate(); // 6 months before today
        selectedEndDate = new Date();
      }
      const year = filterData
        ? filterData.year
        : new Date().getFullYear();
      var filterValue = JSON.stringify({
        start_date: selectedStartDate,
        end_date: selectedEndDate,
        year: year,
        property_slug: singlePropertySlug1,
        user_slug:
          filterData && filterData.user_slug ? filterData.user_slug : "",
        property_name: singlePropertyName1,
        ...(this.state.selectePerformedList && {
          selectePerformedList: this.state.selectePerformedList,
        }),
      });
      localStorage.setItem("globleTracker", filterValue);
    }
  };

  async getTrackerData(queryString = "") {
    this.setState({ loading: true, showSpinner: true });
    var list = [];
    let filterData = JSON.parse(localStorage.getItem("globleTracker"));
    let filterRoomData = JSON.parse(
      localStorage.getItem("roomLocationTracker")
    );
    let filterPropertyData = JSON.parse(
      localStorage.getItem("globleTrackerProperty")
    );
    let filterStatus = JSON.parse(
      localStorage.getItem("globleTrackerInsepection")
    );
    let filterChecklistRoomData = JSON.parse(
      localStorage.getItem("roomTrackerChecklist")
    );
    const propertySlugG =
      filterData && filterData.property_name !== "All Properties"
        ? filterData?.property_slug
        : filterPropertyData?.property_slug
        ? filterPropertyData.property_slug
        : this.state.propertyList.length > 0
        ? this.state.propertyList[0].value
        : "";
    const startDate = filterData
      ? Moment(filterData.start_date).toDate()
      : this.state.start_date
      ? Moment(this.state.start_date).toDate()
      : Moment().subtract(6, "months").toDate();
    const endDate = filterData
      ? Moment(filterData.end_date).toDate()
      : this.state.end_date
      ? Moment(this.state.end_date).toDate()
      : new Date();
    const Year = filterData ? filterData.year : this.state.year;
    if (filterData) {
      queryString =
        (this.state.is_month_wise
          ? "year=" + (Year ? Year : "")
          : "start_date=" +
            Moment(startDate).format("YYYY-MM-DD") +
            "&end_date=" +
            Moment(endDate).format("YYYY-MM-DD")) +
        "&property_slug=" +
        propertySlugG +
        "&user_slug=" +
        (filterData && filterData.user_slug ? filterData.user_slug : "") +
        "&zone_type_id=" +
        (filterRoomData && filterRoomData.zone_type_id
          ? filterRoomData.zone_type_id
          : "") +
        "&location_slug=" +
        (filterRoomData && filterRoomData.location_slug
          ? filterRoomData.location_slug
          : "") +
        "&zone_name=" +
        (filterRoomData && filterRoomData.zone_name
          ? filterRoomData.zone_name
          : "") +
        "&checklist_id=" +
        (filterChecklistRoomData && filterChecklistRoomData.checklist_id
          ? filterChecklistRoomData.checklist_id
          : "") +
        "&status_id=" +
        (filterStatus && filterStatus.selectedStatusList
          ? filterStatus.selectedStatusList.value
          : "") +
        "&sort_column=" +
        (this.state.sort_column ? this.state.sort_column : "") +
        "&sort_order=" +
        (this.state.sort_order ? this.state.sort_order : "") +
        "&is_month_wise=" +
        (this.state.is_month_wise ? 1 : 0);
      let selectedPropertyList;
      if (filterData && filterData.property_name !== "All Properties") {
        selectedPropertyList = {
          label: filterData.property_name,
          value: filterData.property_slug,
        };
      } else {
        selectedPropertyList = {
          label: filterPropertyData
            ? filterPropertyData.property_name
            : this.state.propertyList[0].label,
          value: filterPropertyData
            ? filterPropertyData.property_slug
            : this.state.propertyList[0].value,
        };
      }
      var selectedCheckList = filterChecklistRoomData?.selectedCheckList || [];
      var selectedStatusList = filterStatus?.selectedStatusList || [];
      this.setState({
        propertySlug: selectedPropertyList.value,
        propertyLable: selectedPropertyList.label,
        status_name: selectedStatusList ? selectedStatusList.label : "",
        start_date: startDate,
        end_date: endDate,
        year: Year,
        selectedPropertyList: selectedPropertyList,
        selectedCheckList: selectedCheckList,
        selectedStatusList: selectedStatusList,
        zone_name: filterRoomData ? filterRoomData.zone_name : "",
        location_slug: filterRoomData ? filterRoomData.location_slug : "",
        zone_type_id: filterRoomData ? filterRoomData.zone_type_id : "",
        checklist_id: filterChecklistRoomData
          ? filterChecklistRoomData.checklist_id
          : "",
      });
    } else {
      if (this.state.propertyList.length > 0) {
        const firstProperty =
          filterData && filterData.property_name !== "All Properties"
            ? {
                label: filterPropertyData?.property_name,
                value: filterPropertyData?.property_slug,
              }
            : filterPropertyData
            ? {
                label: filterPropertyData.property_name,
                value: filterPropertyData.property_slug,
              }
            : this.state.propertyList[0];
        this.setState({
          selectedPropertyList: firstProperty,
          propertySlug: firstProperty.value,
          propertyLable: firstProperty.label,
        });
        queryString =
          (this.state.is_month_wise
            ? "year=" + (Year ? Year : "")
            : "start_date=" +
              Moment(startDate).format("YYYY-MM-DD") +
              "&end_date=" +
              Moment(endDate).format("YYYY-MM-DD")) +
          "&property_slug=" +
          firstProperty.value +
          "&checklist_id=" +
          (filterChecklistRoomData && filterChecklistRoomData.checklist_id
            ? filterChecklistRoomData.checklist_id
            : "") +
          "&sort_column=" +
          (this.state.sort_column ? this.state.sort_column : "") +
          "&sort_order=" +
          (this.state.sort_order ? this.state.sort_order : "") +
          "&is_month_wise=" +
          (this.state.is_month_wise ? 1 : 0);
        this.getLocation("property_slug=" + firstProperty.value);
        this.getCheckList("property_slug=" + firstProperty.value);
      } else {
        queryString =
          (this.state.is_month_wise
            ? "year=" + (Year ? Year : "")
            : "start_date=" +
              Moment(startDate).format("YYYY-MM-DD") +
              "&end_date=" +
              Moment(endDate).format("YYYY-MM-DD")) +
          "&sort_column=" +
          (this.state.sort_column ? this.state.sort_column : "") +
          "&sort_order=" +
          (this.state.sort_order ? this.state.sort_order : "");
      }
    }
    this.setState({ records: [] });
    let res = await TrackeService.getRoomTrackerData(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    if (this.state.checkFilter === true) {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
      showSpinner: false,
    });

    if (list.length > 0) {
      this.setState({
        completed_on: Moment(list[0].completed_on).format("DD MMM, YY"),
      });
    }
    this.setState({ showSpinner: false });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value; 
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }

  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.getLocation("property_slug=" + value.value);
    this.getCheckList("property_slug=" + value.value);
    this.setState({
      // selectedLocation: selectedLocation,
      selectedPropertyList: value,
      property_slug: value.value,
      propertySlug: value.value,
      propertyLable: value.label,
      singleScreen:
        localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== ""
          ? true
          : false,
      singleScreenProperty: value.label,
    });
  }

  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
      status_name: value.label,
    });
  }

  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      // if (item.role_id >= global.roleTypeId) {
      userListData.push({
        label: item.full_name,
        value: item.slug,
      });
      // }
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }

  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectePerformedList: value,
      user_slug: value.value,
    });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    this.setState({ loading: true });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocationsMobile(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList =
        res && res.data.data ? res.data.data : res.data ? res.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
        zone_type_id: item.zone_type_id,
      });
    });
    this.setState({
      locationList: locationListData,
    });
    this.setState({ loading: false });
  }

  async getCheckList(queryString = "") {
    var list = [];
    var listData = [];
    let res = await ChecklistService.getCheckList(
      "is_dropdown=1&is_tracker=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
    }
    list.forEach((item) => {
      listData.push({
        label: item.checklist_name,
        value: item.slug,
        checklist_id: item.checklist_id,
      });
    });
    this.setState({
      checkList: listData,
    });
  }

  //handleChangeCheckList
  handleChangeCheckList(value) {
    let checklist_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        checklist_slug.push(item.value);
      });
    }
    this.setState({
      selectedCheckList: value,
      checklist_slug: value.value,
      checklist_id: value.checklist_id,
    });
  }

  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      zone_type_id: value.zone_type_id,
      zone_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  resetData() {
    const filterPropertyData =
      JSON.parse(localStorage.getItem("globleTrackerProperty")) || "";
    const outSidePropertySlug =
      localStorage.getItem("outSidePorpertySlug") || "";
    const singlePropertySlug = localStorage.getItem("singlePorpertySlug") || "";
    const selectedPropertyName =
      localStorage.getItem("selectedPropertyName") || "";
    const singlePropertyName =
      localStorage.getItem("singlePropertyName") || "All Properties";
    var outsideSlugPN =
      outSidePropertySlug !== "" ? selectedPropertyName : singlePropertyName;
    var outsideSlugP =
      outSidePropertySlug !== "" ? outSidePropertySlug : singlePropertySlug;
    if (outsideSlugPN !== "All Properties" && outsideSlugP !== null) {
      localStorage.removeItem("globleTrackerProperty");
    }
    const selectedProperty =
      outsideSlugPN !== "All Properties"
        ? {
            label: outsideSlugPN,
            value: outsideSlugP,
          }
        : filterPropertyData
        ? {
            label: filterPropertyData.property_name,
            value: filterPropertyData.property_slug,
          }
        : this.state.propertyList.length > 0
        ? {
            label: this.state.propertyList[0].label,
            value: this.state.propertyList[0].value,
          }
        : null;
    let start_date, end_date;
    if ([4, 3, 6].includes(global.roleTypeId)) {
      start_date = Moment().startOf("year").toDate();
      end_date = Moment().endOf("year").toDate();
    } else {
      start_date = Moment().subtract(6, "months").toDate();
      end_date = new Date();
    }
    this.setState({
      trackerTypeText: "",
      trackerType: "",
      start_date: start_date,
      end_date: end_date,
      selectedPropertyName: "",
      singleScreenProperty: "",
      singleScreen: false,
      selectStartDate: "",
      selectEndDate: "",
      selecteLocationList: "",
      location_slug: "",
      zone_type_id: "",
      zone_name: "",
      selectedCheckList: "",
      checklist_id: "",
      checkFilter: false,
      selectedStatusList: [],
      status_name: "",
      year: new Date().getFullYear(),
      selectePerformedList: "",
      user_slug: "",
      showExportModal: false,
    });
    localStorage.removeItem("globleTracker");
    localStorage.removeItem("roomLocationTracker");
    localStorage.removeItem("globleTrackerInsepection");
    localStorage.removeItem("roomTrackerChecklist");
    var GlobalApply =
      localStorage.getItem("outSidePorpertySlug") != "" &&
      localStorage.getItem("outSidePorpertySlug") != undefined
        ? parseInt(localStorage.getItem("outSidePorpertySlug"))
        : "";
    if (GlobalApply != undefined && GlobalApply != "") {
      let selectedStartDate, selectedEndDate;
      if ([4, 3, 6].includes(global.roleTypeId)) {
        selectedStartDate = Moment().startOf("year").toDate();
        selectedEndDate = Moment().endOf("year").toDate();
      } else {
        selectedStartDate = Moment().subtract(6, "months").toDate();
        selectedEndDate = new Date();
      }
      var filterValue =
        '{"start_date":"' +
        selectedStartDate +
        '", "end_date":"' +
        selectedEndDate +
        '", "property_slug":"' +
        (localStorage.getItem("outSidePorpertySlug") != ""
          ? localStorage.getItem("outSidePorpertySlug")
          : localStorage.getItem("singlePorpertySlug")) +
        '", "property_name":"' +
        (localStorage.getItem("outSidePorpertySlug") != ""
          ? localStorage.getItem("selectedPropertyName")
          : localStorage.getItem("singlePropertyName")) +
        '"}';
      localStorage.setItem("globleTracker", filterValue);
    }
    if (
      localStorage.getItem("activePropertyCount") != "" &&
      localStorage.getItem("activePropertyCount") != undefined
    ) {
      this.globalDataSet();
    }
    let queryString =
      "start_date=" +
      Moment(this.state.start_date).format("YYYY-MM-DD") +
      "&end_date=" +
      Moment(this.state.end_date).format("YYYY-MM-DD") +
      (selectedProperty ? "&property_slug=" + selectedProperty.value : "");
    this.getTrackerData(queryString);
    this.getPropertyList().then(() => {
      if (selectedProperty) {
        this.setState({
          selectedPropertyList: selectedProperty,
          propertySlug: selectedProperty.value,
          propertyLable: selectedProperty.label,
        });
      }
    });
  }

  customFilter() {
    const selectedStartDate = Moment(this.state.start_date).format(
      "YYYY-MM-DD"
    );
    let selectedEndDate = "";
    if (this.state.end_date && !isNaN(new Date(this.state.end_date))) {
      selectedEndDate = Moment(this.state.end_date).format("YYYY-MM-DD");
    }
    let queryString =
      (this.state.is_month_wise === 1
        ? "year=" +
          (this.state.year ? this.state.year : "")
        : "start_date=" + selectedStartDate + "&end_date=" + selectedEndDate) +
      "&property_slug=" +
      this.state.propertySlug +
      "&zone_type_id=" +
      (this.state.zone_type_id ? this.state.zone_type_id : "") +
      "&location_slug=" +
      (this.state.location_slug ? this.state.location_slug : "") +
      "&zone_name=" +
      (this.state.zone_name ? this.state.zone_name : "") +
      "&checklist_id=" +
      (this.state.checklist_id ? this.state.checklist_id : "") +
      "&user_slug=" +
      (this.state.user_slug ? this.state.user_slug : "") +
      "&status_id=" +
      (this.state.status_id ? this.state.status_id : "");
    this.setState({ tableQueryString: "", customQueryString: queryString });
    var filterValue = JSON.stringify({
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      year: this.state.year ? this.state.year : "",
      property_slug: this.state.propertySlug,
      property_name: this.state.propertyLable,
      ...(this.state.selectePerformedList && {
        selectePerformedList: this.state.selectePerformedList,
      }),
      ...(this.state.user_slug && { user_slug: this.state.user_slug }),
    });
    if (this.state.checklist_id) {
      const filterValueChecklistRoom = {
        checklist_id: this.state.checklist_id,
        selectedCheckList: this.state.selectedCheckList || null,
      };
      localStorage.setItem(
        "roomTrackerChecklist",
        JSON.stringify(filterValueChecklistRoom)
      );
    }

    if (this.state.location_slug) {
      var filterValueLocation =
        (this.state.zone_type_id
          ? '{"zone_type_id" :"' + this.state.zone_type_id
          : "") +
        (this.state.location_slug
          ? '", "location_slug" :"' + this.state.location_slug
          : "") +
        (this.state.zone_name
          ? '", "zone_name" :"' + this.state.zone_name
          : "") +
        '"}';
      localStorage.setItem("roomLocationTracker", filterValueLocation);
    }
    localStorage.setItem("globleTracker", filterValue);
    localStorage.setItem(
      "globleTrackerProperty",
      JSON.stringify({
        property_slug: this.state.propertySlug,
        property_name: this.state.propertyLable,
      })
    );
    localStorage.setItem(
      "globleTrackerInsepection",
      JSON.stringify({
        selectedStatusList: this.state.selectedStatusList,
      })
    );
    this.setState({ globalTrackerState: filterValue });
    this.setState({ isFilterTrue: true, checkFilter: true });
    this.getTrackerData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  // Get Property List
  async getPropertyList(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1&is_tracker=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    propertyList.forEach((item) => {
      propertyListData.push({
        label: item.property_name,
        value: item.slug,
      });
    });
    if (propertyListData.length > 0) {
      this.setState(
        {
          propertyList: propertyListData,
          selectedPropertyList: propertyListData[0], // Set the first property as selected by default
          property_slug: propertyListData[0].value,
          propertySlug: propertyListData[0].value,
          propertyLable: propertyListData[0].label,
        },
        () => {
          let filterData = JSON.parse(localStorage.getItem("globleTracker"));
          let filterPropertyData = JSON.parse(
            localStorage.getItem("globleTrackerProperty")
          );
          const propertySlug =
            filterData && filterData.property_name !== "All Properties"
              ? filterData?.property_slug
              : filterPropertyData?.property_slug
              ? filterPropertyData.property_slug
              : this.state.propertyList.length > 0
              ? this.state.propertyList[0].value
              : "";

          if (propertySlug) {
            this.getLocation("property_slug=" + propertySlug);
            this.getCheckList("property_slug=" + propertySlug);
          }
        }
      );
    } else {
      this.setState({ propertyList: propertyListData });
    }
    this.getTrackerData();
  }

  openExportModal() {
    this.setState({ showExportModal: true });
  }

  closeExportModal() {
    this.setState({ showExportModal: false });
  }

  changeExport = (evt) => {
    let value = evt.target.value;
    this.setState((prevState) => ({ ...prevState, exportType: value }));
  };

  exportData() {
    let exportType = this.state.exportType;
    if (exportType === "pdf") {
      this.insPdfGenerator();
    } else if (exportType === "csv") {
      this.insPdfGenerator("", "csv");
    }
  }

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }
    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getTrackerData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState(
        {
          sort_order: data.sort_order.order, // Correct the mapping (column is order, order is column)
          sort_column: data.sort_order.column,
        },
        () => {
          // This callback ensures that getTrackerData runs after the state is updated
          this.getTrackerData(queryString);
        }
      );
    }
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main className="p-0">
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          <div className="container-fluid p-0">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center tracker_btn_new">
                    <h3 className="invisible"> </h3>
                    <div className="btnNavigate d-flex justify-content-end">
                      <Button
                        onClick={this.openExportModal}
                        className="gf-primary-btn-small-one mg-l-7 loader_btn align-items-center export_pdf_btn"
                      >
                        Export
                      </Button>
                      <button
                        onClick={this.filterToggle}
                        className="gf-primary-btn-small-one mg-l-7"
                      >
                        {" "}
                        {this.state.showFilter === 1 ? "Filter" : "Filter"}
                      </button>
                    </div>
                  </div>
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            {this.state.is_month_wise == 1 ? (
                              <>
                                <label className="form-label text-left mg-b-5 mt-0">
                                  Year
                                </label>
                                <Col
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="mb-md-0 mb-3 pl-0"
                                >
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showYearPicker
                                    autoComplete="off"
                                    yearDropdownItemNumber={global.yearDropdownItemNumber}
                                    name="year"
                                    placeholderText="Select year"
                                    dateFormat="yyyy"
                                    selected={
                                      this.state.year
                                        ? new Date(this.state.year, 0, 1) // Create a Date object for January 1st of the selected year
                                        : null
                                    }
                                    onChange={(date) => {
                                      const year = date.getFullYear(); // Extract only the year from the Date object
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "year",
                                          value: year, // Update the state with the year
                                        },
                                      });
                                    }}
                                  />
                                </Col>
                              </>
                            ) : (
                              <>
                                <label className="form-label text-left mg-b-5 mt-0">
                                  Start Date
                                </label>
                                <Col
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="mb-md-0 mb-3 pl-0"
                                >
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="start_date"
                                    placeholderText="dd-mm-yy"
                                    dateFormat="dd MMM yy"
                                    selected={this.state.start_date || ""}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "start_date",
                                          value: data,
                                        },
                                      })
                                    }
                                    maxDate={this.state.end_date}
                                  />
                                </Col>
                                <label className="form-label text-left mg-b-5">
                                  End Date
                                </label>
                                <Col
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="mb-md-0 mb-3 pl-0"
                                >
                                  <DatePicker
                                    className="form-control max_width_100"
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    autoComplete="off"
                                    yearDropdownItemNumber={
                                      global.yearDropdownItemNumber
                                    }
                                    name="end_date"
                                    placeholderText="dd-mm-yy"
                                    dateFormat="dd MMM yy"
                                    selected={this.state.end_date || ""}
                                    onChange={(data) =>
                                      this.changeHandler({
                                        target: {
                                          type: "date",
                                          name: "end_date",
                                          value: data,
                                        },
                                      })
                                    }
                                    minDate={this.state.start_date}
                                  />
                                </Col>
                              </>
                            )}
                            <label className="form-label text-left mg-b-5">
                              Property
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect"
                                menuPlacement="auto"
                                value={this.state.selectedPropertyList}
                                options={this.state.propertyList}
                                styles={customStyles}
                                defaultValue={this.state.selectedPropertyList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Checklist{" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedCheckList}
                                options={this.state.checkList}
                                onChange={(value) =>
                                  this.handleChangeCheckList(value)
                                }
                                defaultValue={this.state.selectedCheckList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Location{" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selecteLocationList}
                                options={this.state.locationList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeLocation(value)
                                }
                                defaultValue={this.state.selecteLocationList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Inspection{" "}
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedStatusList}
                                options={this.state.statusList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeStatus(value)
                                }
                                defaultValue={this.state.selectedStatusList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5">
                                Performed by
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectePerformedList}
                                options={this.state.userList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeUserList(value)
                                }
                                defaultValue={this.state.selectePerformedList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid p-0">
            <div className="mgb-neg-20 brandList dashboardRightcard">
              <div className="row">
                <div className="col-md-6 col-lg-6 d-flex align-items-center justify-content-start">
                  <label className="form-label mg-b-0 tracker-table-label">
                    {this.state.is_month_wise ? "Monthly View" : "Yearly View"}{" "}
                  </label>
                  <div
                    className={
                      this.state.is_month_wise ? "az-toggle on" : "az-toggle"
                    }
                    onClick={() => {
                      // Toggle the state
                      this.setState(
                        {
                          is_month_wise: !this.state.is_month_wise,
                        },
                        () => {
                          // Call the API after the state has been updated
                          this.getTrackerData();
                        }
                      );
                    }}
                  >
                    <span></span>
                  </div>
                </div>
                <div className="col-md-12">
                  {this.state.is_month_wise == 1 ? (
                    <>
                      {this.state.year !== undefined &&
                      this.state.year !== "" ? (
                        <div className="checklist_table_heading">
                          <span className="lspan">Year: &nbsp;</span>
                          <span className="rspan">
                            {this.state.year}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {this.state.start_date !== undefined &&
                      this.state.start_date !== "" ? (
                        <div className="checklist_table_heading">
                          <span className="lspan">Start Date: &nbsp;</span>
                          <span className="rspan">
                            {Moment(this.state.start_date).format(
                              "DD MMM YYYY"
                            )}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.end_date !== undefined &&
                      this.state.end_date !== "" ? (
                        <div className="checklist_table_heading">
                          <span className="lspan">End Date: &nbsp;</span>
                          <span className="rspan">
                            {Moment(this.state.end_date).format("DD MMM YYYY")}
                          </span>
                        </div>
                      ) : null}
                    </>
                  )}

                  {this.state.propertyLable !== undefined &&
                  this.state.propertyLable !== "" ? (
                    <>
                      <div className="checklist_table_heading">
                        <span className="lspan">Search Applied On:&nbsp;</span>
                        <span className="rspan">
                          {this.state.propertyLable
                            ? this.state.propertyLable
                            : ""}
                          {this.state.selectedCheckList &&
                          (Array.isArray(this.state.selectedCheckList)
                            ? this.state.selectedCheckList.length > 0
                            : Object.keys(this.state.selectedCheckList).length >
                              0)
                            ? ", " + this.state.selectedCheckList.label
                            : ""}
                          {this.state.zone_name
                            ? ", " + this.state.zone_name
                            : ""}
                          {this.state.status_name
                            ? ", " + this.state.status_name
                            : ""}
                          {this.state.selectePerformedList
                            ? ", " + this.state.selectePerformedList.label
                            : ""}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.is_month_wise == 1 ? (
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 table-bordered"
                  config={this.state.config}
                  records={this.state.records}
                  columns={this.state.columns}
                  extraButtons={this.state.extraButtons}
                  dynamic={true}
                  loading={this.state.loading}
                  total_record={this.state.total_record}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandlermonth}
                />
              ) : (
                <ReactDatatable
                  className="table no-wrap mg-b-30 pd-b-2 table-bordered"
                  config={this.state.config2}
                  records={this.state.records}
                  columns={this.state.columns2}
                  extraButtons={this.state.extraButtons2}
                  dynamic={true}
                  loading={this.state.loading}
                  total_record={this.state.total_record}
                  onChange={this.tableChangeHandler}
                  onRowClicked={this.rowClickedHandler}
                />
              )}
            </div>
          </div>
        </main>
        <Modal show={this.state.showExportModal} centered>
          <Modal.Body className={`width_402 p-4 border_radius_14 `}>
            <span
              className="welcomeClose"
              onClick={() => this.closeExportModal()}
            >
              {global.closee}
            </span>

            <h3 className="text-center">Export</h3>

            <label>Select Export File Type : </label>
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                <Form.Check
                  onChange={(e) => this.changeExport(e)}
                  name="alert_type"
                  value="pdf"
                  checked={this.state.exportType === "pdf" ? true : false}
                  type={"radio"}
                  id="pdf"
                  label={"PDF"}
                  className={"rdiobox mg-r-20"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-6 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                <Form.Check
                  onChange={(e) => this.changeExport(e)}
                  name="alert_type"
                  value="csv"
                  checked={this.state.exportType === "csv" ? true : false}
                  type={"radio"}
                  id="csv"
                  label={"CSV"}
                  className={"rdiobox mg-r-20"}
                />
              </div>
            </div>

            <div className="d-flex cnt_center row mt-3">
              <Button
                onClick={() => this.exportData()}
                className="btn btn btn-success btn-block mg-r-7 loader_btn align-items-center export_pdf_btn"
                disabled={this.state.records.length === 0 ? true : false}
              >
                <img src={exportIcon} alt=""  className="mb-0"/>
                {this.state.loadingexport ? global.loader : "Export"}
              </Button>
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeExportModal()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default RoomTracker;

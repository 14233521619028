////////////////////////////////////////////////////////////
//  Program: AuditService.js                              //
//  Application: AuditService                             //
//  Option: Used for manage Template and AuditService     //
//  Developer: Kiran singh 					              //
//  Date: 2022-06-02                                      //
////////////////////////////////////////////////////////////

import ApiService from "./ApiService";

class AuditTemplateService {
  getAuditTemplateList(queryString) {
    let endPoint = "audit-template" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getAuditTemplateRowBySlug(slug) {
    let endPoint = "audit-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

   createAuditTemplate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "audit-template";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateAuditTemplate(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "audit-template/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.putCall(inputData);
    return response;
  }
  
  deleteAuditTemplate(slug) {
    let endPoint = "audit-template/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }
  
  getAuditTemplatePdfBySlug(slug) {
    let endPoint = "audit-template-pdf/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new AuditTemplateService();

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: recent inspection                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, {PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class RecentInspections extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspectionList: this.props.inspectionList ? this.props.inspectionList :[],  
    };
  }
  render() {
    const { isLoading, inspectionList } = this.props;
    return (
      <div className="card card-dashboard-sixteen">
        <div className="card-header">
          <h6 className="card-title tx-14 mg-b-0">
            Recent Inspections{" "}
            {global.userPermissions.checkPermission("inspection-list") && inspectionList.length > 0 && (
             <Link to="/inspections" className="btn_view">
               View More
            </Link>
            )}
            
          </h6>
        </div>
        <div className="card-body custom-table-box">
          <div className={`table-responsive ${inspectionList.length === 0 ? 'no-record-data' : ''}`}>
            <table className="table mg-b-0">
              <tbody>
                {isLoading ? (
                  // Display skeleton loader when data is loading
                  Array(2).fill().map((_, index) => (
                    <tr key={`skeleton_${index}`}>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={150} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={100} />
                        </small>
                      </td>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={100} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={80} />
                        </small>
                      </td>
                    </tr>
                  ))
                ) : inspectionList.length > 0 ? (
                  inspectionList.map((el, index) => (
                    <tr key={"ins" + index}>
                      <td>
                      <Link 
                          to={`/inspection/update/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          #{el.inspection_no ? el.inspection_no : ""}
                        </h6>
                        <small className="tx-11">
                          {el.property && el.property.property_name ? el.property.property_name : ""}
                        </small>
                      </Link>
                      </td>
                      <td>
                      <Link 
                          to={`/inspection/update/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          {el.completed_on
                            ? Moment(el.completed_on).format("DD MMM, YY - hh:mm A")
                            : ""}
                        </h6>
                        <small className="tx-11">
                          {el.inspector ? el.inspector.full_name : ""}
                        </small>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-recrod-found" key="1">No record found</div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default RecentInspections;

/*
 * Program: BrandForm.jsx
 * Application: Customer
 * Option: to Add/Update Customer Information
 * Developer: Chandra Shekhar
 * Date: 2022-03-10
 */
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CustomerService from "../../services/CustomerService";
import customerLogo from "./../../assets/images/default.png";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
// import modaldeleteicon from "./../../assets/images/delete.svg";

export class BrandForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      slug: this.props.match.params.slug,
      isSubmit: false,
      customer_name: "",
      customer_logo: "",
      imageUrl: customerLogo,
      status_id: true,
      sort_order: "",
    };
    this.customerLogo = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateBrand = this.saveOrUpdateBrand.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.opendeleteCustomerModal = this.opendeleteCustomerModal.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    if (this.state.slug !== undefined) {
      var resData = {};
      let res = await CustomerService.getCustomer(this.state.slug);

      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resData.customer_name = res.data.customer_name;
        resData.customer_logo = res.data.customer_logo;
        resData.imageUrl = res.data.customer_logo_url
          ? res.data.customer_logo_url
          : customerLogo;
        resData.status_id = res.data.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.defaultData = resData;
    }
    this.setState({ loading: false });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ loading: true });
      const fileData = new FormData();
      fileData.append(
        "customer_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      let res = await CustomerService.uploadCustomerLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          customer_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ loading: false });
      } else if (res.status === 413) {
        this.setState({
          loading: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  saveOrUpdateBrand = async (e) => {
    e.preventDefault();
    if (
      this.state.status_id === true
        ? this.validator.allValid() && this.customValidate()
        : !this.validator.errorMessages.customer_name
    ) {
      this.setState({loader:true, isSubmit: true });
      let inputData = {
        customer_name: this.state.customer_name,
        customer_logo: this.state.customer_logo,
        status_id: this.state.status_id === true ? 1 : 2,
      };

      if (this.state.slug !== undefined) {
        let res = await CustomerService.updateCustomer(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/brands/"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result && Array.isArray(res.data.result) && res.data.result.length > 0) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.forEach((item) => {
              if (item.errorField && item.errorMessage) {
                errors[item.errorField] = item.errorMessage[0];
              }
            });
            this.setState({ errors: errors });
          } else {
            alertMessage = res.data.message ? res.data.message : "An error occurred.";
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:  alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await CustomerService.createCustomer(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/brands"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ loader:false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      if (this.state.status_id !== true) {
        this.validator.showMessageFor("customer_name");
      } else {
        this.validator.showMessages();
      }
    }
  };

  opendeleteCustomerModal(slug) {
    this.setState({ deleteCustomerModal: true });
  }

  closedeleteCustomerModal() {
    this.setState({ deleteCustomerModal: false });
  }

  async deleteCustomer(slug) {
    this.setState({ loading: true, isDelete:true });
    let res = await CustomerService.deleteCustomer(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteCustomerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/brands"),
        global.redirect_time
      );
    } else {
      this.closedeleteCustomerModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.errorMessage
          ? res.data.errorMessage
          : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete:false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Update Brand";
    } else {
      return "Add Brand";
    }
  }

  render() {
    return (
      <div>
        <main>
          {this.state.showSpinner ? global.spinnerLoader : ""}
          <div className="container-fluid">
            <div className="container-fluid p-0">
              <div className="col-md-12 p-0">
                <div className="dashbaord-pageHeadertitle heading_alignment">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>
                        {this.state.slug !== undefined
                          ? this.state.customer_name
                            ? this.state.customer_name
                            : this.state.customer_name
                          : "Add Brand"}
                      </h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        <Link to={"/brands"}>
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                    </div>
                    <span>
                      {this.state.slug !== undefined
                        ? 'UPDATE Brand'
                        : "Add Brand"}
                    </span>
                  </h3>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="col-md-12 p-5 dashboardRightcard ">
                <div className="tabNavigation">
                  <form id="customer_form">
                    <div className="m-0">
                      <div className="row row-xs align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-80 pd-r-80">
                        <div className="col-md-8 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <div className="fileUpload d-flex flex-wrap justify-content-between">
                            <span>
                              <label className="hand-cursor">
                                <input
                                  onChange={this.onImageChange}
                                  type="file"
                                  name="customer_logo"
                                  tabIndex="2"
                                />
                                {this.state.imageUrl ? (
                                  <img
                                    src={this.state.imageUrl}
                                    alt="Customer Logo"
                                    width={150}
                                    height={150}
                                    className="brandLogo"
                                  />
                                ) : (
                                  ""
                                )}
                              </label>
                            </span>
                          </div>
                          {this.state.errors.customer_logo ? (
                            <div className="text-danger">
                              {this.state.errors.customer_logo}
                            </div>
                          ) : (
                            this.validator.message(
                              "brand_logo",
                              this.state.customer_logo,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        {/* col */}
                      </div>
                      <div className="row row-xs align-items-basline flex-wrap m-0 pd-t-30 pd-b-30 pd-l-80 pd-r-80">
                        {/* col */}
                        <div className="col-md-8 col-lg-6 mg-t-5 mg-md-t-0 pl-0">
                          <label className="form-label mg-b-5">Brand</label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            onChange={this.changeHandler}
                            name="customer_name"
                            value={this.state.customer_name}
                            maxLength="50"
                            tabIndex="1"
                          />
                          {this.state.errors.customer_name ? (
                            <div className="text-danger">
                              {this.state.errors.customer_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "brand_name",
                              this.state.customer_name,
                              "required|max:50",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        {/* col */}
                      </div>
                      <div className="row row-xs align-items-basline flex-wrap m-0 pd-t-30 pd-b-30 pd-l-80 pd-r-80">
                        {/* col */}
                        {global.userPermissions.checkPermission("brand-update") && (
                          <div className="col-md-8 col-lg-6 mg-md-t-0 pl-0 pr-0 d-flex align-items-center justify-content-start">
                            <label className="form-label mg-b-0">
                              {this.state.status_id ? "Active" : "Inactive"}
                            </label>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() => this.changeStatus()}
                            >
                              <span></span>
                            </div>
                          </div>
                        )}
                        {/* col */}
                      </div>
                      <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-30 pd-l-80 pd-r-80">
                        <div className="col-md-8 col-lg-6 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                          <div className="d-flex align-items-center">
                           
                         
                            <Link
                              className="gf-btn-lg-outline mg-r-7"
                              to={"/brands"}
                            >
                              Cancel
                              </Link>
                            
                            {this.state.slug == undefined && global.userPermissions.checkPermission("brand-add") && (
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveOrUpdateBrand}
                                disabled={this.state.isSubmit ? true : false}
                              >
                               
                                {this.state.isSubmit
                                  ? global.loader
                                  : "Add"}
                              </button>
                            )}

                          {this.state.slug !== undefined && global.userPermissions.checkPermission("brand-update") && (
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveOrUpdateBrand}
                                disabled={this.state.isSubmit ? true : false}
                              >
                               
                                {this.state.isSubmit
                                  ? global.loader
                                  :"Update"}
                              </button>
                            )}
                           
                          </div>
                          &emsp;
                          
                          {global.userPermissions.checkPermission("brand-delete") && (
                            
                              this.state.slug !== undefined ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.opendeleteCustomerModal(this.state.slug);
                                  }}
                                  className="detlet-text mg-r-20"
                                >
                                  Delete Brand
                                </button>
                              ) : (
                                ""
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          show={this.state.deleteCustomerModal}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <h3>Delete Brand?</h3>
              <p>
                Do you really want to delete this Brand? This process cannot be
                undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className=" m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteCustomerModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className=" m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteCustomer(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default BrandForm;

////////////////////////////////////////////////////////////
//  Program: PropertiesAddForm.jsx                        //
//  Application:  Properties                              //
//  Option: For add or update PropertiesAddForm           //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CustomerService from "../../services/CustomerService";
import CountryService from "../../services/CountryService";
import StateService from "../../services/StateService";
import PropertyService from "../../services/PropertyService";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
export class PropertiesAddForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      deletedFileList: [],
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      propertyList: [],
      customerList: [],
      countyList: [],
      stateList: [],
      errors: {},
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.slug,
      isSubmit: false,
      status_id: true,
    };

    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
  }

  async componentDidMount() {
    this.getCustomerData();
    this.getCountyData();
    this.getTimezone();
  }

  async getCustomerData(queryString = "") {
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    if (customerList.length > 0) {
      customerList.forEach((item) => {
        customerListData.push({
          label: item.customer_name,
          value: item.slug,
        });
      });
    }
    this.setState({ customerList: customerListData });
  }

  /* GET COUNTRY DATA FOR FILTER */
  async getCountyData(queryString = "") {
    var countryList = [];
    var countryListData = [];
    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    if (countryList.length > 0) {
      countryList.forEach((item) => {
        countryListData.push({
          label: item.country_name,
          value: item.slug,
        });
      });
    }

    this.setState({ countryList: countryListData });
  }
 /* GET COUNTRY DATA FOR FILTER */
 async getTimezone(queryString = "") {
  var timezoneList = [];
  var timezoneListData = [];
  let res = await CountryService.getTimezone(
    "is_dropdown=1" + (queryString ? "&" + queryString : "")
  );
  if (global.successStatus.includes(res.status)) {
    timezoneList = res.data ? res.data : [];
  }
  if (timezoneList.length > 0) {
    timezoneList.forEach((item) => {
      timezoneListData.push({
        label: item.timezone_name,
        value: item.slug,
      });
    });
  }

  this.setState({ timezoneList: timezoneListData });
}
  /* GET STATE DATA FOR FILTER */
  async getStateData(countryValue, countyValue, queryString = "") {
    var stateList = [];
    var stateListData = [];
    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        "&county_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    if (stateList.length > 0) {
      stateList.forEach((item) => {
        stateListData.push({
          label: item.state_name,
          value: item.slug,
        });
      });
    }
    this.setState({ stateList: stateListData });
  }
  // CUSTOMER Change Handler
  handleChangeCustomer(value) {
    let customer_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        customer_slug.push(item.value);
      });
    }
    this.setState({
      selectedCustomer: value,
      customer_slug: value.value,
    });
  }
  // Country Change Handler
  handleChangeCountry(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    this.setState({
      selectedCountry: value,
      country_slug: value.value,
      selectedState: [],
      state_slug: "",
      stateList: this.getStateData(value.value),
    });
  }
  handleChangeTimeZone(value){
    let timezone_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        timezone_slug.push(item.value);
      });
    }
    this.setState({
      selectedTimezone: value,
      timezone_slug: value.value,
    });
  }
  // state Change Handler
  handleChangeState(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    this.setState({
      selectedState: value,
      state_slug: value.value,
    });
  }
  saveOrUpdateProperty = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ loading: true, isSubmit: true });
      let inputData = {
        customer_slug: this.state.customer_slug,
        property_name: this.state.property_name,
        property_uuid : this.state.property_uuid,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        country_slug: this.state.country_slug,
        state_slug: this.state.state_slug,
        city: this.state.city,
        zip_code: this.state.zip_code,
        timezone_slug: this.state.timezone_slug,
        status_id: this.state.status_id === true ? 1 : 2,
      };
      // console.log("inpiut", inputData);
      let res = await PropertyService.createProperty(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/properties"),
          global.redirect_time
        );
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.result.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message ? res.data.message : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }

      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
      this.setState({ loading:false, isSubmit: false });
    } else {
      this.validator.showMessages();
    }
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;
    input[event.target.name] = value;
    //alert(event.target.name);
    this.setState({ [event.target.name]: value, input: input });

    if (event.target.name === "country_id") {
      if (event.target.value === "") {
        this.setState({
          state_id: "",
          city_id: "",
          stateList: [],
          cityList: [],
        });
      } else {
        this.getStateData(event.target.value);
      }
    }

    if (event.target.name === "state_id") {
      if (event.target.value === "") {
        this.setState({
          city_id: "",
          cityList: [],
        });
      }
    }
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("property_name");
    }
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
          <div className="container-fluid">
            <div className="row m-0">
              <div className="col-md-12 p-0">
                <div className="dashbaord-pageHeadertitle heading_alignment">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Add Property</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                        <Link to="/properties">
                          <img src={back} alt="" />
                          Back
                        </Link>
                      </div>
                    </div>
                    <span>all Properties</span>
                  </h3>
                </div>
              </div>
              <div className="col-md-12 pl-0 pr-0">
                <Alerts
                  show={this.state.showAlertModal}
                  type={this.state.alertModalType}
                  title={this.state.alertModalTitle}
                  message={this.state.alertModalMessage}
                />
              </div>
              <div className="col-md-12 mg-t-0 p-5 dashboardRightcard">
                <div className="tabNavigation">
                  <form id="customer_form">
                    <div className="m-0">
                      <div className="mg-t-0 mg-b-0">
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Brand
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedCustomer}
                              options={this.state.customerList}
                              tabIndex="1"
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeCustomer(value)
                              }
                              defaultValue={this.state.selectedCustomer}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                            {this.state.errors.customer_slug ? (
                              <div className="text-danger">
                                {this.state.errors.customer_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "brand",
                                this.state.customer_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Property
                            </label>
                            <Form.Control
                              type="text"
                              tabIndex="2"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="property_name"
                              value={this.state.property_name}
                              maxLength="191"
                            />
                            {this.state.errors.property_name ? (
                              <div className="text-danger">
                                {this.state.errors.property_name}
                              </div>
                            ) : (
                              this.validator.message(
                                "property",
                                this.state.property_name,
                                "required|max:191",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Property ID
                            </label>
                            <Form.Control
                              type="text"
                              tabIndex="2"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="property_uuid"
                              value={this.state.property_uuid}
                              maxLength="191"
                            />
                            {this.state.errors.property_uuid ? (
                              <div className="text-danger">
                                {this.state.errors.property_uuid}
                              </div>
                            ) : (
                              this.validator.message(
                                "property id",
                                this.state.property_uuid,
                                "required|max:191",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>

                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Street Address 1
                            </label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              tabIndex="3"
                              onChange={this.changeHandler}
                              name="street_1"
                              value={this.state.street_1}
                              maxLength="50"
                            />
                            {this.state.errors.street_1 ? (
                              <div className="text-danger">
                                {this.state.errors.street_1}
                              </div>
                            ) : (
                              this.validator.message(
                                "Street Address 1",
                                this.state.street_1,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Street Address 2 (Optional) 
                            </label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              tabIndex="4"
                              onChange={this.changeHandler}
                              name="street_2"
                              value={this.state.street_2}
                              maxLength="50"
                            />
                          </div>
                        </div>
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Country
                            </label>

                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              tabIndex="5"
                              value={this.state.selectedCountry}
                              options={this.state.countryList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeCountry(value)
                              }
                              defaultValue={this.state.selectedCountry}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                            {this.state.errors.country_slug ? (
                              <div className="text-danger">
                                {this.state.errors.country_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "country",
                                this.state.country_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              State
                            </label>

                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              tabIndex="6"
                              value={this.state.selectedState}
                              options={this.state.stateList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeState(value)
                              }
                              defaultValue={this.state.selectedState}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                            {this.state.errors.state_slug ? (
                              <div className="text-danger">
                                {this.state.errors.state_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "state",
                                this.state.state_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                        <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              City
                            </label>
                            <Form.Control
                              onKeyPress={
                                global.onKeyPressEvent.alphaSpaceValidation
                              }
                              type="text"
                              tabIndex="7"
                              autoComplete="off"
                              onChange={this.changeHandler}
                              name="city"
                              value={this.state.city}
                              maxLength="50"
                            />
                            {this.state.errors.city ? (
                              <div className="text-danger">
                                {this.state.errors.city}
                              </div>
                            ) : (
                              this.validator.message(
                                "city",
                                this.state.city,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Zip code
                            </label>
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              tabIndex="8"
                              onChange={this.changeHandler}
                              name="zip_code"
                              value={this.state.zip_code}
                              maxLength="10"
                            />
                            {this.state.errors.zip_code ? (
                              <div className="text-danger">
                                {this.state.errors.zip_code}
                              </div>
                            ) : (
                              this.validator.message(
                                "zip_code",
                                this.state.zip_code,
                                "required|max:50",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                        </div>
                       
                        <div className="row align-items-center flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                            <label className="form-label text-left mg-b-5">
                              Timezone
                            </label>

                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              tabIndex="5"
                              value={this.state.selectedTimezone}
                              options={this.state.timezoneList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeTimeZone(value)
                              }
                              defaultValue={this.state.selectedTimezone}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                            {this.state.errors.timezone_slug ? (
                              <div className="text-danger">
                                {this.state.errors.timezone_slug}
                              </div>
                            ) : (
                              this.validator.message(
                                "timezone",
                                this.state.timezone_slug,
                                "required",
                                { className: "text-danger" }
                              )
                            )}
                          </div>
                          <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70 d-flex align-items-center justify-content-start">
                            <label className="form-label mg-b-0 ">
                              {this.state.status_id ? "Active" : "Inactive"}{" "}
                            </label>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>

                        <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-140 pd-r-80">
                          <div className="col-md-12 btnNavigate d-flex align-items-center justify-content-between flex-row-reverse flex-wrap-bothBtn p-0">
                            <div className="d-flex align-items-center">
                              <Link to="/properties">
                                <button
                                  className="gf-btn-lg-outline mg-r-7"
                                  type="button"
                                >
                                  Cancel
                                </button>
                              </Link>
                              {global.userPermissions.checkPermission("property-add") && (
                              <button
                                className="gf-btn-lg-stroke mg-l-2"
                                type="button"
                                onClick={this.saveOrUpdateProperty}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.isSubmit ? global.loader : "ADD"}
                              </button>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default PropertiesAddForm;

////////////////////////////////////////////////////////////
//  Program: Properties List.jsx                          //
//  Application:  Properties  List                        //
//  Option: List of properties                            //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import PropertyService from "../../services/PropertyService";
import CountryService from "../../services/CountryService";
import StateService from "../../services/StateService";
import CustomerService from "../../services/CustomerService";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Alerts from "../common/Alerts";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
// import PropertySelection from "../dashboard/PropertySelection";

export class Properties extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      isSubmit: false,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      selectedCountryList: [],
      selectedStateList: [],
      isFilterTrue: false,
      perPage: 0,
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "property_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by property",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "property_name",
          text: " Property",
          className: "property_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property_name ? record.property_name : "";
          },
        },
        {
          key: "property_uuid",
          text: " Property ID",
          className: "property_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.property_uuid ? record.property_uuid : "";
          },
        },
        {
          key: "customer_id",
          text: " Brand",
          className: "customer_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.customer ? record.customer.customer_name : "";
          },
        },
        {
          key: "city",
          text: "City",
          className: "city hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.city ? record.city : "";
          },
        },
        {
          key: "state_id",
          text: " State",
          className: "state_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.state ? record.state.state_name : "";
          },
        },
        {
          key: "country_id",
          text: " Country",
          className: "country_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.country ? record.country.country_name : "";
          },
        },

        {
          key: "status_id",
          text: " Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
      countryList: [],
      stateList: [],
      cityList: [],
      country_id: "",
      state_id: "",
      city_id: "",
      isFirstLoad: true,
      lastQueryString: "",
    };
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
  }
  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('propertyFilterState'));
    const savedFormattedData = JSON.parse(localStorage.getItem('formattedDataProperties'));
    if (savedFilterState) {
      this.setState({
        selectedCountry: savedFilterState.selectedCountry,
        country_slug: savedFilterState.country_slug,
        selectedState: savedFilterState.selectedState,
        state_slug: savedFilterState.state_slug,
        selectedCustomer: savedFilterState.selectedCustomer,
        customer_slug: savedFilterState.customer_slug,
        selectedStatusList: savedFilterState.selectedStatusList,
        status_id: savedFilterState.status_id,
        isFilterTrue: true,
        module_search: savedFilterState.module_search,
        is_filter: savedFilterState.is_filter,
        formattedData: savedFormattedData || [],
      }, () => {
        let queryString =
          "is_filter=1&" +
          "country_slug=" + (this.state.country_slug ? this.state.country_slug : '') +
          "&state_slug=" + (this.state.state_slug ? this.state.state_slug : '') +
          "&customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
          "&status=" + (this.state.status_id ? this.state.status_id : '');
        this.getData(queryString);
        this.getCustomerData();
        this.setState({ tableQueryString: "", customQueryString: queryString, savedFilterState: savedFilterState });
      });
    } else {
      this.getData();
      this.getCustomerData();
    }
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getCustomerData();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  /* GET ALL PROPERTY DATA */
  async getData(queryString = "", otherData = {}) {
    this.setState({ showSpinner: true });
    if (otherData.is_reset !== 1) {
      this.setState({ isSubmit: true });
    }
    var list = [];
    var totalRecords = 0;
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    if(localStorage.getItem("outSidePorpertySlug") !== undefined &&
    localStorage.getItem("outSidePorpertySlug") !== null &&
      localStorage.getItem("outSidePorpertySlug") !== "") {
      let propertySlug = localStorage.getItem("outSidePorpertySlug");
       queryString = "property_slug=" + propertySlug;
    } 
    let res = await PropertyService.getPropertyList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total ? res.data.total : 0;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({ total_record: totalRecords, records: list });
    this.setState({ isSubmit: false, loading: false, isFilterTrue: false, showSpinner: false });
  }
  /* GET CUSTOMER DROPDOWN FOR FILTER */
  async getCustomerData(queryString = "") {
    this.setState({ isSubmit: true });
    var customerList = [];
    var customerListData = [];
    let res = await CustomerService.getCustomerList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      customerList = res.data ? res.data : [];
    }
    if (customerList.length > 0) {
      customerList.forEach((item) => {
        customerListData.push({
          label: item.customer_name,
          value: item.slug,
        });
      });
    }
    this.setState({ customerList: customerListData });
    this.setState({ isSubmit: false });
  }
  /* GET COUNTRY DATA FOR FILTER */
  async getCountryData(queryString = "") {
    var countryList = [];
    var countryListData = [];
    let res = await CountryService.getCountry(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      countryList = res.data ? res.data : [];
    }
    if (countryList.length > 0) {
      countryList.forEach((item) => {
        countryListData.push({
          label: item.country_name,
          value: item.slug,
        });
      });
    }

    this.setState({ countryList: countryListData });
  }
  /* GET STATE DATA FOR FILTER */
  async getStateData(countryValue, countyValue, queryString = "") {
    var stateList = [];
    var stateListData = [];
    let res = await StateService.getStates(
      "is_dropdown=1&country_slug=" +
        countryValue +
        "&county_slug=" +
        countyValue +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      stateList = res.data ? res.data : [];
    }
    if (stateList.length > 0) {
      stateList.forEach((item) => {
        stateListData.push({
          label: item.state_name,
          value: item.slug,
        });
      });
    }
    this.setState({ stateList: stateListData });
  }

  customFilter() {
    let queryString = 
      "is_filter=1&" +
      "country_slug=" + (this.state.country_slug ? this.state.country_slug : '') +
      "&state_slug=" + (this.state.state_slug ? this.state.state_slug : '') +
      "&customer_slug=" + (this.state.customer_slug ? this.state.customer_slug : '') +
      "&page_size=" + (this.state.perPage ? this.state.perPage : '') +
      "&status=" + (this.state.status_id ? this.state.status_id : '');
      if (this.state.perPage == this.state.total_record) {
        queryString += `&all_records=all`;
      }
    if (queryString !== "") {
        this.setState({ lastQueryString: queryString });
        this.setState({ isFilterTrue: true });
        this.setState({ is_filter: 1 });
    }
    const filterState = JSON.stringify({
      selectedCountry: this.state.selectedCountry,
      country_slug: this.state.country_slug,
      selectedState: this.state.selectedState,
      state_slug: this.state.state_slug,
      selectedCustomer: this.state.selectedCustomer,
      customer_slug: this.state.customer_slug,
      selectedStatusList: this.state.selectedStatusList,
      status_id: this.state.status_id,
      is_filter: 1
    });
    localStorage.setItem('propertyFilterState', filterState);
    const result1 = JSON.parse(filterState);
    
    const formattedData = [];
    if(result1?.selectedCountry?.label) {
      formattedData.push(`Country: ${result1?.selectedCountry?.label}`);
    }
    if(result1?.selectedState?.label) {
      formattedData.push(`State: ${result1?.selectedState?.label}`);
    }
    if(result1?.selectedCustomer?.label) {
      formattedData.push(`Brand: ${result1?.selectedCustomer?.label}`);
    }
    if(result1?.selectedStatusList?.label) {
      formattedData.push(`Status: ${result1?.selectedStatusList?.label}`);
    }
    // if (this.state.singleScreenProperty || localStorage.getItem("selectedPropertyName")) {
    //   const propertyName = this.state.singleScreenProperty 
    //     ? this.state.singleScreenProperty 
    //     : localStorage.getItem("selectedPropertyName");
    //   formattedData.push(`Property: ${propertyName}`);
    // }
    
    this.setState({ formattedData });
    localStorage.setItem('formattedDataProperties', JSON.stringify(formattedData));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({ is_filter: 0 });
    this.setState({
      selectedState: [],
      selectedCountry: [],
      selectedCustomer: [],
      country_slug: "",
      state_slug: "",
      customer_slug: "",
      stateList: [],
      lastQueryString: "",
      selectedStatusList:"",
    });
    // Remove filter state from local storage
    localStorage.removeItem('propertyFilterState');
    localStorage.removeItem('formattedDataProperties');
    this.getData("resetCall", { is_reset: 1 });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      this.setState({ selectedCountryList: [] });
      this.setState({ selectedStateList: [] });
      document.body.classList.remove("bdy_over");
    } else {
      this.getCountryData();
      this.setState({ showFilter: 1 });
      this.setState({ statusList: global.activeStatusWeb });
      document.body.classList.add("bdy_over");
    }
  };

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } 
          else if (key === "page_size") {
            // newKey = "per_page";
            this.setState({ perPage: data[key]});
          }
          params.append(newKey, data[key]);
        }
      });
      if (data.page_size == this.state.total_record) {
        params.append("all_records", "all");
      }
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
      const updatedParams = new URLSearchParams(this.state.lastQueryString);
      if (updatedParams.has('page_size')) {
        updatedParams.delete('page_size');
        // Update state with modified parameters
        this.setState({ lastQueryString: updatedParams.toString() });
    }
      // Fetch data with the updated query string
      this.getData(`${queryString}${updatedParams.toString()}`).finally(() => {
          // Hide the spinner after data is fetched
          this.setState({ showSpinner: false });
      });
    }
  };
  // CUSTOMER Change Handler
  handleChangeCustomer(value) {
    let customer_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        customer_slug.push(item.value);
      });
    }
    this.setState({
      selectedCustomer: value,
      customer_slug: value.value,
      brand_name: value.label,
    });
  }
  // Country Change Handler
  handleChangeCountry(value) {
    let country_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        country_slug.push(item.value);
      });
    }
    this.setState({
      selectedCountry: value,
      country_slug: value.value,
      selectedState: [],
      stateList: this.getStateData(value.value),
      country_name: value.label,
    });
  }
  // state Change Handler
  handleChangeState(value) {
    let state_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        state_slug.push(item.value);
      });
    }
    this.setState({
      selectedState: value,
      state_slug: value.value,
      state_name: value.label,
    });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/properties/view/" + data.slug + "/basic");
  };
  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
      status_name: value.label,
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          {this.state.showSpinner}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Properties</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                        {global.userPermissions.checkPermission("property-add") && (
                           <Link to={"/properties/add"}>
                            <button className="gf-primary-btn-small-one">
                              Add Property
                            </button>
                          </Link>
                            )}
                         
                          <Button
                            onClick={this.filterToggle}
                            className="gf-primary-btn-small-one mg-l-7"
                          >
                            {" "}
                            {this.state.showFilter === 1 ? "Filter" : "Filter"}
                          </Button>
                        </div>
                      </div>
                      <span>all Properties</span>
                    </h3>
                  </div>
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>
                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <label className="form-label text-left mg-b-5 mt-0">
                              Brand
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedCustomer}
                                options={this.state.customerList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeCustomer(value)
                                }
                                defaultValue={this.state.selectedCustomer}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <label className="form-label text-left mg-b-5">
                              Country
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedCountry}
                                options={this.state.countryList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeCountry(value)
                                }
                                defaultValue={this.state.selectedCountry}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <label className="form-label text-left mg-b-5">
                              State
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedState}
                                options={this.state.stateList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeState(value)
                                }
                                defaultValue={this.state.selectedState}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>

                            <label className="form-label text-left mg-b-5">
                              Status
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedStatusList}
                                options={this.state.statusList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeStatus(value)
                                }
                                defaultValue={this.state.selectedStatusList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>

                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 pl-0 pr-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="mg-tneg-4  brandList dashboardRightcard">
            {/* <PropertySelection
                propertySlug={this.getPropertySlug.bind(this)}
                outSidePropertySlug={this.state.outSidePropertySlug}
              /> */}
            {this.state.is_filter === 1 ? (
                <>
                  {/* <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                      <div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="checklist_table_heading">
                        {" "}
                        <span className="lspan">
                        {(this.state.formattedData && this.state.formattedData.length && this.state.formattedData.join(', ')) ? <> Search Applied On:&nbsp;{" "} </>: ''}
                        </span>
                        <span className="rspan">
                        {(this.state.formattedData && this.state.formattedData.length > 0) ? this.state.formattedData.join(', ') : ''}

                        {(this.state.formattedData && this.state.formattedData.length > 0) && (
                          <a
                            type="button"
                            onClick={this.resetData}
                            className="global-flt-clearBtn"
                          >
                            Clear
                          </a>
                        )}
                          </span>
                      {/* </div> */}
                    </div>
                    </div>
                  </div>
                </>
              ) : (
              ""
            )}
            {localStorage.getItem("outSidePorpertySlug") ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On:&nbsp;</span><span className="rspan">{localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Properties;

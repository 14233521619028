////////////////////////////////////////////////////////////
//  Program: FloorList.jsx                                //
//  Application:  Floor                                   //
//  Option: For add or update Tower                       //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import FloorService from "../../services/FloorService";
import { Link } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Alerts from "../common/Alerts";
import BuildingService from "../../services/BuildingService";
import Select from "react-select";
export class Floor extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      tableName: "property_floors",
      loading: false,
      isDelete: false,
      showSpinner: false,
      total_record: 0,
      property_slug: this.props.propertySlug,
      building_slug: this.props.buildingSlug,
      pathname: window.location.origin,
      records: [],
      buildingList: [],
      status_id: true,
      config: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by floor",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "floor_name",
          text: "Floor",
          className: "floor_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.floor_name ? record.floor_name : "";
          },
        },
        {
          key: "building_id",
          text: "Building",
          className: "building_name hand-cursor",
          sortable: true,
          cell: (record) => {
            // return record && record.building ? record.building.building_name : "";
            return record && record.building_name ? record.building_name : "";
          },
        },
        {
          key: "sort_order",
          text: "Sort Order",
          className: "floor_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.sort_order ? record.sort_order : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
      lastQueryString: "",
    };
    this.closeModal = this.closeModal.bind(this);
    this.resetDate = this.resetDate.bind(this);
    this.openModal = this.openModal.bind(this);
    this.opendeleteModal = this.opendeleteModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.saveOrUpdateFloor = this.saveOrUpdateFloor.bind(this);
  }

  async componentDidMount() {
    this.getBuildingData();
    this.getData();
    if (this.state.slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      let res = await FloorService.getFloorBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.floor_name = resDatalist.floor_name
          ? resDatalist.floor_name
          : "";
        resData.building_slug = resDatalist.building
          ? resDatalist.building.slug
          : "";
        resData.status_id = resDatalist.status_id === 1 ? true : false;
      }
      this.setState(resData);
      this.defaultData = resData;
    }
    this.setState({ loading: false });
  }

  async getMaxorder(table, buildingslug) {
    let sort_order = "";
    let res = await FloorService.getSortOrder(table, buildingslug);
    if (global.successStatus.includes(res.status)) {
      sort_order =
        res.data && res.data.max_order ? res.data.max_order[0].max_order : "";
    }
    this.setState({ sort_order: sort_order });
  }
  async getBuildingData(queryString = "") {
    var buildingList = [];
    var buildingListData = [];
    let res = await BuildingService.getBuilding(
      "is_dropdown=1" +
        "&property_slug=" +
        this.state.property_slug +
        "&" +
        queryString
    );
    if (global.successStatus.includes(res.status)) {
      buildingList = res.data ? res.data : [];
    }
    if (buildingList.length > 0) {
      buildingList.forEach((item) => {
        buildingListData.push({
          label: item.building_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      showSpinner: false,
      buildingList: buildingListData,
    });
  }
  /* for amenity type modal */
  openModal(data) {
    this.getBuildingData();
    if (data.sort_order === 0) {
      if (data.building !== undefined && data.slug != undefined) {
        this.getMaxorder(this.state.tableName, data.building_slug);
      }
    }
    var selectedBuilding = [];
    selectedBuilding = data
      ? [
          {
            value: data.building_slug,
            label: data.building_name,
          },
        ]
      : "";
    this.setState({
      addModal: true,
      showAlertModal: false,
      slug: data.slug,
      building_slug: data.building_slug ? data.building_slug : "",
      floor_name: data.floor_name,
      sort_order: data.sort_order,
      selectedBuilding: selectedBuilding,
      errors: {},
    });

    if (data.slug == undefined) {
      this.setState({ building_slug: "" });
    }
    if (data.status_id !== undefined) {
      this.setState({ status_id: data.status_id === 1 ? true : false });
    }
  }

  closeModal() {
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.setState({ addModal: false });
    this.setState({
      floor_name: "",
      sort_order: "",
      errors: {},
    });
  }

  opendeleteModal(slug) {
    this.setState({ addModal: false });
    this.setState({
      deleteModal: true,
      slug: slug,
    });
  }

  closedeleteModal() {
    this.setState({ deleteModal: false });
  }
  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await FloorService.getFloor(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : "";
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ lastQueryString: queryString });
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "", lastQueryString: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState({ is_filter_apply: queryString });
      this.getData(queryString + this.state.lastQueryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  async deleteFloor(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await FloorService.deleteFloor(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.closeModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.getData();
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  saveOrUpdateFloor = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      let inputData = {
        floor_name: this.state.floor_name ? this.state.floor_name : "",
        building_slug: this.state.building_slug ? this.state.building_slug : "",
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
      };
      if (this.state.slug !== undefined) {
        // For active status 1:Active, 2:Inactive
        inputData.status_id = this.state.status_id ? this.state.status_id : 2;
        let res = await FloorService.updateFloor(this.state.slug, inputData);

        if (global.successStatus.includes(res.status)) {
          this.closeModal();
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          this.getData(this.state.is_filter_apply);
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      } else {
        let res = await FloorService.createFloor(inputData);

        if (global.successStatus.includes(res.status)) {
          this.closeModal();
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.setState({ addModal: false }),
            global.redirect_time
          );
          setTimeout(
            () => this.getData(this.state.is_filter_apply),
            global.redirect_time
          );
        } else {
          if (res.data.result.errorDetail) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };
  // BUILDING Change Handler
  handleChangeBuilding(value) {
    let building_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        building_slug.push(item.value);
      });
    }
    this.setState(
      {
        selectedBuilding: value,
        building_slug: value.value,
      },
      () => this.getMaxorder(this.state.tableName, this.state.building_slug)
    );
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.openModal(data);
  };
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <main className="p-0">
        <div
          className={`${
            global.userPermissions.checkPermission(
              "property-update-assigned-property"
            )
              ? "btnNavigate d-flex flex-column building-btn"
              : "d-none"
          }`}
        >
          {global.userPermissions.checkPermission(
            "property-update-assigned-property"
          ) && (
            // {global.userData.role_id === 1 || global.userData.role_id === 6 || global.userData.role_id === 3 ?
            <>
              <Link to={"#"} className="w-100 d-flex justify-content-end">
                <button
                  className="gf-primary-btn-small-one "
                  onClick={this.openModal}
                >
                  Add Floor
                </button>
              </Link>
            </>
          )}
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        {this.state.showSpinner ? global.spinnerLoader : ""}
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0">
            <div className="col-md-12 ">
              <div className="dashboardRightcard mt-top-7neg">
                <div className="mg-t-18 brandList">
                  <ReactDatatable
                    className="table no-wrap mg-b-30 pd-b-2"
                    config={this.state.config}
                    records={this.state.records}
                    columns={this.state.columns}
                    extraButtons={this.state.extraButtons}
                    dynamic={true}
                    loading={this.state.loading}
                    total_record={this.state.total_record}
                    onChange={this.tableChangeHandler}
                    onRowClicked={
                      global.userPermissions.checkPermission(
                        "property-update-assigned-property"
                      ) && this.rowClickedHandler
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ht-50"></div>
        </div>

        {/* add Floor popup modal */}
        <Modal show={this.state.addModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">
              {this.state.slug !== undefined ? "Update Floor" : "Add Floor"}
            </h3>
            <label>Building</label>

            <Select
              className="multiselect new_drop"
              menuPlacement="auto"
              value={this.state.selectedBuilding}
              options={this.state.buildingList}
              isSearchable={true}
              onChange={(value) => this.handleChangeBuilding(value)}
              defaultValue={this.state.selectedBuilding}
              autoComplete="off"
              getOptionValue={(option) => `${option.label}`}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary75: "#808080",
                  primary50: "#808080",
                  primary25: "#808080",
                },
              })}
            />
            {this.state.errors.building_slug ? (
              <div className="text-danger">
                {this.state.errors.building_slug}
              </div>
            ) : (
              this.validator.message(
                "Building",
                this.state.building_slug,
                "required",
                { className: "text-danger" }
              )
            )}

            <label className="form-label text-left mg-b-0 mg-t-15">Floor</label>
            <Form.Control
              onChange={this.changeHandler}
              name="floor_name"
              value={this.state.floor_name}
              className="form-control max_width_100 "
              type="text"
              maxLength="50"
              autoComplete="off"
              autoFocus={true}
            />
            {this.state.errors.floor_name ? (
              <div className="text-danger">{this.state.errors.floor_name}</div>
            ) : (
              this.validator.message(
                "floor",
                this.state.floor_name,
                "required",
                { className: "text-danger" }
              )
            )}
            <label className="form-label text-left mg-b-0 mg-t-15">
              Sort Order(Optional)
            </label>
            <Form.Control
              onChange={this.changeHandler}
              name="sort_order"
              value={this.state.sort_order}
              className="form-control max_width_100 "
              type="text"
              maxLength="50"
            />
            {global.userPermissions.checkPermission(
              "property-update-assigned-property"
            ) && (
              <div className="d-flex align-items-center justify-content-between mg-b-30 pd-b-3 mg-t-15">
                <div className="d-flex">
                  <b
                    className={
                      this.state.status_id == 1 ? "active" : "inactive"
                    }
                  >
                    {this.state.status_id == 1 ? "Active" : "Inactive"}
                  </b>
                  <div
                    className={
                      this.state.status_id == 1
                        ? "az-toggle on hand-cursor"
                        : "az-toggle hand-cursor"
                    }
                    onClick={() =>
                      this.setState({ status_id: !this.state.status_id })
                    }
                  >
                    <span></span>
                  </div>
                </div>
                {/* && (global.userData.role_id === 1 || global.userData.role_id === 6 || global.userData.role_id === 3) */}
                {this.state.slug !== undefined ? (
                  <Link
                    onClick={() => {
                      this.opendeleteModal(this.state.slug);
                    }}
                    className="delete-text"
                  >
                    Delete Floor
                  </Link>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="d-flex cnt_center mt-3 row">
              <button
                type="button"
                className="m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal()}
              >
                Cancel
              </button>
              {global.userPermissions.checkPermission(
                "property-update-assigned-property"
              ) && (
                <button
                  type="button"
                  className="m-0 btn btn btn-success btn-block"
                  onClick={this.saveOrUpdateFloor}
                >
                  {this.state.isSubmit
                    ? global.loader
                    : this.state.slug !== undefined
                    ? "UPDATE"
                    : "ADD"}
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {/* add delete modal */}
        <Modal show={this.state.deleteModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14 h-380">
            <div className="text-center">
              <h3>Delete Floor?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Are you sure you want to delete this floor? This process cannot
                be reversed
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteFloor(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default Floor;

////////////////////////////////////////////////////////////
//  Program: Inspection List.jsx                          //
//  Application:  Inspection  List                        //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-06                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import AuditService from "../../services/AuditService";
import AuditTemplateService from "../../services/AuditTemplateService";
import { Row, Col, Modal } from "react-bootstrap";
import Moment from "moment";
import UserService from "../../services/UserService";
import back from "./../../assets/images/back.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import PropertyService from "../../services/PropertyService";
import PropertySelection from "../dashboard/PropertySelection";
// import {Link} from "react-router-dom";

export class Audit extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      property_slug: "",
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      hideCommercial: 0,
      hideEquipment: 0,
      hideRoom: 0,
      records: [],
      roomList: [],
      zoneList: [],
      comerList: [],
      userList: [],
      checkList: [],
      statusList: [],
      selectedStatusList: [],
      status_id: "",
      user_slug: "",
      template_slug: "",
      isFilterTrue: false,
      showSpinner: false,
      startDate: "",
      endDate: "",
      perPage: 0,
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      config: {
        sort: {
          column: "audit_id",
          order: "desc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by audit no.",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "audit_no",
          text: "Audit No.",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.audit_no ? "#" + record.audit_no : "";
          },
        },
        {
          key: "audit_template_id",
          text: "Audit Template",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.audit_name
              ? record.audit_name
              : "";
          },
        },
        {
          key: "property_name",
          text: "Property ",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property_name ? record.property_name : "";
          },
        },
        {
          key: "conducted_on",
          text: "Conducted on",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            // return Moment(record.started_on).format("DD MMM, YY - hh:mm A");
            return Moment(record.started_on).format("DD MMM, YY - hh:mm A");
          },
        },
        {
          key: "conducted_by",
          text: "Created By",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.full_name ? record.full_name : "";
          },
        },
        {
          key: "over_all",
          text: "Overall Score %",
          className: "property hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.audit_percentage + "%";
          },
        },
        {
          key: "audit_status",
          text: "Status",
          className: "hand-cursor",
          sortable: true,
          cell: (record) => {
            if (record.audit_status == 1) return "In Progress";
            else if (record.audit_status == 2) return "Follow up";
            else if (record.audit_status == 3) return "Completed";
            else return "";
          },
        },
      ],
      isFirstLoad: true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
  }
  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('auditFilterState'));
    const savedFormattedData = JSON.parse(localStorage.getItem('formattedDataAudit'));
    if (savedFilterState) {
      this.setState({
        user_slug: savedFilterState.user_slug,
        template_slug: savedFilterState.template_slug,
        property_slug: savedFilterState.property_slug,
        startDate: savedFilterState.startDate ? new Date(savedFilterState.startDate) : null,
        endDate: savedFilterState.endDate ? new Date(savedFilterState.endDate) : null,
        status_id: savedFilterState.status_id,
        selectedUserList: savedFilterState.selectedUserList,
        selectedChecklist: savedFilterState.selectedChecklist,
        selectedProperty: savedFilterState.selectedProperty,
        selectedStatusList: savedFilterState.selectedStatusList,
        isFilterTrue: true,
        module_search: savedFilterState.module_search,
        is_filter: savedFilterState.is_filter,
        formattedData: savedFormattedData || [],
      }, () => {
        let start_date = "";
        let end_date = "";
        if (this.state.startDate) {
          start_date = Moment(this.state.startDate).format("YYYY-MM-DD");
        }
        if (this.state.endDate) {
          end_date = Moment(this.state.endDate).format("YYYY-MM-DD");
        }
        let queryString =
          "is_filter=1&" +
          "user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
          "&template_slug=" + (this.state.template_slug ? this.state.template_slug : '') +
          "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&status_id=" + (this.state.status_id ? this.state.status_id : '') +
          "&created_at=" + (start_date ? start_date : '') +
          "&created_on=" + (end_date ? end_date : '');
        this.getData(queryString);
        this.getUserList();
        this.getAuditTemplate();
        this.getProperty();
        this.setState({ tableQueryString: "", customQueryString: queryString, savedFilterState: savedFilterState });
      });
    } else {
      this.getData();
      this.getUserList();
      this.getAuditTemplate();
      this.getProperty();
      this.setState({ zoneTypeList: global.zoneTypeList });
    }
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getUserList();
      this.getAuditTemplate();
      this.getProperty();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    // let localSelectedProperty = "";
    // if (localStorage.getItem("activePropertyCount") == 1) {
    //   localSelectedProperty = {
    //     label: localStorage.getItem("singlePropertyName"),
    //     value: localStorage.getItem("singlePorpertySlug"),
    //   };
    // } else {
    //   if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
    //     localStorage.getItem("outSidePorpertySlug") !== null &&
    //     localStorage.getItem("outSidePorpertySlug") !== "") {
    //     let propertySlug = localStorage.getItem("outSidePorpertySlug");
    //     let propertyName = localStorage.getItem("selectedPropertyName");
    //     queryString = "property_slug=" + propertySlug;
    //      localSelectedProperty = {
    //       label: propertyName,
    //       value: propertySlug,
    //     };
    //   }
    // }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";
    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = "property_slug=" + propertySlug;
          this.setState({fistLoad:true});
        } else {
          queryString = queryString + "&property_slug=" + propertySlug;
}
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = [];
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    var list = [];
    var totalRecords = 0;
    let res = await AuditService.getAuditList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total ? res.data.total : 0;
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
      total_record: totalRecords,
      selectedProperty:localSelectedProperty,
      showSpinner: false
    });


    this.setState({
      completed_on: Moment(list.completed_on).format("DD MMM, YY - hh:mm A"),
    });
  }

  async getAuditTemplate(queryString = "") {
    var list = [];
    var listData = [];
    let res = await AuditTemplateService.getAuditTemplateList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data : [];
    }
    if (list.length > 0) {
      list.forEach((item) => {
        listData.push({
          label: item.audit_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      checkList: listData,
    });
  }
  async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
    });
  }
  // Get Users List
  async getUserList(queryString = "") {
    this.setState({ loading: true });
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      // if (item.role_id >= global.roleTypeId) {
        userListData.push({
          label: item.full_name,
          value: item.slug,
        });
      // }
    });

    this.setState({ userList: userListData });
    this.setState({ loading: false });
  }
  customFilter() {
    let start_date = "";
    let end_date = "";
    if (this.state.startDate) {
      start_date = Moment(this.state.startDate).format("YYYY-MM-DD");
    }
    if (this.state.endDate) {
      end_date = Moment(this.state.endDate).format("YYYY-MM-DD");
    }

    let queryString =
    "is_filter=1" +
    "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
    "&template_slug=" + (this.state.template_slug ? this.state.template_slug : '') +
    "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&status_id=" + (this.state.status_id ? this.state.status_id : '') +
    "&created_at=" + (start_date ? start_date : '') +
    "&page_size=" + (this.state.perPage ? this.state.perPage : '') +
    "&created_on=" + (end_date ? end_date : '');
    if (this.state.perPage == this.state.total_record) {
      queryString += `&all_records=all`;
    }
    if (queryString !== "") {
      this.setState({ lastQueryString: queryString });
      this.setState({ is_filter: 1 });
    }
    // Save the filter state to local storage

    const dataFiltered = JSON.stringify({
      user_slug: this.state.user_slug,
      template_slug: this.state.template_slug,
      property_slug: this.state.property_slug,
      out_time: this.state.out_time,
      status_id: this.state.status_id,
      selectedUserList: this.state.selectedUserList,
      selectedChecklist: this.state.selectedChecklist,
      selectedProperty: this.state.selectedProperty,
      selectedStatusList: this.state.selectedStatusList,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      is_filter: 1
    })
    localStorage.setItem('auditFilterState', dataFiltered);
    const result1 = JSON.parse(dataFiltered);
    
    const formattedData = [];
    if (result1?.selectedProperty?.label) {
      formattedData.push(`Property: ${result1?.selectedProperty?.label}`);
    } else {
      if (
        (this.state.singleScreenProperty && this.state.singleScreenProperty !== "All Properties") || 
        (localStorage.getItem("selectedPropertyName") && localStorage.getItem("selectedPropertyName") !== "All Properties")
      ) {
        const propertyName = this.state.singleScreenProperty 
          ? this.state.singleScreenProperty 
          : localStorage.getItem("selectedPropertyName");
        formattedData.push(`Property: ${propertyName}`);
      }
    }
    if (result1?.selectedChecklist?.label) {
      formattedData.push(`Template: ${result1?.selectedChecklist?.label}`);
    }
    if (result1?.selectedUserList?.label) {
      formattedData.push(`Created By: ${result1?.selectedUserList?.label}`);
    }
    if(result1?.startDate) {
      formattedData.push(`Start Date: ${Moment(result1?.startDate).format("DD MMM, YY")}`);
    }
    if(result1?.endDate) {
      formattedData.push(`End Date: ${Moment(result1?.endDate).format("DD MMM, YY")}`);
    }
    this.setState({ formattedData });
    localStorage.setItem('formattedDataAudit', JSON.stringify(formattedData));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({ is_filter: 0 });
    this.setState({
      startDate: "",
      endDate: "",
      selectedChecklist: "",
      selectedProperty: "",
      selectedUserList: "",
      user_slug: "",
      template_slug: "",
      property_slug: "",
      date: "",
      status_id: "",
      selectedStatusList: [],
      lastQueryString: "",
      singleScreenProperty: "",
      singleScreen: false,
      formattedData: [],
    });
     // Clear the filter state from local storage
     localStorage.removeItem('auditFilterState');
     localStorage.removeItem('formattedDataAudit');
    this.getData("resetCall");
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ zoneTypeList: global.zoneTypeList });
      this.setState({ showFilter: 1 });
      this.setState({ statusList: global.auditStatus });
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Status Change Handler
  handleChangeStatus(value) {
    let status_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        status_id.push(item.value);
      });
    }
    this.setState({
      selectedStatusList: value,
      status_id: value.value,
      status_name: value.label,
    });
  }

  tableChangeHandler = (data) => {
    // Show the spinner when the table change starts
    this.setState({ showSpinner: true });
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            // newKey = "per_page";
            this.setState({ perPage: data[key]});
          }
          params.append(newKey, data[key]);
        }
      });
      if (data.page_size == this.state.total_record) {
        params.append("all_records", "all");
      }
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => {
            this.getData(queryString).finally(() => {
              // Hide the spinner after data is fetched
              this.setState({ showSpinner: false });
            });
          },
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
      const updatedParams = new URLSearchParams(this.state.lastQueryString);
      if (updatedParams.has('page_size')) {
        updatedParams.delete('page_size');
        // Update state with modified parameters
        this.setState({ lastQueryString: updatedParams.toString() });
    }
      // Fetch data with the updated query string
      this.getData(`${queryString}${updatedParams.toString()}`).finally(() => {
          // Hide the spinner after data is fetched
          this.setState({ showSpinner: false });
      });
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/audits/" + data.slug);
  };
  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
      user_name1: value.label,
    });
  }

  //property type handle
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.setState({
      selectedProperty: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
      property_name: value.label,
    });
  }
  //template type handle
  handleChangeChecklist(value) {
    let template_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        template_slug.push(item.value);
      });
    }
    this.setState({
      selectedChecklist: value,
      template_slug: value.value,
      template_name: value.label,
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="brandSection">
                  <div className="dashbaord-pageHeadertitle">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Audits</h3>
                        <div className="btnNavigate d-flex justify-content-end">
                       
                          <button
                            onClick={this.filterToggle}
                            className="gf-primary-btn-small-one"
                          >
                            {" "}
                            {this.state.showFilter === 1 ? "Filter" : "Filter"}
                          </button>
                        </div>
                      </div>
                      <span>All Audits</span>
                    </h3>
                  </div>
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>

                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                        <Row className="align-items-start justify-content-between m-0 p-0">
                          <label className="form-label text-left mg-b-5">
                            Property
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedProperty}
                              options={this.state.propertyList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeProperty(value)
                              }
                              defaultValue={this.state.selectedProperty}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Template
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedChecklist}
                              options={this.state.checkList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeChecklist(value)
                              }
                              defaultValue={this.state.selectedChecklist}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Created By
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedUserList}
                              options={this.state.userList}
                              onChange={(value) =>
                                this.handleChangeUserList(value)
                              }
                              defaultValue={this.state.selectedUserList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              Start Date{" "}
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="startDate"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.startDate}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "startDate",
                                    value: data,
                                  },
                                })
                              }
                              maxDate={this.state.endDate}
                            />
                          </Col>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0 pb-0"
                          >
                            <label className="form-label text-left mg-b-5">
                              End Date{" "}
                            </label>
                            <DatePicker
                              className="form-control max_width_100"
                              showMonthDropdown
                              showYearDropdown
                              scrollableYearDropdown
                              autoComplete="off"
                              yearDropdownItemNumber={
                                global.yearDropdownItemNumber
                              }
                              name="endDate"
                              placeholderText="dd-mm-yy"
                              dateFormat="dd MMM yy"
                              selected={this.state.endDate}
                              onChange={(data) =>
                                this.changeHandler({
                                  target: {
                                    type: "date",
                                    name: "endDate",
                                    value: data,
                                  },
                                })
                              }
                              minDate={this.state.startDate}
                            />
                          </Col>
                          <label className="form-label text-left mg-b-5">
                            Status
                          </label>
                          <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedStatusList}
                              options={this.state.statusList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeStatus(value)
                              }
                              defaultValue={this.state.selectedStatusList}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>

                            <label className="form-label text-left mg-b-5">
                              Status
                            </label>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedStatusList}
                                options={this.state.statusList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeStatus(value)
                                }
                                defaultValue={this.state.selectedStatusList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>

                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className=" brandList dashboardRightcard">
            <PropertySelection
                propertySlug={this.getPropertySlug.bind(this)}
                outSidePropertySlug={this.state.outSidePropertySlug}
              />
            {this.state.is_filter === 1 ? (
                <>
                  {/* <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                      <div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="checklist_table_heading">
                        {" "}
                        <span className="lspan">
                        {(this.state.formattedData && this.state.formattedData.length && this.state.formattedData.join(', ')) ? <> Search Applied On:&nbsp;{" "} </>: ''}
                        </span>
                        <span className="rspan">
                        {(this.state.formattedData && this.state.formattedData.length > 0) ? this.state.formattedData.join(', ') : ''}

                        {(this.state.formattedData && this.state.formattedData.length > 0) && (
                          <a
                            type="button"
                            onClick={this.resetData}
                            className="global-flt-clearBtn"
                          >
                            Clear
                          </a>
                        )}
                          </span>
                      {/* </div> */}
                    </div>
                    </div>
                  </div>
                </>
              ) : (
              ""
            )}
            {localStorage.getItem("outSidePorpertySlug") && (this.state.formattedData == undefined || this.state.formattedData.length == 0) ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On:&nbsp;</span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
        {/* Detail popup Modal */}
        <Modal className="" show={this.state.addCostPayableAtModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <h3 className="text-center">Log</h3>
            <p className="text-center font-weight-bold">
              DAILY LOG {this.state.row_index}
            </p>
            <p className="text-center">
              {this.state.created_date} . {this.state.user_name}
            </p>
            <p className="text-center">{this.state.note}</p>

            <div className="d-flex justify-content-between mt-3 row">
              <button
                type="button"
                className="mx-auto m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeLogDetailAtModal()}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Audit;

////////////////////////////////////////////////////////////
//  Program: TemplateForm.jsx                             //
//  Application:  Template Form                           //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-01                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import ChecklistService from "../../services/ChecklistService";
import pluswIcon from "./../../assets/images/plus.svg";
import EditpenIcon from "./../../assets/images/edit-pen-icon.svg";
import deleteicon from "../../assets/images/delete-gray.svg";
import back from "./../../assets/images/back.svg";
import Select from "react-select";
import Alerts from "../common/Alerts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LinkModal from "../LinkModal";
import exportIcon from "./../../assets/images/export-icon.svg";

export class TemplatesForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      status_id: true,
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug,
      isSubmit: false,
      loader: false,
      isDelete: false,
      sort_order: "",
      pathname: window.location.pathname,
      toggleSwitchDark2: true,
      zoneTypeList: [],
      formValues: [
        { category_name: "", task_name: [{ name: "", viewMode: false }] },
      ],
    };
    this.quillRefs = [];
    this.saveOrUpdateTemplate = this.saveOrUpdateTemplate.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.opendeleteModal = this.opendeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleChangeQuill = this.handleChangeQuill.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.insPdfGenerator = this.insPdfGenerator.bind(this);
  }

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    if (this.state.slug !== undefined) {
      return "Inspection Templates";
    } else {
      return "Inspection Templates";
    }
  }
  getsubTitle() {
    if (this.state.slug !== undefined) {
      return "Update Templates";
    } else {
      return "Add Templates";
    }
  }
  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };
  /***** handle all category add and remove  */
  handleChange(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }
  addFormFields() {
    this.setState(
      {
        formValues: [
          ...this.state.formValues,
          { category_name: "", task_name: [{ task_name: "" }] },
        ],
      },
      () => {
        // Focus on the first ReactQuill of the newly added category
        const lastCategoryIndex = this.state.formValues.length - 1;
        if (
          this.quillRefs[lastCategoryIndex] &&
          this.quillRefs[lastCategoryIndex][0]
        ) {
          this.quillRefs[lastCategoryIndex][0].getEditor().focus();
        }
      }
    );
  }
  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }
  /* handle all task add and remove */
  handleChangeTask(index, taskIndex) {
    let formValues = [...this.state.formValues];
    formValues[index].task_name[taskIndex].viewMode = true;
    this.setState({ formValues }, () => {
      if (this.quillRefs[index] && this.quillRefs[index][taskIndex]) {
        this.quillRefs[index][taskIndex].getEditor().focus();
      }
    });
  }
  addFormFieldsTask(k, m) {
    this.setState(
        (prevState) => {
            const formValues = [...prevState.formValues];
            formValues[k].task_name.push({ task_name: "", viewMode: true });
            return { formValues };
        },
        () => {
            // Focus on the newly added ReactQuill editor
            const lastTaskIndex = this.state.formValues[k].task_name.length - 1;
            if (this.quillRefs[k] && this.quillRefs[k][lastTaskIndex]) {
          this.quillRefs[k][lastTaskIndex].focus();
            }
        }
    );
}

  removeFormFieldsTask(k, m) {
    let formValues = this.state.formValues;
    formValues[k].task_name.splice(m, 1);
    this.setState({ formValues });
  }
  async componentDidMount() {
    if (this.state.slug !== undefined) {
      var resData = {};
      var resDatalist = {};
      let res = await ChecklistService.getChecklistRowBySlug(this.state.slug);
      if (global.successStatus.includes(res.status)) {
        resData = res.data ? res.data : {};
        resDatalist = resData.data ? resData.data : {};
        resData.checklist_name = resDatalist.checklist_name
          ? resDatalist.checklist_name
          : "";
        resData.description = resDatalist.description
          ? resDatalist.description
          : "";
        resData.formValues = resDatalist.category
          ? resDatalist.category.map((category) => ({
              category_name: category.category_name,
              task_name: category.task_name.map((task) => ({
                task_name: task.task_name,
                viewMode: false,
              })),
            }))
          : "";
        resData.status_id = resDatalist.status_id === 1 ? true : false;
        resData.zone_type = resDatalist.zone
          ? resDatalist.zone.zone_type_id
          : "";
      }
      let zoneType = parseInt(resDatalist.zone.zone_type_id);
      let selectedZoneTypeList = [];
      global.templateOrCheckListzoneTypeList.forEach((item, i) => {
        if (parseInt(item.value) === zoneType) {
          selectedZoneTypeList = [
            {
              value: item.value,
              label: item.label,
            },
          ];
          return false;
        }
      });
      this.setState(resData);
      this.setState({ selectedZoneTypeList: selectedZoneTypeList });
      this.setState({ formValues: resData.formValues });
    } else {
      this.setState({
        formValues: [{ category_name: "", task_name: [{ name: "" }] }],
      });
    }
    this.setState({ zoneTypeList: global.templateOrCheckListzoneTypeList });
    // setTimeout(() => {
    //   this.quillRefs.forEach((refArray, index) => {
    //     if (Array.isArray(refArray)) {
    //       refArray.forEach((ref, taskIndex) => {
    //         if (ref && ref.getEditor) {
    //           const quill = ref.getEditor();
    //           quill
    //             .getModule("toolbar")
    //             .addHandler("link", () => this.handleLink(index, taskIndex));
    //           quill.root.addEventListener("click", this.handleLinkClick);
    //         }
    //       });
    //     }
    //   });
    // }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.currentlyFocusedInput) {
      this.currentlyFocusedInput.focus();
    }
    if (prevState.formValues.length !== this.state.formValues.length) {
      setTimeout(() => {
        this.quillRefs.forEach((refArray, index) => {
          if (Array.isArray(refArray)) {
            refArray.forEach((ref, taskIndex) => {
              if (ref && ref.getEditor) {
                const quill = ref.getEditor();
                quill
                  .getModule("toolbar")
                  .addHandler("link", () => this.handleLink(index, taskIndex));
                quill.root.addEventListener("click", this.handleLinkClick);
              }
            });
          }
        });
      }, 100);
    }
  }

  componentWillUnmount() {
    this.quillRefs.forEach((refArray) => {
      if (Array.isArray(refArray)) {
        refArray.forEach((ref) => {
          if (ref && ref.getEditor) {
            const quill = ref.getEditor();
            quill.root.removeEventListener("click", this.handleLinkClick);
          }
        });
      }
    });
  }

  // componentWillUnmount() {
  //   this.quillRefs.forEach((refArray) => {
  //     if (Array.isArray(refArray)) {
  //       refArray.forEach((ref) => {
  //         if (ref && ref.getEditor) {
  //           const quill = ref.getEditor();
  //           quill.root.removeEventListener("click", this.handleLinkClick);
  //         }
  //       });
  //     }
  //   });
  // }

  async insPdfGenerator() {
    this.setState({ loading: true });
    var list = [];
    let res = await ChecklistService.getTemplatePdfBySlug(this.state.slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data.report["0"] ? res.data.report["0"] : [];
    }
    this.setState({ list: list });
    this.setState({ loading: false });
    window.open(this.state.list.filePath, "_blank");
  }
  handleLink(index, taskIndex) {
    const quill = this.quillRefs[index][taskIndex].getEditor();
    const range = quill.getSelection();
    if (range) {
      this.setState({
        showModal: true,
        range: { index, taskIndex, range },
        url: "",
        text: "",
        isEditing: false,
      });
    }
  }

  handleModalClose() {
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleModalSubmit(url, text) {
    const { range } = this.state;
    if (range && url && text) {
      const { index, taskIndex, range: quillRange } = range;
      const quill = this.quillRefs[index][taskIndex].getEditor();
      if (quill) {
        quill.deleteText(quillRange.index, quillRange.length);
        quill.insertText(quillRange.index, text, "link", url);
        quill.focus();
        quill.setSelection(quillRange.index + text.length);
      }
    }
    this.setState({
      showModal: false,
      range: null,
      url: "",
      text: "",
      isEditing: false,
    });
  }

  handleLinkClick(event) {
    if (event.target.tagName === "A") {
      event.preventDefault();
      const { range } = this.state;
      if (range) {
        const { index, taskIndex } = range;
        const quill = this.quillRefs[index][taskIndex].getEditor();
        const quillRange = quill.getSelection();
        const url = event.target.getAttribute("href");
        const text = event.target.innerText;

        // Prevent Quill's tooltip from showing
        quill.blur();
        // quill.focus();

        this.setState({
          showModal: true,
          range: { index, taskIndex, range: quillRange },
          url,
          text,
          isEditing: true,
        });
      }
    }
  }

  handleModalRemove() {
    const quill = this.quillRefs[this.state.range.index].getEditor();
    const textLength = quill.getLength();

    // Get the entire content and iterate over each character
    for (let i = 0; i < textLength; i++) {
      const [link] = quill.getFormat(i, 1).link || [];
      if (link) {
        // Remove the link format only, leaving the text intact
        quill.formatText(i, 1, "link", false);
      }
    }

    this.setState({ showModal: false, url: "", text: "", isEditing: false });
  }

  handleChangeQuill(index, taskIndex, value) {
    const formValues = [...this.state.formValues];
    formValues[index].task_name[taskIndex].task_name = value;
    // this.setState({ formValues });
  }
  saveOrUpdateTemplate = async (e) => {
    e.preventDefault();
    let inputData = {
      checklist_name: this.state.checklist_name
        ? this.state.checklist_name
        : "",
      zone_type: this.state.zone_type ? this.state.zone_type : "",
      description: this.state.description ? this.state.description : "",
      formValues: this.state.formValues ? this.state.formValues : "",
      status_id: this.state.status_id === true ? 1 : 2,
      is_checklist: 1,
    };
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      if (this.state.slug !== undefined) {
        let res = await ChecklistService.updateCheckList(
          this.state.slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/templates"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      } else {
        let res = await ChecklistService.createCheckList(inputData);
        if (global.successStatus.includes(res.status)) {
          this.setState({
            showAlertModal: true,
            alertModalType: "success",
            alertModalTitle: "Success",
            alertModalMessage: res.message ? res.message : "Success",
          });
          setTimeout(
            () => this.props.history.push("/templates"),
            global.redirect_time
          );
        } else {
          let alertMessage = "";
          if (
            res.data.errorCode === "validationFailed" &&
            res.data.errorDetail !== null
          ) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.message ? res.data.message : "Error!",
            alertModalMessage:
              alertMessage !== "" ? alertMessage : res.data.message,
          });
        }
      }
      this.setState({ isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
    }
  };
  async deleteChecklist(slug) {
    this.setState({ loading: true, isDelete: true });
    let res = await ChecklistService.deleteCheckList(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.props.history.push("/templates"),
        global.redirect_time
      );
    } else {
      this.closedeleteModal();
      let alertMessage = "";
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ loading: false, isDelete: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }
  closedeleteModal() {
    this.setState({ deleteModal: false });
  }
  opendeleteModal(slug) {
    this.setState({ deleteModal: true });
  }
  //zone type handle
  handleChangeZoneType(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
    });
  }

  handleInputFocus = (event) => {
    // console.log(event.target.name);
    if (event.target && event.target.name === "checklist_name") {
      this.currentlyFocusedInput = event.target;
    } else if (event.target && event.target.name === "category_name") {
      this.currentlyFocusedInput = event.target;
    } else if (event.target && event.target.name === "description") {
      this.currentlyFocusedInput = event.target;
    }
  };

  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement("div");
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    const modules = (toolbarId, handleLink) => ({
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {
          link: function (value) {
            if (value) {
              const range = this.quill.getSelection();
              if (range) {
                handleLink(); // Call the handleLink function passed as a prop
              }
            } else {
              this.quill.format("link", false);
            }
          },
        },
      },
    });
    return (
      <main>
        <div className="container-fluid">
          <div className="row row-xs">
            <div className="col-md-12">
              <div className="dashbaord-pageHeadertitle heading_alignment">
                <h3 className="flex-column">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <h3>{this.getTitle()}</h3>
                    <div className="btnNavigate d-flex justify-content-end">
                      <Link to="/templates">
                        <img src={back} alt="" />
                        Back
                      </Link>
                    </div>
                  </div>
                  <span> {this.getsubTitle()}</span>
                </h3>
                  {this.state.slug !== undefined && (
                        <button
                          onClick={this.insPdfGenerator}
                          className="gf-primary-btn-small-one loader_btn pdf_gen export_pdf_btn btn btn-primary ml-auto"
                        >
                          <img src={exportIcon} alt="" />
                            {this.state.loading ? global.loader : "Export PDF"}
                        </button>)}
              </div>
            </div>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <Alerts
              show={this.state.showAlertModal}
              type={this.state.alertModalType}
              title={this.state.alertModalTitle}
              message={this.state.alertModalMessage}
            />
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12">
                <div className="scrolling-carousel p-0">
                  <div className="dashboardRightcard mgt_11">
                    <div className="mg-t-0 mg-b-0">
                      <div className="az-content-label titleLable">Info</div>
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-70">
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                          <label className="form-label mg-b-5">Template Name</label>
                          <Form.Control
                            onChange={this.changeHandler}
                            autoComplete="off"
                            name="checklist_name"
                            value={this.state.checklist_name ?? ""}
                            className="form-control max_width_100"
                            type="text"
                            onFocus={(e) => this.handleInputFocus(e)}
                          />
                          {this.state.errors.checklist_name ? (
                            <div className="text-danger">
                              {this.state.errors.checklist_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "template",
                              this.state.checklist_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70">
                          <label className="form-label mg-b-5">Zone</label>
                          <Select
                            className="multiselect new_drop"
                            menuPlacement="auto"
                            value={this.state.selectedZoneTypeList ?? []}
                            options={this.state.zoneTypeList}
                            isSearchable={true}
                            onChange={(value) =>
                              this.handleChangeZoneType(value)
                            }
                            defaultValue={this.state.selectedZoneTypeList}
                            autoComplete="off"
                            getOptionValue={(option) => `${option.label}`}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary75: "#808080",
                                primary50: "#808080",
                                primary25: "#808080",
                              },
                            })}
                          />
                          {this.state.errors.zone_type ? (
                            <div className="text-danger">
                              {this.state.errors.zone_type}
                            </div>
                          ) : (
                            this.validator.message(
                              "zone",
                              this.state.zone_type,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pr-0">
                          <label className="form-label mg-b-5">
                            Description (Optional)
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            autoComplete="off"
                            name="description"
                            value={this.state.description ?? ""}
                            type="textarea"
                            as="textarea"
                            maxLength="500"
                            placeholder="Type here..."
                            onFocus={(e) => this.handleInputFocus(e)}
                          />
                        </div>
                      </div>
                      <hr className="mg-t-30 mg-b-20 pd-t-1 pd-b-3" />
                      <div className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-30 pd-r-20">
                        <div className="col-md-6 col-lg-6 mg-t-5 mg-md-t-0 mg-b-0 pd-b-1 pd-r-0 pl-0">
                          <div className="az-content-label titleLable pl-0">
                            <span className="pd-t-10 d-block">Tasks</span>
                          </div>
                        </div>
                        
                      </div>

                      {this.state.formValues.map((element, index) => (
                        <div
                          className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-20"
                          key={index}
                        >
                          <div className="col-md-9 col-lg-9 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-0">
                            <label className="form-label text-left mg-b-5">
                              Category
                            </label>
                            <Form.Control
                              name="category_name"
                              className="form-control max_width_100"
                              autoComplete="off"
                              type="text"
                              onFocus={(e) => this.handleInputFocus(e)}
                              value={
                                element.category_name !== ""
                                  ? element.category_name
                                  : ""
                              }
                              onChange={(e) => this.handleChange(index, e)}
                            />
                          </div>
                          <div className="col-lg-3 align-items-center d-flex">
                            {/* {index && (global.userData.role_id === 1 || global.userData.role_id === 2 || global.userData.role_id === 5) ? ( */}
                            {global.userPermissions.checkPermission(
                              "checklist-templates-add"
                            ) && (
                              <button
                                type="button"
                                className="button deleteCat "
                                onClick={() => this.removeFormFields(index)}
                              >
                                <img src={deleteicon} alt="Delete" />
                              </button>
                            )}
                            {/* ) : null} */}
                          </div>
                          <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 pd-l-70 pd-r-10">
                            <label className="form-label text-left pd-l-70 mb-0">
                              Task
                            </label>
                          </div>

                          {element.task_name !== undefined &&
                            element.task_name.map((el, indexes) => {
                              const toolbarId = `toolbar-${index}-${indexes}`;
                              return (
                                <div
                                  className="row align-items-basline flex-wrap m-0 pd-t-0 pd-b-0 pd-l-70 pd-r-0 w-100"
                                  key={indexes}
                                >
                                  <div className="col-md-8 col-lg-8 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-10">
                                    <label className="form-label text-left mg-b-0"></label>
                                    {el.viewMode == false ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: this.modifyTaskName(
                                            el.task_name ? el.task_name : ""
                                          ),
                                        }}
                                        className="task-name-span text_para_inn"
                                        onClick={() =>
                                          this.handleChangeTask(index, indexes)
                                        }
                                      />
                                    ) : (
                                      <>
                                        <div id={toolbarId}>
                                          <button className="ql-bold"></button>
                                          <button className="ql-italic"></button>
                                          <button className="ql-underline"></button>
                                          <button className="ql-link"></button>
                                        </div>
                                        <ReactQuill
                                          ref={(el) => {
                                            if (!this.quillRefs[index])
                                              this.quillRefs[index] = [];
                                            this.quillRefs[index][indexes] = el;
                                          }}
                                          theme="snow"
                                          name="task_name"
                                          value={el.task_name ?? ""}
                                          onChange={(l) =>
                                            this.handleChangeQuill(
                                              index,
                                              indexes,
                                              l
                                            )
                                          }
                                          modules={modules(toolbarId, () =>
                                            this.handleLink(index, indexes)
                                          )}
                                          formats={global.formats}
                                          className="custom_editor_style"
                                        />
                                        {this.state.showModal && (
                                          <LinkModal
                                            onClose={this.handleModalClose}
                                            onSubmit={this.handleModalSubmit}
                                            onRemove={this.handleModalRemove}
                                            url={this.state.url}
                                            text={this.state.text}
                                            isEditing={this.state.isEditing}
                                            showModal={this.state.showModal}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="col-lg-3 align-items-center pd-t-10 pd-l-5 pt-l-7">
                                  <div className="checklist-custom-btnBox">
                                    {/* && (global.userData.role_id === 1 || global.userData.role_id === 2 || global.userData.role_id === 5)  */}
                                    {global.userPermissions.checkPermission(
                                      "checklist-templates-add"
                                    ) &&
                                      (indexes ? (
                                        <div className="trash-btnBox">
                                          <button
                                            type="button"
                                            className="button remove deleteCat  new_btn_add"
                                            onClick={() =>
                                              this.removeFormFieldsTask(
                                                index,
                                                indexes
                                              )
                                            }
                                          >
                                            <img
                                              src={deleteicon}
                                              alt="Delete"
                                            />
                                          </button>
                                          {this.state.slug !== undefined ? (
                                            <button
                                              className="button addCat new_btn_add"
                                              type="button"
                                              onClick={() =>
                                                this.handleChangeTask(
                                                  index,
                                                  indexes
                                                )
                                              }
                                            >
                                              <img
                                                src={EditpenIcon}
                                                alt="Add"
                                              />{" "}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ) : null)}
                                      {indexes  === element.task_name.length - 1  ? (
                                       <button
                                              className="button addCat new_btn_add"
                                              type="button"
                                              onClick={() =>
                                                this.addFormFieldsTask(
                                                  index,
                                                  indexes
                                                )
                                              }
                                            >
                                              <img src={pluswIcon} alt="Add" />{" "}
                                            </button>
                                        ) : ''}
                                    {global.userPermissions.checkPermission(
                                      "checklist-templates-add"
                                    ) &&
                                      (global.userData.role_id === 1 ||
                                      global.userData.role_id === 2 ||
                                      global.userData.role_id === 5 ? (
                                        indexes ? null : (
                                          <div className="add_btn_fst">
                                            {/* <button
                                              className="button addCat new_btn_add"
                                              type="button"
                                              onClick={() =>
                                                this.addFormFieldsTask(
                                                  index,
                                                  indexes
                                                )
                                              }
                                            >
                                              <img src={pluswIcon} alt="Add" />{" "}
                                            </button> */}
                                            <button
                                              type="button"
                                              className="button deleteCat new_btn_add"
                                              onClick={() =>
                                                this.removeFormFieldsTask(
                                                  index,
                                                  indexes
                                                )
                                              }
                                            >
                                              <img
                                                src={deleteicon}
                                                alt="Delete"
                                              />
                                            </button>
                                            {this.state.slug !== undefined ? (
                                              <button
                                                className="button addCat new_btn_add"
                                                type="button"
                                                onClick={() =>
                                                  this.handleChangeTask(
                                                    index,
                                                    indexes
                                                  )
                                                }
                                              >
                                                <img
                                                  src={EditpenIcon}
                                                  alt="Add"
                                                />{" "}
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )
                                      ) : (
                                        ""
                                      ))}
                                  </div>
                                </div>
                                </div>
                              );
                            })}
                        </div>
                      ))}

                      <div className="row align-items-basline flex-wrap m-0 pd-t-25 pd-b-0 pd-l-70 padding-right-72">
                        <div className="col-md-10 col-lg-10 mg-t-5 mg-md-t-0 mg-b-15 pd-b-1 pd-l-70 pd-r-70 d-flex align-items-center justify-content-between ">
                          <div className="d-flex align-items-center justify-content-start">
                            <label className="form-label text-left mg-b-0">
                              {this.state.status_id ? "Active" : "Inactive"}{" "}
                            </label>
                            <div
                              className={
                                this.state.status_id
                                  ? "az-toggle on"
                                  : "az-toggle"
                              }
                              onClick={() =>
                                this.setState({
                                  status_id: !this.state.status_id,
                                })
                              }
                            >
                              <span></span>
                            </div>
                          </div>
                          <div className="col-lg-3 float-right p-0">
                          {global.userPermissions.checkPermission(
                            "checklist-templates-add"
                          ) && (
                            // { global.userData.role_id === 1 || global.userData.role_id === 2 || global.userData.role_id === 5
                            // ?
                            <button
                              type="button"
                              className="gf-btn-lg-stroke float-right button "
                              onClick={() => this.addFormFields()}
                            >
                              add category
                            </button>
                            // : ""
                          )}
                        </div>
                          {/* <button type="button" onClick={this.saveOrUpdateTemplate} className="gf-btn-lg-outline mg-r-5"> + Add Task </button> */}
                        </div>
                      </div>
                      <div className="row row-xs align-items-center flex-wrap m-0 pd-t-30 pd-b-0 pd-l-80 pd-r-95">
                        <div className="col-md-10 btnNavigate d-flex align-items-center justify-content-between flex-row flex-wrap-bothBtn p-0">
                          {this.state.slug !== undefined &&
                            global.userPermissions.checkPermission(
                              "checklist-templates-delete"
                            ) && (
                              <button
                                type="button"
                                className="detlet-text mg-r-20 pl-0 "
                                onClick={() => {
                                  this.opendeleteModal(this.state.propertySlug);
                                }}
                              >
                                Delete Template
                              </button>
                            )}

                          <div className="d-flex align-items-center pd-r-65">
                            <Link to="/templates">
                              {" "}
                              <button
                                type="button"
                                className="gf-btn-lg-outline mg-r-7"
                              >
                                {" "}
                                Cancel{" "}
                              </button>
                            </Link>
                            {this.state.slug == undefined &&
                              global.userPermissions.checkPermission(
                                "checklist-templates-add"
                              ) && (
                                <button
                                  type="button"
                                  onClick={this.saveOrUpdateTemplate}
                                  className="gf-btn-lg-stroke mg-l-2"
                                >
                                  {this.state.isSubmit ? global.loader : "ADD"}
                                </button>
                              )}
                            {this.state.slug !== undefined &&
                              global.userPermissions.checkPermission(
                                "checklist-templates-update"
                              ) && (
                                <button
                                  type="button"
                                  onClick={this.saveOrUpdateTemplate}
                                  className="gf-btn-lg-stroke mg-l-2"
                                >
                                  {this.state.isSubmit
                                    ? global.loader
                                    : "Update"}
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ht-50"></div>
            </div>
          </div>
        </div>
        <Modal show={this.state.deleteModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              <h3>Delete Template?</h3>
              <p className="pd-l-50 pd-r-50 pd-b-20 model_first_p">
                Do you really want to delete this template? This process cannot
                be undone.
              </p>
              <div className="d-flex cnt_center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteChecklist(this.state.slug)}
                  disabled={this.state.isDelete ? true : false}
                >
                  {this.state.isDelete ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default TemplatesForm;

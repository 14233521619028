////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Recent audit                            //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class RecentAudits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    auditlist: this.props.auditlist ? this.props.auditlist :[],
      
    };

  }

  render() {
    const { isLoading, auditlist } = this.props;
    return (
      <div className="card card-dashboard-sixteen">
        <div className="card-header">
          <h6 className="card-title tx-14 mg-b-0">
            Recent Audits{" "}
            {global.userPermissions.checkPermission("audits-list") && auditlist.length > 0 && (
            <Link to="/audits" className="btn_view">
              View More
            </Link>
            )}
          </h6>
        </div>
        <div className="card-body custom-table-box">
          <div className={`table-responsive ${auditlist.length === 0 ? 'no-record-data' : ''}`}>
            <table className="table mg-b-0">
              <tbody>
                {isLoading ? (
                  // Display skeleton loader when data is still loading
                  Array(2).fill().map((_, index) => (
                    <tr key={`skeleton_${index}`}>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={150} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={100} />
                        </small>
                      </td>
                      <td>
                        <h6 className="mg-b-0">
                          <Skeleton width={100} />
                        </h6>
                        <small className="tx-11">
                          <Skeleton width={80} />
                        </small>
                      </td>
                    </tr>
                  ))
                ) : auditlist.length > 0 ? (
                  auditlist.map((el, index) => (
                    <tr key={"audit" + index}>
                      <td>
                        <Link 
                          to={`/audits/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          #{el.audit_no ? el.audit_no : ""}
                        </h6>
                        <small className="tx-11">
                          {el.property_name ? el.property_name : ""}
                        </small>
                        </Link>
                      </td>
                      <td>
                      <Link 
                          to={`/audits/${el.slug}`} className="link-style"
                        >
                        <h6 className="mg-b-0 ">
                          {el.completed_on
                            ? Moment(el.completed_on).format("DD MMM, YY - hh:mm A")
                            : ""}
                        </h6>
                        <small className="tx-11">
                          {el.auditor ? el.auditor.full_name : ""}
                        </small>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="no-recrod-found" key="1">No record found</div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default RecentAudits;

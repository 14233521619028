////////////////////////////////////////////////////////////
//  Program: Template List.jsx                            //
//  Application:  Template  List                          //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-03-31                                      //
////////////////////////////////////////////////////////////
import React, { Component } from 'react';
import ReactDatatable from "@ashvin27/react-datatable"
import SimpleReactValidator from "simple-react-validator"
import ChecklistService from "../../services/ChecklistService"
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";

export class Templates extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      is_inactive: false,
      tableQueryString: '',
      customQueryString: '',
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: '',
      loading: false,
      total_record: 0,
      zoneTab: false,
      records: [],
      config: {
        sort: {
          column: "checklist_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by templates",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "checklist_name",
          text: "Templates",
          className: "template_name hand-cursor",
          sortable: true,
          cell: (record) => {

            return record.checklist_name ? record.checklist_name : ''
          },
        },

        {
          key: "zone_type",
          text: "Zone",
          className: "zone_type hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.zone_type === 0 ? "All" : record && record.zone ? record.zone.zone_name : ''
          },
        },

        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? <b className="status-active">Active</b> :<b className="status-inactive">Inactive</b>;
          },
        },
      ],
      countryList: [],
      stateList: [],
      cityList: [],
      country_id: "",
      state_id: "",
      city_id: "",
      isFirstLoad: true,
      lastQueryString:"",
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.closeModalPerform = this.closeModalPerform.bind(this);
  }

  async closeModalPerform(type) {
    this.setState({
      zoneTab: true,
      showChecklistModal: false,    
    });
  }
  componentDidMount() {
    this.getData();
  }
  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    var list = [];
    let res = await ChecklistService.getTemplateList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
    }
    this.setState({
      loading: false,
      records: list,
      total_record: res.data ? res.data.total : '',
      showSpinner: false
    });
  }

  customFilter() {
    let queryString = "country_slug=" + this.state.country_id + "&city_slug=" + this.state.city_id + "&state_slug=" + this.state.state_id;
    this.setState({ lastQueryString: queryString });
    this.setState({ tableQueryString: '', customQueryString: queryString });
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

  resetData() {
    this.setState({ country_slug: "", lastQueryString: "", });
    this.setState({ city_slug: "" });
    this.setState({ state_slug: "" });
    this.getData();
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  }

  tableChangeHandler = (data) => {
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          }
          params.append(newKey, data[key]);
        }
      });
      
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
  
      this.getData(`${queryString}${this.state.lastQueryString}`);
    }
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/templates/update/" + data.slug);
  };
 
     
  render() {
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          <div className='container-fluid'>
            <div className="row row-xs">

              <div className="col-md-12">
                <div className="brandSection">

                  <div className="dashbaord-pageHeadertitle ">
                    <h3 className="flex-column">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h3>Inspection Templates</h3>
                        {global.userPermissions.checkPermission("checklist-templates-add") && (
                          <div className="btnNavigate d-flex justify-content-end">                          
                            <Link to={'/templates/add'} ><button className="gf-primary-btn-small-one mg-l-7"> Add Template</button></Link>
                          </div>
                        )}
                      </div>
                      <span>All Inspection Templates</span>
                    </h3>
                  </div>



                  <div className="col-md-12 pl-0 pr-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className="mg-tneg-4 brandList dashboardRightcard">
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>

     

      </div>
    )
  }
}

export default Templates

/*------------------------------------------------------------

Program: AppDownload.jsx
Application: Detailed Note of Privacy Notice 
Option: For Privacy Notice 
Developer: Chandra Shekhar
Date: 21-04-2023

---------------------------------------------------------------*/

import React, { Component } from "react";
import pcImg from "../../assets/images/gf-bot-new.png";
import linkImg from "../../assets/images/gf-bot-link.png";
import qrImg from "../../assets/images/qr-img.PNG";
import qrImgStg from "../../assets/images/GFBOT_stg_app_link.png";
import iosApp from "../../assets/images/ios-download.PNG";
import androidApp from "../../assets/images/android-download.PNG";
export class AppDownload extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <main className="app-download-sec">
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-6">
                <div className="or-cnt">
                  <div className="download_heading">
                    <h3>
                      DOWNLOAD <br />
                      <span>OUR APP</span>
                    </h3>
                  </div>
                  <div className="or_code_sec">
                    <div className="or_box">
                      <img src={qrImg} alt="" />
                      <a
                        href="#"
                        className="btn-bot-img-link"
                        onClick={() =>
                          window.open(
                            "https://apps.apple.com/us/app/gfbot/id6443468105",
                            "_blank"
                          )
                        }
                      >
                        <img src={iosApp} alt="" />
                      </a>
                    </div>
                    <div className="or_box">
                      <img src={qrImgStg} alt="" />
                      <a
                        href="#"
                        className="btn-bot-img-link"
                        onClick={() =>
                          window.open(
                            "https://drive.google.com/file/d/1lZdKKA5wBQL41jSNCVzWptWldLiAR6VZ/view?usp=sharing"
                          )
                        }
                      >
                        <img src={androidApp} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pc_img_center">
                <div className="gf-bot-img-sec">
                  <a
                    href="#"
                    className="btn-bot-img-link"
                    onClick={() =>
                      window.open("https://www.gfbot.com/", "_blank")
                    }
                  >
                    <img src={linkImg} alt="" />
                  </a>
                  <img src={pcImg} alt="" className="pc-app-img" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default AppDownload;
////////////////////////////////////////////////////////////
//  Program: EquipmentList.jsx                            //
//  Application:  Equipment                               //
//  Option: For add or update Tower                       //
//  Developer: kiran singh                                //
//  Date: 2022-03-22                                      //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Alerts from "../common/Alerts";
import EquipmentService from "../../services/EquipmentService";
export class Equipment extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      property_slug: this.props.propertySlug,
      pathname: window.location.origin,
      sort_order: "",
      records: [],
      config: {
        sort: {
          column: "equipment_name",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by equipment",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "equipment_name",
          text: "Equipment",
          className: "equipment_name hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.equipment_name ? record.equipment_name : "";
          },
        },
        {
          key: "serial_number",
          text: "Equipment No.",
          className: "Floor hand-cursor",
          sortable: true,
          cell: (record) => {
            return record && record.serial_number ? record.serial_number : "";
          },
        },

        {
          key: "zone_type",
          text: "Zone",
          className: "zone_type hand-cursor",
          sortable: true,
          cell: (record) => {
            //return record.zone_type.zone_name;
            return record && record.zone_name ? record.zone_name : "";
          },
        },
        {
          key: "location_id",
          text: "Location",
          className: "location hand-cursor",
          sortable: true,
          cell: (record) => {
            // if (record.zone_type.zone_type_id === 1) {
            //   return record && record.room ? record.room.room_name : "";
            // } else if (record.zone_type.zone_type_id === 2) {
            //   return record && record.commercial ? record.commercial.commercials_zone_name : "";
            // } else if (record.zone_type.zone_type_id === 3) {
            //   return record && record.zone ? record.zone.equipment_zone_name : "";
            // } else {
            //   return false;
            // }

            return record && record.zone_types ? record.zone_types : "";
          },
        },
        {
          key: "floor_id",
          text: "Floor",
          className: "Floor hand-cursor",
          sortable: true,
          cell: (record) => {
            //return record && record.floor ? record.floor.floor_name: "";
            return record && record.floor_name ? record.floor_name : "";
          },
        },
        {
          key: "building_id",
          text: "Building",
          className: "Building hand-cursor",
          sortable: true,
          cell: (record) => {
            //return record && record.building ? record.building.building_name: "";
            return record && record.building_name ? record.building_name : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
      lastQueryString: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData(queryString = "") {
    this.setState({ loading: true });
    var list = [];
    var totalRecords = 0;
    let res = await EquipmentService.getEquipment(
      "property_slug=" + this.state.property_slug + "&" + queryString
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data ? res.data.total : "";
    }
    this.setState({
      loading: false,
      records: list,
      total_record: totalRecords,
    });
  }
  customFilter() {
    let queryString = "filter_value" + this.state.filter_value + "&is_custom=1";
    this.setState({ lastQueryString: queryString });
    this.setState({ tableQueryString: "", customQueryString: queryString });
    this.getData(queryString);
  }

  resetDate() {
    this.setState({ start_date: "", lastQueryString: "" });
    this.setState({ end_date: "" });
    this.getData();
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
    } else {
      this.setState({ showFilter: 1 });
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "sort_order") {
            data[key] = "asc";
          }
          return (
            encodeURIComponent(newKey) + "=" + encodeURIComponent(data[key])
          );
        }
      })
      .join("&");

    if (data.filter_value !== "") {
      this.setState({ tableQueryString: queryString });
    } else {
      queryString += "&" + this.state.customQueryString;
      this.setState({ tableQueryString: queryString });
    }

    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => this.getData(queryString),
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      this.setState({ is_filter_apply: queryString });
      this.getData(queryString + this.state.lastQueryString);
    }
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }

  getTitle() {
    return "Equipment";
  }

  setOpen(open, e) {
    this.setState({ open: open });
    this.setState({ eventKey: e.target.eventKey });
  }
  rowClickedHandler = (event, data, rowIndex) => {
    window.location.href =
      this.state.pathname +
      "/properties/view/" +
      this.state.property_slug +
      "/equipment/update/" +
      data.slug;
  };
  render() {
    return (
      <main className="p-0">
        <div
          className={`${
            global.userPermissions.checkPermission(
              "property-update-assigned-property"
            )
              ? "btnNavigate d-flex flex-column building-btn"
              : "d-none"
          }`}
        >
          {global.userPermissions.checkPermission(
            "property-update-assigned-property"
          ) && (
            <>
              {/* {global.userData.role_id === 1 || global.userData.role_id === 6 || global.userData.role_id ===  3 ? <> */}
              <Link
                to={
                  "/properties/view/" +
                  this.state.property_slug +
                  "/equipment/add"
                }
                className="w-100 d-flex justify-content-end"
              >
                <button className="gf-primary-btn-small-one ">
                  Add Equipment
                </button>
              </Link>
            </>
          )}
        </div>
        <div className="col-md-12 pl-0 pr-0">
          <Alerts
            show={this.state.showAlertModal}
            type={this.state.alertModalType}
            title={this.state.alertModalTitle}
            message={this.state.alertModalMessage}
          />
        </div>
        <div className="d-flex flex-column">
          <div className="row row-xs mg-t-0 pd-t-0">
            <div className="col-md-12">
              <div className="dashboardRightcard mt-top-7neg">
                {this.state.showFilter ? (
                  <Row className="align-items-center order_form">
                    <Col md={3} lg={5} xl={5} className="mb-md-0 mb-3 pl-0">
                      <input
                        name="filter_value"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Filter Value"
                        value={this.state.filter_value}
                        onChange={this.changeHandler}
                        maxLength="30"
                      />{" "}
                    </Col>
                    <Col md={5} lg={5} xl={5}>
                      <Row>
                        <button
                          onClick={this.customFilter}
                          className="btn-success mg-l-30 mg-r-20"
                        >
                          Search
                        </button>
                        <button
                          onClick={this.resetDate}
                          className="btn-success-outline"
                        >
                          Reset
                        </button>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <div className="mg-t-18 brandList">
                  <ReactDatatable
                    className="table no-wrap mg-b-30 pd-b-2"
                    config={this.state.config}
                    records={this.state.records}
                    columns={this.state.columns}
                    extraButtons={this.state.extraButtons}
                    dynamic={true}
                    loading={this.state.loading}
                    total_record={this.state.total_record}
                    onChange={this.tableChangeHandler}
                    onRowClicked={
                      global.userPermissions.checkPermission(
                        "property-update-assigned-property"
                      ) && this.rowClickedHandler
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ht-50"></div>
        </div>
      </main>
    );
  }
}

export default Equipment;

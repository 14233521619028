////////////////////////////////////////////////////////////
//  Program: Checklist List.jsx                           //
//  Application:  Checklist  List                         //
//  Option: List of Template                              //
//  Developer: kiran singh                                //
//  Date: 2022-04-08                                      //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import ChecklistService from "../../services/ChecklistService";
import PropertyService from "../../services/PropertyService";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import back from "./../../assets/images/back.svg";

import CheckListInfoTab from "./ChecklistInfoTab";
import CheckListRoomTab from "./CheckListRoomTab";
import CheckListCommercialTab from "./ChecklistCommercialTab";
import CheckListEquipmentZoneTab from "./ChecklistEquipmentZoneTab";
import CheckListEquipmentTab from "./ChecklistEquipmentTab";
import { Tab, Nav } from "react-bootstrap";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import PropertySelection from "../dashboard/PropertySelection";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color:
      state.isSelected && state.isFocused
        ? "white"
        : state.isSelected
        ? "black"
        : state.isFocused && "white",
    backgroundColor:
      state.isSelected && state.isFocused
        ? "gray"
        : state.isFocused && "gray",
    fontFamily: "Roboto-Regular",
    fontSize: "16px",
  }),
};

export class Cheklist extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      showBasicModal: false,
      showChecklistModal: false,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      page_number:"",
      showFilter: global.showFilter,
      pathname: window.location.origin,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      total_record: 0,
      records: [],
      TemplateList: [],
      zoneTypeList: [],
      isFilterTrue: false,
      showSpinner: false,
      perPage: 0,
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      selectedStatusList: [],
      config: {
        sort: {
          column: "created_at",
          order: "desc",
        },
        
        autoResetPage: true,
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by checklist",
        },
      },
      filename:"ddadadadad",
      extraButtons: [],
      columns: [
        {
          key: "checklist_name",
          text: "Checklist",
          className: "checklist_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.checklist_name ? record.checklist_name : "";
          },
        },
        {
          key: "zone_type",
          text: "Zone",
          className: "zone_type hand-cursor",
          sortable: false,
          cell: (record) => {
            return record && record.zone ? record.zone.zone_name : ''
          },
        },
        {
          key: "property_id",
          text: "Property",
          className: "property hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.property ? record.property.property_name : "";
          },
        },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1 ? (
              <b className="status-active">Active</b>
            ) : (
              <b className="status-inactive">Inactive</b>
            );
          },
        },
      ],
      isFirstLoad : true,
      lastQueryString: "",
      singleScreen: false,
      fistLoad: false,
      zoneTab: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeModalPerform = this.closeModalPerform.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.saveOrUpdatechecklist = this.saveOrUpdatechecklist.bind(this);
    this.handleChangeProperty = this.handleChangeProperty.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);
  }

  saveOrUpdatechecklist = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      this.setState({ isSubmit: true });
      let checklistSlug = this.state.checklist_slug;
      setTimeout(() => {
        this.setState({ showBasicModal: false });
        this.setState({ showChecklistModal: false });
        this.closeModal("basic");
        this.setState({ isSubmit: false });
        window.location.href =
          this.state.pathname + "/checklist/import/" + checklistSlug;
      }, 500);
    } else {
      this.validator.showMessages();
    }
  };
  closeModal(type) {
    if (type === "basic") {
      this.validator.hideMessages();
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.setState({
        showBasicModal: false,
        showChecklistModal: false,
        selectedChecklist: [],
        checklist_slug: "",
      });
    }
    this.setState({ showAlertModal: false });
  }
  async closeModalPerform(type) {
    if (type === "basic") {
      if (localStorage.getItem('outSidePorpertySlug') != "" && localStorage.getItem('outSidePorpertySlug') != undefined) {
        await this.setState({
          zoneTab: true,
          showChecklistModal: false,    
        });
      } else {
        if (localStorage.getItem('singlePropertyName') != "" && localStorage.getItem('singlePropertyName') != undefined) {
          this.setState({
            zoneTab: true,
            showChecklistModal:false,
          });
        } else {
          this.setState({
            zoneTab: false,
            // selectedProperty: [],
            showChecklistModal:false,       
          });
        }
      }
      
    }
  }
  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      this.setState({ zoneTypeList: global.templateOrCheckListzoneTypeList });
      this.getProperty();
      document.body.classList.add("bdy_over");
    }
  };

  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('checklistFilterState'));
    const savedFormattedData = JSON.parse(localStorage.getItem('formattedDataChecklist')); 
    if (savedFilterState) {
      this.setState({
        zone_type: savedFilterState.zone_type,
        property_slug: savedFilterState.property_slug,
        selectedZoneTypeList: savedFilterState.selectedZoneTypeList,
        selectedProperty: savedFilterState.selectedProperty,
        isFilterTrue: true,
        is_filter: savedFilterState.is_filter,
        formattedData: savedFormattedData || [],
      }, () => {
        let queryString =
          "&zone_type=" +
          (this.state.zone_type ? this.state.zone_type : '') +
          "&property_slug=" +
          (this.state.property_slug ? this.state.property_slug : '') +
          "&is_filter=1";
        this.getData(queryString);
        this.getTemplate();
        this.getProperty();
        this.setState({ tableQueryString: "", customQueryString: queryString, savedFilterState: savedFilterState });
      });
    } else {
      this.getData();
      this.getTemplate();
      this.getProperty();
      this.setState({ zoneTypeList: global.templateOrCheckListzoneTypeList });
    }
  }
  async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
    });
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getTemplate();
      this.getProperty();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";

    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
      };
      this.setState({zoneTab:true, property_slug:localStorage.getItem("singlePorpertySlug")})
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedProperty;
          if (queryString == "resetCall") {
            localSelectedProperty = [];
        }
        this.setState({ zoneTab: true })
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
          if (this.state.fistLoad === false) {
            queryString = "property_slug=" + propertySlug;
            this.setState({fistLoad:true});
          } else {
            queryString = queryString + "&property_slug=" + propertySlug;
          }
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
          };
          this.setState({zoneTab:true, property_slug:propertySlug})
        } else {
          localSelectedProperty = [];
          if (queryString == "resetCall") {
            localSelectedProperty = [];
          }
          
      }
      }
     
    }
    
    var list = [];
    let res = await ChecklistService.getCheckList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data ? res.data.data : [];
    }
    if (queryString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      records: list,
      total_record: res.data ? res.data.total : "",
      selectedProperty: localSelectedProperty,
      showSpinner: false
    });
  }
  async getTemplate(queryString = "") {
    var TemplateList = [];
    var TemplateListData = [];
    let res = await ChecklistService.getTemplateList(
      "is_import_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      TemplateList = res.data ? res.data : [];
    }
    if (TemplateList.length > 0) {
      TemplateList.forEach((item) => {
        TemplateListData.push({
          label: item.checklist_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      TemplateList: TemplateListData,
    });
  }
  customFilter() {
    let queryString = 
    "zone_type=" + (this.state.zone_type ? this.state.zone_type : '') +
    "&property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&page_size=" + (this.state.perPage ? this.state.perPage : '') +
    "&is_filter=1";
    if (this.state.perPage == this.state.total_record) {
      queryString += `&all_records=all`;
    }
    if (queryString !== "") {
      this.setState(
        {
          is_filter: 1,
          lastQueryString: queryString,
        },
        () => {
          const dataFiltered = JSON.stringify({
            zone_type: this.state.zone_type,
            property_slug: this.state.property_slug,
            selectedZoneTypeList: this.state.selectedZoneTypeList,
            selectedProperty: this.state.selectedProperty,
            is_filter: 1,
          })
          // Saving filter state to localStorage
          localStorage.setItem('checklistFilterState', dataFiltered);
          const result1 = JSON.parse(dataFiltered);
    
          const formattedData = [];
          if(result1?.selectedZoneTypeList?.label) {
            formattedData.push(`Zone: ${result1?.selectedZoneTypeList?.label}`);
          }
          if(result1?.selectedProperty?.label) {
            formattedData.push(`Property: ${result1?.selectedProperty?.label}`);
          } else {
            if (
              (this.state.singleScreenProperty && this.state.singleScreenProperty !== "All Properties") || 
              (localStorage.getItem("selectedPropertyName") && localStorage.getItem("selectedPropertyName") !== "All Properties")
            ) {
              const propertyName = this.state.singleScreenProperty 
                ? this.state.singleScreenProperty 
                : localStorage.getItem("selectedPropertyName");
              formattedData.push(`Property: ${propertyName}`);
            }
          }
          this.setState({ formattedData });
          localStorage.setItem('formattedDataChecklist', JSON.stringify(formattedData));

          // Fetch data with the new query string
          this.getData(queryString);
  
          if (this.state.showFilter === 1) {
            document.body.classList.remove("bdy_over");
          }
        }
      );
    }
    }

  resetData() {
    this.setState({ is_filter: 0 });
    this.setState({
      selectedZoneTypeList: "",
      selectedProperty: [],
      property_slug: "",
      zone_type: "",
      lastQueryString: "",
      singleScreenProperty: "",
      singleScreen: false,
      selectedStatusList: [],
      formattedData: [],
    });
    localStorage.removeItem('checklistFilterState');
    localStorage.removeItem('formattedDataChecklist');
    this.getData("resetCall");
  }
  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    this.setState({ template_slug: event.target.value });
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  tableChangeHandler = (data) => {
  // Show the spinner when the table change starts
  this.setState({ showSpinner: true });
  
    // Function to create query parameters
    const createQueryParams = (data) => {
      const params = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        if (key === "sort_order" && data[key]) {
          params.append("sort_order", data[key].order);
          params.append("sort_column", data[key].column);
        } else {
          let newKey = key;
          if (key === "page_number") {
            newKey = "page";
          } else if (key === "page_size") {
            this.setState({ perPage: data[key]});
          }
          params.append(newKey, data[key]);
        }
      });

      if (data.page_size == this.state.total_record) {
        params.append("all_records", "all");
      }
      
      return params.toString();
    };
    
    const pageSize = Number(data.page_size);
    const validPageSizes = [10,20, 50, 100];

    let possible = this.state.total_record / pageSize + (this.state.total_record % pageSize ? 1 : 0);

    if (data.page_number > possible || ( pageSize && !validPageSizes.includes(pageSize))) {
      data.page_number = 1; 
    }
    let queryString = createQueryParams(data);
  
    if (data.filter_value === "") {
      queryString += `&${this.state.customQueryString}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {  
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(
          () => {
            this.getData(queryString).finally(() => {
              // Hide the spinner after data is fetched
              this.setState({ showSpinner: false });
            });
          },
          global.filter_time
        ),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          page_size: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
      // Parse the last query string to update parameters
      const updatedParams = new URLSearchParams(this.state.lastQueryString);
    
      // Check if 'page_size' exists in the parameters
      if (updatedParams.has('page_size')) {
          updatedParams.delete('page_size');
          // Update state with modified parameters
          this.setState({ lastQueryString: updatedParams.toString() });
      }
      // Fetch data with the updated query string
      this.getData(`${queryString}${updatedParams.toString()}`).finally(() => {
        // Hide the spinner after data is fetched
        this.setState({ showSpinner: false });
      });
    }
  };

  rowClickedHandler = (event, data, rowIndex) => {
    if (data.is_checklist == 2) {
      this.props.history.push("/checklist/edit/" + data.slug);
    } else {
      this.props.history.push("/checklist/import/" + data.slug);
    }
  };
  customValidate() {
    let isValid = true;
    return isValid;
  }
  //zone type handle
  handleChangeZoneType(value) {
    let zone_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        zone_type.push(item.value);
      });
    }
    this.setState({
      selectedZoneTypeList: value,
      zone_type: value.value,
      zonename: value.label,
    });
  }
  //property type handle
  handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
   this.setState({
      selectedProperty: value,
      property_slug: value.value,
      template_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
      property_name: value.label,
    });
  }
  /* Hndlae chnage for perform index */
  async handleChangePropertyPerform(value) {
    this.setState({ zoneTab: false });
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    await this.setState({
      selectedProperty: value,
      property_slug: value.value,
      template_slug: value.value,
      singleScreen:
        localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== ""
          ? true
          : false,
      singleScreenProperty: value.label,
    });

    /* Location tab open when open when inspection perform */
    if (this.state.showChecklistModal === true) {
      this.setState({ zoneTab: true });
    }
  }

  //property type handle
  handleChangeChecklist(value) {
    let checklist_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        checklist_slug.push(item.value);
      });
    }

    this.setState({
      selectedChecklist: value,
      checklist_slug: value.value,
    });
  }
  inspectionFlow() {
    this.setState({ showChecklistModal: true })
    if(this.state.selectedProperty !== undefined) {
      this.setState({zoneTab:true})
    }
  }
  render() {

    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
                {/* <p className="pt-2">POWERED BY NCKE</p> */}
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>Checklists</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                      {global.userPermissions.checkPermission("inspection-add") && (
                        
                          <Button
                          onClick={() => this.inspectionFlow()}
                            className="gf-primary-btn-small-one mg-l-7"
                          >
                            Perform
                          </Button>
                        )}
                        {global.userPermissions.checkPermission("checklist-add") && (
                          <Link to={"/checklist/add"}>
                            <Button className="gf-primary-btn-small-one mg-l-7">
                              Add Checklist
                            </Button>
                          </Link>
                        )}
                        {global.userPermissions.checkPermission("checklist-add") && (
                          <Button
                            onClick={() =>
                              this.setState({ showBasicModal: true })
                            }
                            className="gf-primary-btn-small-one mg-l-7"
                          >
                            Import Template
                          </Button>
                        )}
                        <Button
                          onClick={this.filterToggle}
                          className="gf-primary-btn-small-one mg-l-7"
                        >
                          {this.state.showFilter === 1 ? "Filter" : "Filter"}
                        </Button>
                      </div>
                    </div>
                    <span>All Checklists</span>
                  </h3>
                </div>

                <div className="brandSection ">
                  {this.state.showFilter ? (
                    <Row className="align-items-start fliter_back">
                      <div className="order_form ">
                        <div className="d-flex justify-content-between align-items-center w-100 ">
                          <h3
                            className="hand-cursor back-size"
                            onClick={this.filterToggle}
                          >
                            <img src={back} alt="" />
                            Back
                          </h3>
                        </div>

                        <h3>Filter</h3>
                        <div className="filter-box-scroll">
                          <Row className="align-items-start justify-content-between m-0 p-0">
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5 mt-0">
                                Zone
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedZoneTypeList}
                                options={this.state.zoneTypeList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeZoneType(value)
                                }
                                defaultValue={this.state.selectedZoneTypeList}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Col
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-md-0 mb-3 pl-0"
                            >
                              <label className="form-label text-left mg-b-5 mg-b-5">
                                Property
                              </label>
                              <Select
                                className="multiselect new_drop"
                                menuPlacement="auto"
                                value={this.state.selectedProperty}
                                options={this.state.propertyList}
                                isSearchable={true}
                                onChange={(value) =>
                                  this.handleChangeProperty(value)
                                }
                                defaultValue={this.state.selectedProperty}
                                autoComplete="off"
                                getOptionValue={(option) => `${option.label}`}
                                styles={customStyles}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "#808080",
                                    primary50: "#808080",
                                    primary25: "#808080",
                                  },
                                })}
                              />
                            </Col>
                            <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                              <button
                                onClick={this.resetData}
                                className="btn-success-outline"
                              >
                                Reset
                              </button>
                              <button
                                onClick={this.customFilter}
                                className="btn-success mg-l-0 mg-r-20"
                              >
                                {this.state.isFilterTrue
                                  ? global.loader
                                  : "Search"}
                              </button>
                            </Row>
                          </Row>
                        </div>
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">

            <div className="brandList dashboardRightcard">
            <PropertySelection
                propertySlug={this.getPropertySlug.bind(this)}
                outSidePropertySlug={this.state.outSidePropertySlug}
              />
            {this.state.is_filter === 1 ? (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="checklist_table_heading">
                      {" "}
                      <span className="lspan">
                        {(this.state.formattedData && this.state.formattedData.length && this.state.formattedData.join(', ')) ? <> Search Applied On:&nbsp;{" "} </>: ''}
                        </span>
                        <span className="rspan">
                        {(this.state.formattedData && this.state.formattedData.length > 0) ? this.state.formattedData.join(', ') : ''}

                        {(this.state.formattedData && this.state.formattedData.length > 0) && (
                          <a
                            type="button"
                            onClick={this.resetData}
                            className="global-flt-clearBtn"
                          >
                            Clear
                          </a>
                        )}
                          </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
            ""
            )}
              {localStorage.getItem("outSidePorpertySlug") && (this.state.formattedData == undefined || this.state.formattedData.length == 0) ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On:&nbsp; </span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
             
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>

        <Modal className="per_checklist_modal" show={this.state.showChecklistModal} centered dialogClassName={(this.state.zoneTab === false ? "single_property_mod_dialog" : "per_checklist_modal_dialog")}>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">Perform Inspection</h3>
            <div className={(this.state.zoneTab === false ? "col-md-12" : "col-md-6")}>
            <label>Property</label>
            <Select
                className="multiselect new_drop"
                menuPlacement="auto"
                value={this.state.selectedProperty}
                options={this.state.propertyList}
                isSearchable={true}
                onChange={(value) =>
                  this.handleChangePropertyPerform(value)
                }
                defaultValue={this.state.selectedProperty}
                autoComplete="off"
                getOptionValue={(option) => `${option.label}`}
                styles={customStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary75: "#808080",
                    primary50: "#808080",
                    primary25: "#808080",
                  },
                })}
              />

            </div> 
            <div className={"col-md-12 mt-4" + (this.state.zoneTab === false ? " d-none" : "")}>
              {/* Nav Bar start */}
              {this.state.zoneTab === true ? (<>
                <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="info"
            >
            <div className="scrolling-carousel pd-l-0 checklist_scroll_carousel">
                <ScrollingCarousel rightArrow={true} leftArrow={true}>
                <div className="swipesection ">
                    <Nav variant="pills" className="">
                    <Nav.Item>
                        <Nav.Link
                        eventKey="info"
                        className=""
                        >
                        All
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="room"
                        className=""
                        >
                        Room
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="commercial-area"
                        className=""
                        >
                        Commercial Area
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="equipment-zone"
                        className=""
                        >
                        Equipment Zone
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                        eventKey="equipment"
                        className=""
                        >
                        Equipment
                        </Nav.Link>
                    </Nav.Item>
                    </Nav>
                </div>
                </ScrollingCarousel>
            </div>
              <Tab.Content>
                <Tab.Pane eventKey="info">
                  <div className="mg-t-15 mb-4">
                    <div className="tabNavigation">
                          <CheckListInfoTab property_slug={this.state.property_slug} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="room">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListRoomTab property_slug={this.state.property_slug} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="commercial-area">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListCommercialTab property_slug={this.state.property_slug} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="equipment-zone">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListEquipmentZoneTab  property_slug={this.state.property_slug} />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="equipment">
                  <div className="mg-t-18 mb-5">
                    <div className="tabNavigation">
                      <CheckListEquipmentTab property_slug={this.state.property_slug} />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            {/* Nav bar end */}
              </>):""
              }
           

            </div>
            <div className="cnt_center d-flex mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModalPerform("basic")}
              >
                Cancel
              </button>
             
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showBasicModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <span className="welcomeClose" onClick={() => this.closeModal()}>
              {global.closee}
            </span>

            <h3 className="text-center">Import from Template</h3>
            <label>Template</label>
            <Select
              className="multiselect new_drop"
              menuPlacement="auto"
              value={this.state.selectedChecklist}
              options={this.state.TemplateList}
              isSearchable={true}
              onChange={(value) => this.handleChangeChecklist(value)}
              defaultValue={this.state.selectedChecklist}
              autoComplete="off"
              getOptionValue={(option) => `${option.label}`}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary75: "#808080",
                  primary50: "#808080",
                  primary25: "#808080",
                },
              })}
            />
            {this.state.errors.checklist_slug ? (
              <div className="text-danger">
                {this.state.errors.checklist_slug}
              </div>
            ) : (
              this.validator.message(
                "template",
                this.state.checklist_slug,
                "required",
                { className: "text-danger" }
              )
            )}
            <div className="cnt_center d-flex mt-3">
              <button
                type="button"
                className=" m-0 btn btn-outline-light btn-block"
                onClick={() => this.closeModal("basic")}
              >
                Cancel
              </button>
              <button
                type="button"
                className=" m-0 btn btn btn-success btn-block"
                onClick={this.saveOrUpdatechecklist}
                disabled={this.state.isSubmit ? true : false}
              >
                {this.state.isSubmit ? global.loader : "CONTINUE"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Cheklist;

/*
 * Program: Mro.jsx
 * Application: Mro
 * Option: to List out of Mro Information
 * Developer: Chandra Shekhar
 * Date: 2022-04-04
 */
import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleReactValidator from "simple-react-validator";
import MroService from "../../services/MroService";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Moment from "moment";
import back from "./../../assets/images/back.svg";
import UserService from "../../services/UserService";
import Select from "react-select";
import MroCategoryService from "../../services/MroCategoryService";
import DatePicker from "react-datepicker";
import PropertyService from "../../services/PropertyService";
import PropertySelection from "../dashboard/PropertySelection";
export class Mro extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      showSpinner: false,
      total_record: 0,
      records: [],
      user_slug: "",
      userList: [],
      selectedUserList: [],
      mro_category_slug: "",
      mroCategory: [],
      selectedMroCategory: [],
      priority_type: "",
      priorityType: [],
      due_date: "",
      status_id: 1,
      statusList: [],
      selectedStatusList: [],
      lastQueryString: "",
      isFilterTrue: false,
      selectedUserListCreated:[],
      perPage: 0,
      statusFilter: this.props.match.params.status ? this.props.match.params.status : undefined,
      newStatusList: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      config: {
        sort: {
          column: "due_date",
          order: "asc",
        },
        key_column: "slug",
        page_size: global.page_size,
        length_menu: global.page_length_menu,
        show_length_menu: true,
        show_filter: global.show_filter,
        show_pagination: global.show_pagination,
        pagination: "advance",
        show_info: true,
        button: {
          excel: global.excel_button,
          print: global.print_button,
          extra: false,
        },
        language: {
          no_data_text: "No record found",
          filter: "Search by MRO no. and title",
        },
      },

      extraButtons: [],
      columns: [
        {
          key: "mro_number",
          text: "MRO No.",
          className: "mro_number hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.mro_number ? "#" + record.mro_number : "";
          },
        },
        {
          key: "title",
          text: "Title",
          className: "title hand-cursor mro_ttl_p",
          sortable: false,
          cell: (record) => {
            const note = record.title ? this.limitTextTo50Words(record.title) : "";
            return note;
          },
        },
        {
          key: "property_name",
          text: "Property",
          className: "email hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.property_name;
          },
        },
        {
          key: "due_date",
          text: "Due Date",
          className: "due_date hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.due_date
              ? Moment(record.due_date).format("DD MMM Y")
              : "";
          },
        },
        // {
        //   key: "zone_type",
        //   text: "Zone",
        //   className: "zone_type hand-cursor",
        //   sortable: false,
        //   cell: (record) => {
        //     return record.zone_type_string ? record.zone_type_string : "";
        //   },
        // },
        {
          key: "location",
          text: " Location",
          className: "location hand-cursor",
          sortable: false,
          cell: (record) => {
            if (record.zone_type === 1)
              return record.room_name ? record.room_name : "";
            else if (record.zone_type === 2)
              return record.commercials_zone_name
                ? record.commercials_zone_name
                : "";
            else if (record.zone_type === 3)
              return record.equipment_zone_name
                ? record.equipment_zone_name
                : "";
            else if (record.zone_type === 4)
              return record.equipment_name ? record.equipment_name : "";
            else return "All Zone";
          },
        },
        {
          key: "mro_created_at",
          className: "pl-2 hand-cursor",
          text: "Created On",
          sortable: true,
          cell: (record) => {
            return record.mro_created_at
              ? Moment(record.mro_created_at).format("DD MMM Y")
              : "";
          },
        },
        {
          key: "full_name_conducted",
          text: "Created By",
          className: "Conducted hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.full_name_conducted ? record.full_name_conducted : "";
          },
        },
        {
          key: "full_name",
          text: "Assigned to",
          className: "full_name hand-cursor",
          sortable: false,
          cell: (record) => {
            return record.full_name ? record.full_name : "";
          },
        },
        // {
        //   key: "priority_type",
        //   text: " Priority",
        //   className: "priority_type hand-cursor",
        //   sortable: true,
        //   cell: (record) => {
        //     return record.priority_type === 1
        //       ? "High"
        //       : record.priority_type === 2
        //       ? "Medium"
        //       : record.priority_type === 3
        //       ? "Low"
        //       : "";
        //   },
        // },
        {
          key: "status_id",
          text: "Status",
          className: "status_id hand-cursor",
          sortable: true,
          cell: (record) => {
            return record.status_id === 1
              ? "Backlog"
              : record.status_id === 2
              ? "Pending"
              : record.status_id === 3
              ? "Ongoing"
              : record.status_id === 4
              ? "Completed"
              : record.status_id === 5
              ? "Cancelled"
              : record.status_id === 6
              ? "Overdue"
              : "";
          },
        },
      ],
      isFirstLoad: true,
      singleScreen: false,
      fistLoad: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.filterToggle = this.filterToggle.bind(this);
    this.resetData = this.resetData.bind(this);
    this.customFilter = this.customFilter.bind(this);
    this.handleChangeUserListCreatedBy = this.handleChangeUserListCreatedBy.bind(this);
    this.getPropertySlug = this.getPropertySlug.bind(this);

  }

  componentDidMount() {
    const savedFilterState = JSON.parse(localStorage.getItem('mroFilterState'));
    const savedFormattedData = JSON.parse(localStorage.getItem('formattedDataMRO'));  
    if (savedFilterState) {
      if (this.state.statusFilter !== undefined) {
        var statuslocal = 0;
        if (this.state.statusFilter === "pending") {
          statuslocal = 2;
        }
        if (this.state.statusFilter === "on-going") {
          statuslocal = 3;
        }
        if (this.state.statusFilter === "overdue") {
          statuslocal = 6;
        }
        if (this.state.statusFilter === "completed") {
          statuslocal = 4;
        }
        if (this.state.statusFilter === "backlog") {
          statuslocal = 1;
        }
        if (this.state.statusFilter === "cancelled") {
          statuslocal = 5;
        }
        // this.setState({ status_id: statuslocal });
        let mroStatus = global.mroStatusList;
        var selectedDashboardStatus = [];
        mroStatus.map((i) => {
          if (i.value == statuslocal) {
            selectedDashboardStatus.push(i);
          }
        });
      }
      this.setState({
        property_slug: savedFilterState.property_slug,
        user_slug: savedFilterState.user_slug,
        mro_category_slug: savedFilterState.mro_category_slug,
        priority_type: savedFilterState.priority_type,
        zone_type_id: savedFilterState.zone_type_id,
        location_slug: savedFilterState.location_slug,
        status_id: statuslocal == undefined ? savedFilterState.status_id : statuslocal,
        user_slug_created: savedFilterState.user_slug_created,
        due_date: savedFilterState.due_date ? new Date(savedFilterState.due_date) : null,
        mro_created_at: savedFilterState.mro_created_at ? new Date(savedFilterState.mro_created_at) : null,
        selectedProperty: savedFilterState.selectedProperty,
        selectedUserList: savedFilterState.selectedUserList,
        selectedMroCategory: savedFilterState.selectedMroCategory,
        selectedPriorityType: savedFilterState.selectedPriorityType,
        selectedStatusList: selectedDashboardStatus == undefined ? savedFilterState.selectedStatusList : selectedDashboardStatus,
        selectedUserListCreated: savedFilterState.selectedUserListCreated,
        selecteLocationList: savedFilterState.selecteLocationList,
        isFilterTrue: true,
        module_search: savedFilterState.module_search,
        is_filter: savedFilterState.is_filter,
        formattedData: savedFormattedData || [],
      }, () => {
        let queryString =
          "property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
          "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
          "&is_filter=1&" +
          "mro_category_slug=" + (this.state.mro_category_slug ? this.state.mro_category_slug : '') +
          "&priority_type=" + (this.state.priority_type ? this.state.priority_type : '') +
          "&zone_type_id=" + (this.state.zone_type_id ? this.state.zone_type_id : '') +
          "&location_slug=" + (this.state.location_slug ? this.state.location_slug : '') +
          "&status_id=" + (this.state.status_id ? this.state.status_id : '') +
          "&created_by=" + (this.state.user_slug_created ? this.state.user_slug_created : '') +
          "&due_date=" + (this.state.due_date ? Moment(this.state.due_date).format("YYYY-MM-DD") : '') +
          "&mro_created_at=" + (this.state.mro_created_at ? Moment(this.state.mro_created_at).format("YYYY-MM-DD") : '');
        this.getData(queryString);
        this.getProperty();
        this.setState({ tableQueryString: "", customQueryString: queryString, savedFilterState: savedFilterState });  
      });
    } else {
      this.getProperty();
      this.handleStatusClick(2);
      const queryString = "status_id=2";
      this.getData(queryString);
    }
    
  }
  getPropertySlug(property_slug) {
    this.setState({ property_slug: property_slug });
    setTimeout(() => {
      this.getData();
      this.getProperty();
    }, 200);
    this.setState({
      unit_number: "",
      module_search: [],
      is_search: 0,
    });
  }
  modifyTaskName(taskName) {
    // Create a temporary element to manipulate the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = taskName;

    // Find all links in the element
    const links = tempElement.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    // Return the modified HTML
    return tempElement.innerHTML;
  }

  limitTextTo50Words(htmlContent) {
    // Parse HTML content to extract text
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const textContent = doc.body.textContent || "";

    // Limit the text content to 50 characters
    const limit = 30;
    let result = textContent.slice(0, limit);

    // Append "..." if the original content is longer than 50 characters
    if (textContent.length > limit) {
        result += '...';
    }

    return result;
  }

  async getData(queryString = "") {
    this.setState({ showSpinner: true });
    
    if (this.state.isFirstLoad) {
      this.setState({ loading: true, isFirstLoad: false });
    }
    
    // let localSelectedProperty = "";
    // if (localStorage.getItem("activePropertyCount") == 1) {
    //   localSelectedProperty = {
    //     label: localStorage.getItem("singlePropertyName"),
    //     value: localStorage.getItem("singlePorpertySlug"),
    //   };
    // } else {
    //   if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
    //     localStorage.getItem("outSidePorpertySlug") !== null &&
    //     localStorage.getItem("outSidePorpertySlug") !== "") {
    //     let propertySlug = localStorage.getItem("outSidePorpertySlug");
    //     let propertyName = localStorage.getItem("selectedPropertyName");
    //     queryString = "property_slug=" + propertySlug;
    //    localSelectedProperty = {
    //       label: propertyName,
    //       value: propertySlug,
    //     };
    //   }
    // }/
    let propertySlug = "";
    let propertyName = "";
    let localSelectedProperty = "";
    if (localStorage.getItem("activePropertyCount") == 1) {
      localSelectedProperty = {
        label: localStorage.getItem("singlePropertyName"),
        value: localStorage.getItem("singlePorpertySlug"),
        };
        this.getLocation("property_slug=" + localSelectedProperty);
    } else {
      if (this.state.singleScreen === true) {
        localSelectedProperty = this.state.selectedProperty;
        let resetCallString = queryString.split('&')[0];
          if (resetCallString == "resetCall") {
            localSelectedProperty = [];
          }
      } else {
        if (localStorage.getItem("outSidePorpertySlug") !== undefined &&
        localStorage.getItem("outSidePorpertySlug") !== null &&
          localStorage.getItem("outSidePorpertySlug") !== "") {
        propertySlug = localStorage.getItem("outSidePorpertySlug");
        propertyName = localStorage.getItem("selectedPropertyName");
        if (this.state.fistLoad === false) {
          queryString = "property_slug=" + propertySlug + "&status_id=" + this.state.status_id + "&sort_order=asc&sort_column=due_date";
          this.getLocation("property_slug=" + propertySlug);
          this.setState({fistLoad:true});
        } else {
          queryString = queryString + "&property_slug=" + propertySlug + "&status_id=" + this.state.status_id;
          this.getLocation("property_slug=" + propertySlug);
        }
         localSelectedProperty = {
          label: propertyName,
          value: propertySlug,
        };
        } else {
          localSelectedProperty = [];
          let resetCallString = queryString.split('&')[0];
          if (resetCallString == "resetCall") {
            localSelectedProperty = [];
          }
          const propertyName = localStorage.getItem("selectedPropertyName");
          if(propertyName === "All Properties")
          {
            queryString = queryString + "&status_id=" + this.state.status_id;
          }
        }
      }
     
    }	
    var list = [];
    var totalRecords = 0;
    let res = await MroService.getMroList(queryString);
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = res.data.total;
    }
    let resetCallString = queryString.split('&')[0];
        if (resetCallString !== "resetCall") {
      this.setState({ showFilter: false });
    }
    this.setState({
      loading: false,
      isFilterTrue: false,
      total_record: totalRecords,
      records: list,
      selectedProperty:localSelectedProperty,
      showSpinner: false
    });
  }

  // Get Users List
  async getUserList(queryString = "") {
    var userList = [];
    var userListData = [];
    let res = await UserService.getUserList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      userList = res.data ? res.data : [];
    }

    userList.forEach((item) => {
      //if (item.role_id >= global.roleTypeId) {
        userListData.push({
          label: item.full_name,
          value: item.slug,
        });
      //}
    });
    this.setState({ userList: userListData });
  }

  // Get MRO Category List
  async getMroCategoryList(queryString = "") {
    var mroCategoryList = [];
    var mroCategoryListData = [];
    let res = await MroCategoryService.getMroCategoryList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      mroCategoryList = res.data ? res.data : [];
    }
    if (mroCategoryList.length > 0) {
      mroCategoryList.forEach((item) => {
        mroCategoryListData.push({
          label: item.name,
          value: item.slug,
        });
      });
    }
    this.setState({ mroCategory: mroCategoryListData });
  }

  filterToggle = () => {
    if (this.state.showFilter === 1) {
      this.setState({ showFilter: 0 });
      document.body.classList.remove("bdy_over");
    } else {
      this.setState({ showFilter: 1 });
      this.setState({ priorityType: global.priorityType });
      this.setState({ statusList: global.mroStatusList });
      this.getUserList();
      this.getMroCategoryList();
      document.body.classList.add("bdy_over");
    }
  };

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
  };

  // Technician/User Change Handler
  handleChangeUserList(value) {
    let user_slug = [];
    if (value !== null && value.length > 0) {
      value.forEach((item, i) => {
        user_slug.push(item.value);
      });
    }
    this.setState({
      selectedUserList: value,
      user_slug: value.value,
      user_name: value.label,
    });
  }

  // MRO Category Change Handler
  handleChangeMroCategory(value) {
    let mro_category_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        mro_category_slug.push(item.value);
      });
    }
    this.setState({
      selectedMroCategory: value,
      mro_category_slug: value.value,
      mro_name: value.label,
    });
    this.setState({ confirm_back: 1 });
  }

  // Priority  Change Handler
  handleChangePriority(value) {
    let priority_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        priority_type.push(item.value);
      });
    }
    this.setState({
      selectedPriorityType: value,
      priority_type: value.value,
      priority_name1: value.label,
    });
  }

  // Status Change Handler
  // handleChangeStatus(value) {
  //   let status_id = [];
  //   if (value != null && value.length > 0) {
  //     value.forEach((item, i) => {
  //       status_id.push(item.value);
  //     });
  //   }
  //   this.setState({
  //     selectedStatusList: value,
  //     status_id: value.value,
  //     status_name: value.label,
  //   });
  // }

    // Created By/User Change Handler
    handleChangeUserListCreatedBy(value) {
      let user_slug_created = [];
      if (value !== null && value.length > 0) {
        value.forEach((item, i) => {
          user_slug_created.push(item.value);
        });
      }
      this.setState({
        selectedUserListCreated: value,
        user_slug_created: value.value,
        user_slug_name: value.label,
      });
    }


  customFilter() {
    let date = "";
    let date1 = "";
    if (this.state.due_date) {
      date = Moment(this.state.due_date).format("YYYY-MM-DD");
    }
    if (this.state.mro_created_at) {
      date1 = Moment(this.state.mro_created_at).format("YYYY-MM-DD");
    }
    let queryString =
    "property_slug=" + (this.state.property_slug ? this.state.property_slug : '') +
    "&user_slug=" + (this.state.user_slug ? this.state.user_slug : '') +
    "&is_filter=1&" +
    "mro_category_slug=" + (this.state.mro_category_slug ? this.state.mro_category_slug : '') +
    "&priority_type=" + (this.state.priority_type ? this.state.priority_type : '') +
    "&zone_type_id=" + (this.state.zone_type_id ? this.state.zone_type_id : '') +
    "&location_slug=" + (this.state.location_slug ? this.state.location_slug : '') +
    "&status_id=" + (this.state.status_id ? this.state.status_id : '') +
    "&created_by=" + (this.state.user_slug_created ? this.state.user_slug_created : '') +
    "&per_page=" + (this.state.perPage ? this.state.perPage : '') +
    "&due_date=" + (date ? date : '')+
    "&mro_created_at=" + (date1 ? date1 : '') +   
    "&sort_order=" + (this.state.sort_order ? this.state.sort_order : '') +
    "&sort_column=" + (this.state.sort_column ? this.state.sort_column : '');
    if (this.state.perPage == this.state.total_record) {
      queryString += `&all_records=all`;
    }
    if (queryString !== "") {
      this.setState({ lastQueryString: queryString });
      this.setState({ isFilterTrue: true });
      this.setState({ is_filter: 1 });
    }
    const dataFiltered = JSON.stringify({
      property_slug: this.state.property_slug,
      user_slug: this.state.user_slug,
      mro_category_slug: this.state.mro_category_slug,
      priority_type: this.state.priority_type,
      zone_type_id: this.state.zone_type_id,
      location_slug: this.state.location_slug,
      status_id: this.state.status_id,
      user_slug_created: this.state.user_slug_created,
      due_date: this.state.due_date,
      mro_created_at: this.state.mro_created_at,
      selectedProperty: this.state.selectedProperty,
      selectedUserList: this.state.selectedUserList,
      selectedMroCategory: this.state.selectedMroCategory,
      selectedPriorityType: this.state.selectedPriorityType,
      selectedStatusList: this.state.selectedStatusList,
      selectedUserListCreated: this.state.selectedUserListCreated,
      selecteLocationList : this.state.selecteLocationList,
      is_filter: 1  
    });
    localStorage.setItem('mroFilterState', dataFiltered);
    const result1 = JSON.parse(dataFiltered);
    
    const formattedData = [];
    if (result1?.selectedProperty?.label) {
      formattedData.push(`Property: ${result1?.selectedProperty?.label}`);
    } else {
      if (
        (this.state.singleScreenProperty && this.state.singleScreenProperty !== "All Properties") || 
        (localStorage.getItem("selectedPropertyName") && localStorage.getItem("selectedPropertyName") !== "All Properties")
      ) {
        const propertyName = this.state.singleScreenProperty 
          ? this.state.singleScreenProperty 
          : localStorage.getItem("selectedPropertyName");
        formattedData.push(`Property: ${propertyName}`);
      }
    }
    if (result1?.selecteLocationList?.label) {
      formattedData.push(`Location: ${result1?.selecteLocationList?.label}`);
    }
    if (result1?.selectedStatusList?.label) {
      formattedData.push(`Status: ${result1?.selectedStatusList?.label}`);
    }
    if (result1?.due_date) {
      const formattedDate = Moment(result1?.due_date).format("YYYY-MM-DD");
      formattedData.push(`Due Date: ${formattedDate}`);
    }
    if (result1?.mro_created_at) {
      const formattedDate = Moment(result1?.mro_created_at).format("YYYY-MM-DD");
      formattedData.push(`Created On: ${formattedDate}`);
    }
    if (result1?.selectedPriorityType?.label) {
      formattedData.push(`Priority: ${result1?.selectedPriorityType?.label}`);
    }
    if (result1?.selectedMroCategory?.label) {
      formattedData.push(`Category: ${result1?.selectedMroCategory?.label}`);
    }
    if (result1?.selectedUserList?.label) {
      formattedData.push(`Assigned To: ${result1?.selectedUserList?.label}`);
    }
    if (result1?.selectedUserListCreated?.label) {
      formattedData.push(`Created By: ${result1?.selectedUserListCreated?.label}`);
    }
    this.setState({ formattedData });
    localStorage.setItem('formattedDataMRO', JSON.stringify(formattedData));
    this.getData(queryString);
    if (this.state.showFilter === 1) {
      document.body.classList.remove("bdy_over");
    }
  }

   //get property data
   async getProperty(queryString = "") {
    var propertyList = [];
    var propertyListData = [];
    let res = await PropertyService.getPropertyList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
    }
    if (propertyList.length > 0) {
      propertyList.forEach((item) => {
        propertyListData.push({
          label: item.property_name,
          value: item.slug,
        });
      });
    }
    this.setState({
      propertyList: propertyListData,
    });
  }

  // Get Location List
  async getLocation(queryString = "") {
    this.setState({ selecteLocationList: [] });
    this.setState({ loading: true });
    var locationList = [];
    var locationListData = [];
    let res = await MroService.getLocationsFilter(
      (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationList = res.data ? res.data.data : [];
    }
    locationList.forEach((item) => {
      locationListData.push({
        label: item.name,
        value: item.slug,
        zone_type_id: item.zone_type_id,
      });
    });
    this.setState({ locationList: locationListData });
    this.setState({ loading: false });
  }

  handleChangeLocation(value) {
    let location_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_slug.push(item.value);
      });
    }
    this.setState({
      selecteLocationList: value,
      location_slug: value.value,
      zone_type_id: value.zone_type_id,
    });
    this.setState({ confirm_back: 1 });
  }
   //property type handle
   handleChangeProperty(value) {
    let property_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_slug.push(item.value);
      });
    }
    this.getLocation("property_slug=" + value.value);
    this.setState({
      selectedProperty: value,
      property_slug: value.value,
      singleScreen: (localStorage.getItem("outSidePorpertySlug") !== undefined &&
      localStorage.getItem("outSidePorpertySlug") !== null &&
        localStorage.getItem("outSidePorpertySlug") !== "" ? true:false),
      singleScreenProperty: value.label,
      property_name: value.label,
    });
  }

  resetData() {
    this.setState({ is_filter: 0 });
    this.setState({
      property_slug: "",
      due_date: "",
      mro_created_at: "",
      selectedUserList: [],
      selectedMroCategory: [],
      selectedPriorityType: [],
      selectedStatusList: [],
      selecteLocationList: [],
      user_slug: "",
      mro_category_slug: "",
      priority_type: "",
      status_id: 2,
      date: "",
      zone_type_id: "",
      location_slug: "",
      lastQueryString: "",
      selectedProperty: [],
      singleScreenProperty: "",
      singleScreen: false,
      created_by:'',
      selectedUserListCreated:[],
      formattedData: [],
    });
    localStorage.removeItem('mroFilterState');
    localStorage.removeItem('formattedDataMRO');
    let queryString = "resetCall&status_id=2";
    this.getData(queryString);  
  }
  tableChangeHandler = (data) => {
    this.setState({ showSpinner: true });
  
    // Preserve state between tab switches
    const createQueryParams = (data) => {
      let params = new URLSearchParams(this.state.lastQueryString || ""); // Ensure lastQueryString exists
  
      Object.keys(data).forEach((key) => {
        let newKey = key;
        let value = data[key];
  
        if (key === "sort_order" && value) {
          params.set("sort_order", value.order);
          params.set("sort_column", value.column);
        } else {
          if (key === "page_number") newKey = "page";
          if (key === "page_size") {
            newKey = "per_page";
            this.setState({ perPage: value });
          }
  
          if (value !== undefined && value !== null && value !== "") {
            params.set(newKey, value);
          } else {
            params.delete(newKey); // Remove empty parameters
          }
        }
      });
  
      if (data.page_size == this.state.total_record) {
        params.set("all_records", "all");
      }
  
      return params.toString();
    };
  
    const pageSize = Number(data.page_size);
    const validPageSizes = [10, 20, 50, 100];
    let possiblePages = Math.ceil(this.state.total_record / pageSize);
  
    if (data.page_number > possiblePages || !validPageSizes.includes(pageSize)) {
      data.page_number = 1; // Reset invalid page number
    }
  
    let queryString = createQueryParams(data);  
    if (this.state.lastQueryString !== "") {
      let params = new URLSearchParams(this.state.customQueryString);
      if (params.has("status_id")) {
        params.set("status_id", this.state.status_id);
      }
      const savedFilterState = JSON.parse(localStorage.getItem("mroFilterState"));
      if (savedFilterState) {
        if (params.has("property_slug")) {
          params.set("property_slug", savedFilterState.property_slug);
        }
      }
      queryString += `&${params.toString()}`;
    }
  
    this.setState({ tableQueryString: queryString });
  
    if (data.filter_value !== this.state.filter_value) {
      clearTimeout(this.state.filter_time);
      this.setState({
        filter_time: setTimeout(() => {
          this.getData(queryString).finally(() => {
            this.setState({ showSpinner: false });
          });
        }, global.filter_time),
        filter_value: data.filter_value,
      });
    } else {
      if (this.state.firstLoad === true) {
        queryString = new URLSearchParams({
          page: data.page_number,
          per_page: data.page_size,
          sort_order: data.sort_order.order,
          sort_column: data.sort_order.column,
        }).toString();
      }
  
      // Ensure 'per_page' is removed when updating query
      const updatedParams = new URLSearchParams(this.state.lastQueryString);
      if (updatedParams.has("per_page")) {
        updatedParams.delete("per_page");
        this.setState({ lastQueryString: updatedParams.toString() });
      }
  
      this.getData(`${queryString}`).finally(() => {
        this.setState({ showSpinner: false });
      });
    }
    this.setState({sort_order: data.sort_order.order, sort_column: data.sort_order.column});
  };
  
  

  rowClickedHandler = (event, data, rowIndex) => {
    this.props.history.push("/mros/update/" + data.slug);
  };

  customValidate() {
    let isValid = true;
    return isValid;
  }
  handleStatusClick(statusId) {
    this.setState(
      {
        status_id: statusId,
        selectedStatusList: [{ label: this.getStatusLabel(statusId), value: statusId }],
        isStatusFilter: true,
      },
      () => {
        this.customFilter();
      }
    );
  }
  
  getStatusLabel(statusId) {
    switch (statusId) {
      case 1:
        return "Backlog";
      case 2:
        return "Pending";
      case 3:
        return "Ongoing";
      case 4:
        return "Completed";
      case 5:
        return "Cancelled";
      case 6:
        return "Overdue";
      default:
        return "";
    }
  }
  

  render() {
    const statuses = [
      { id: 2, label: "Pending" },
      { id: 6, label: "Overdue" },
      { id: 3, label: "Ongoing" },
      { id: 1, label: "Backlog" },
      { id: 4, label: "Completed" },
      { id: 5, label: "Cancelled" },
    ];    
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "gray"
            : state.isFocused && "gray",
        fontFamily: "Roboto-Regular",
        fontSize: "16px",
      }),
    };
    return (
      <div>
        <main>
        {this.state.showSpinner && (
            <div className="spinnerLoader1">
              <div className="toploader1">
                <img src={require("../../assets/images/Loader-new.gif").default} alt="Loading" />
              </div>
            </div>
          )}
          {this.state.showSpinner}
          <div className="container-fluid">
            <div className="row row-xs">
              <div className="col-md-12">
                <div className="dashbaord-pageHeadertitle">
                  <h3 className="flex-column">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h3>MROs</h3>
                      <div className="btnNavigate d-flex justify-content-end">
                      {global.userPermissions.checkPermission("mros-add") && (
                        <Link to={"/mros/add"}>
                          <button className="gf-primary-btn-small-one mg-l-7">
                            Add MRO
                          </button>
                        </Link>
                        )}
                        <Link to={"/mros/category"}>
                          <button className="gf-primary-btn-small-one mg-l-7">
                            View Category
                          </button>
                        </Link>
                        <button
                          onClick={this.filterToggle}
                          className="gf-primary-btn-small-one mg-l-7"
                        >
                          {" "}
                          {this.state.showFilter === 1 ? "Filter" : "Filter"}
                        </button>
                      </div>
                    </div>
                    <span>All MROs</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                <div className="col-md-12">
                  <div className="scrolling-carousel pd-l-0">
                    <div className="status-buttons d-flex justify-content-start mt-3">
                      {statuses.map((status) => (
                        <button
                          key={status.id}
                          type="button"
                          className={`btn ${this.state.status_id === status.id ? 'active' : ''}`}
                          onClick={() => this.handleStatusClick(status.id)}
                        >
                          {status.label}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="ht-50"></div> */}
            </div>
          </div>
          <div className="container-fluid">
            <div className="brandList dashboardRightcard">
            <PropertySelection
                propertySlug={this.getPropertySlug.bind(this)}
                outSidePropertySlug={this.state.outSidePropertySlug}
              />
            {this.state.is_filter === 1 ? (
                <>
                  {/* <div className="col-xxl-12 col-sm-12 pd-lg-r-30 pd-xs-l-30 d-inline-flex align-items-start justify-content-end justify_mob_filter scrolling-carousel">
                    <div className="custm-filter">
                      <div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="checklist_table_heading">
                        {" "}
                        <span className="lspan">
                        {(this.state.formattedData && this.state.formattedData.length && this.state.formattedData.join(', ')) ? <> Search Applied On:&nbsp;{" "} </>: ''}
                        </span>
                        <span className="rspan">
                        {(this.state.formattedData && this.state.formattedData.length > 0) ? this.state.formattedData.join(', ') : ''}

                        {(this.state.formattedData && this.state.formattedData.length > 0) && (
                          <a
                            type="button"
                            onClick={this.resetData}
                            className="global-flt-clearBtn"
                          >
                            Clear
                          </a>
                        )}
                          </span>
                      {/* </div> */}
                    </div>
                    </div>
                  </div>
                </>
              ) : (
              ""
            )}
            {localStorage.getItem("outSidePorpertySlug") && (this.state.formattedData == undefined || this.state.formattedData.length == 0) ? (<>
                <div className="row">
                <div className="col-md-12">
                    <div className="checklist_table_heading"><span className="lspan">Search Applied On:&nbsp; </span><span className="rspan">{this.state.singleScreenProperty ? this.state.singleScreenProperty:localStorage.getItem("selectedPropertyName") }</span></div>
                </div>
              </div>
              </>) :""}
              <ReactDatatable
                className="table no-wrap mg-b-30 pd-b-2"
                config={this.state.config}
                records={this.state.records}
                columns={this.state.columns}
                extraButtons={this.state.extraButtons}
                dynamic={true}
                loading={this.state.loading}
                total_record={this.state.total_record}
                onChange={this.tableChangeHandler}
                onRowClicked={this.rowClickedHandler}
              />
            </div>
          </div>
        </main>
        <div className="brandSection">
          {this.state.showFilter ? (
            <Row className="align-items-start fliter_back">
              <div className="order_form">
                <div className="d-flex justify-content-between align-items-center w-100 ">
                  <h3
                    className="hand-cursor back-size"
                    onClick={this.filterToggle}
                  >
                    <img src={back} alt="" />
                    Back
                  </h3>
                </div>
                <h3>Filter</h3>
                <div className="filter-box-scroll">
                <Row className="align-items-start justify-content-between m-0 p-0">

                <Col
                            md={12}
                            lg={12}
                            xl={12}
                            className="mb-md-0 mb-3 pl-0"
                          >
                            <label className="form-label text-left mg-b-5 mt-0">
                              Property{" "}
                            </label>
                            <Select
                              className="multiselect new_drop"
                              menuPlacement="auto"
                              value={this.state.selectedProperty}
                              options={this.state.propertyList}
                              isSearchable={true}
                              onChange={(value) =>
                                this.handleChangeProperty(value)
                              }
                              defaultValue={this.state.selectedProperty}
                              autoComplete="off"
                              getOptionValue={(option) => `${option.label}`}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary75: "#808080",
                                  primary50: "#808080",
                                  primary25: "#808080",
                                },
                              })}
                            />
                          </Col>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-md-0 mb-3 pl-0"
                  >
                    <label className="form-label text-left mg-b-5">
                      Location{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selecteLocationList}
                      options={this.state.locationList}
                      isSearchable={true}
                      onChange={(value) =>
                        this.handleChangeLocation(value)
                      }
                      defaultValue={this.state.selecteLocationList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col>
                  {/* <Col md={12} lg={12} xl={12} className="mb-md-0 mb-3 pl-0">
                    <label className="form-label text-left mg-b-5">
                      Status{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedStatusList}
                      options={this.state.statusList}
                      isSearchable={true}
                      onChange={(value) => this.handleChangeStatus(value)}
                      defaultValue={this.state.selectedStatusList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col> */}
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-md-0 mb-3 pl-0 pb-"
                  >
                    <label className="form-label text-left mg-b-5">
                      Due Date{" "}
                    </label>
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="due_date"
                      placeholderText="dd-mm-yy"
                      dateFormat="dd MMM yy"
                      selected={this.state.due_date}
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "due_date",
                            value: data,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-md-0 mb-3 pl-0 pb-"
                  >
                    <label className="form-label text-left mg-b-5">
                    Created On{" "}
                    </label>
                    <DatePicker
                      className="form-control max_width_100"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      autoComplete="off"
                      yearDropdownItemNumber={global.yearDropdownItemNumber}
                      name="mro_created_at"
                      placeholderText="dd-mm-yy"
                      dateFormat="dd MMM yy"
                      selected={this.state.mro_created_at}
                      onChange={(data) =>
                        this.changeHandler({
                          target: {
                            type: "date",
                            name: "mro_created_at",
                            value: data,
                          },
                        })
                      }
                    />
                  </Col>
                  {/* <Col md={12} lg={12} xl={12} className="mb-md-0 mb-3 pl-0 ">
                    <label className="form-label text-left mg-b-5">
                      Priority{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedPriorityType}
                      options={this.state.priorityType}
                      isSearchable={true}
                      onChange={(value) => this.handleChangePriority(value)}
                      defaultValue={this.state.selectedPriorityType}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col> */}
                  <Col md={12} lg={12} xl={12} className="mb-md-0 mb-3 pl-0 ">
                    <label className="form-label text-left mg-b-5">
                      Category{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedMroCategory}
                      options={this.state.mroCategory}
                      onChange={(value) => this.handleChangeMroCategory(value)}
                      defaultValue={this.state.selectedMroCategory}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col>
                  <Col md={12} lg={12} xl={12} className="mb-md-0 mb-3 pl-0">
                    <label className="form-label text-left mg-b-5">
                      Assigned To{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedUserList}
                      options={this.state.userList}
                      onChange={(value) => this.handleChangeUserList(value)}
                      defaultValue={this.state.selectedUserList}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col>
                  <Col md={12} lg={12} xl={12} className="mb-md-0 mb-3 pl-0">
                    <label className="form-label text-left mg-b-5">
                      Created By{" "}
                    </label>
                    <Select
                      className="multiselect new_drop"
                      menuPlacement="auto"
                      value={this.state.selectedUserListCreated}
                      options={this.state.userList}
                      onChange={(value) => this.handleChangeUserListCreatedBy(value)}
                      defaultValue={this.state.selectedUserListCreated}
                      autoComplete="off"
                      getOptionValue={(option) => `${option.label}`}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#808080",
                          primary50: "#808080",
                          primary25: "#808080",
                        },
                      })}
                    />
                  </Col>
                  <Row className="row col-md-12 mg-t-30 justify-content-between w-100 pr-0">
                    <button
                      onClick={this.resetData}
                      className="btn-success-outline"
                    >
                      Reset
                    </button>
                    <button
                      onClick={this.customFilter}
                      className="btn-success mg-l-0 mg-r-20"
                    >
                      {this.state.isFilterTrue ? global.loader : "Search"}
                    </button>
                  </Row>
                </Row>
                </div>
              </div>
            </Row>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Mro;


import React, { Component } from "react";
import PropTypes from "prop-types";
import HtmlTemplate from "./HtmlTemplate";
import FileValidations from "./FileValidations";
import axios from "axios";
class FileWidget extends Component {
  a = [];
  constructor(props) {
    super(props);
    this.state = {
      test: "test state",
      loader: false,
      progress: 0,
      removedFiles: [],
    };
  }

  componentDidMount() {
    // this.setPercentBar = this.setPercentBar.bind(this);
  }

  render() {
    return (
      <HtmlTemplate
        uploadType={this.props.uploadType}
        fieldTitle={this.props.fieldTitle}
        fieldLabel={this.props.fieldLabel}
        labelClassName={this.props.labelClassName}
        inputClassName={this.props.inputClassName}
        helpText={this.props.helpText}
        sampleFileUrl={this.props.sampleFileUrl}
        fieldName={this.props.fieldName}
        allowedType={this.props.allowedType}
        maxFileSize={this.props.maxFileSize}
        maxFileSizeType={this.props.maxFileSizeType}
        maxFileAllowed={this.props.maxFileAllowed}
        uploadOn={this.props.uploadOn}
        refTableId={this.props.refTableId}
        displayFileName={this.props.displayFileName}
        documentTypeSlug={this.props.documentTypeSlug}
        mediaType={this.props.mediaType}
        sortOrder={this.props.sortOrder}
        statusId={this.props.statusId}
        resizeOptions={this.props.resizeOptions}
        checkValidation={this.props.checkValidation}
        destinationFolder={this.props.destinationFolder}
        uploadedFiles={this.props.uploadedFiles}
        deletedFiles={this.props.deletedFiles}
        onRemovedFiles={this.handleRemovedFiles}
        percentage={this.state.progress}
        accept={this.props.acceptOnly}
        displayPreviewStyle={this.props.displayPreviewStyle}
        drag={this.props.drag}
        dragText={this.props.dragText}
        newRelatedFilesDescChange={this.props.newRelatedFilesDescChange}
        currentFile={this.props.currentFile}
      />
    );
  }

  handleRemovedFiles = async (props) => {
    // console.log(props);
    //let removedFiles = this.state.removedFiles;
    await this.setState({
      removedFiles: [...this.state.removedFiles, props],
    });

    // await this.setState({ removedFiles: Object.assign({}, this.state.removedFiles, props) });
    // let removedFiles = this.state.removedFiles;
    // console.log(removedFiles);
    this.a.push(props);
    // console.log(this.a);
  };

  fileUploadFun = async (formID) => {
    let form = document.getElementById(formID);
    const allFiles = form.getElementsByClassName("file_widget_class");
    const validationResponse = await this.validateFiles(allFiles);
    if (validationResponse.validationStatus) {
      const response = await this.uploadFiles(allFiles, formID);
      return {
        status: true,
        response: response,
      };
    } else {
      return {
        status: false,
        response: validationResponse,
      };
    }
  };

  validateFiles = async (allFiles) => {
    let finalValidationResponse = [];
    let validationStatus = true;
    for (let i = 0; i < allFiles.length; i++) {
      let validationMessage = [];
      if (allFiles[i].attributes.allowedtype !== undefined) {
        const allowedType = allFiles[i].attributes.allowedtype.value;
        const maxFileSize = allFiles[i].attributes.maxfilesize.value;
        const maxFileSizeType = allFiles[i].attributes.maxfilesizetype.value;
        const maxFileAllowed = allFiles[i].attributes.maxfileallowed.value;
        const fieldName = allFiles[i].attributes.name.value;
        const currentFile = allFiles[i].attributes.currentFile.value;
        const newfilelength = allFiles[i].attributes.newfilelength.value;

        let validationRules = {
          allowedType: allowedType,
          maxFileSize: maxFileSize,
          maxFileSizeType: maxFileSizeType,
          maxFileAllowed: maxFileAllowed,
          fieldName: fieldName,
          currentFile: currentFile,
          selectedFileLength: newfilelength,
          // removedFileLength: allFiles[i].files.length,
        };
        //let allFilesValidation = [];
        const FileValidationsObj = new FileValidations();
        for (let j = 0; j < allFiles[i].files.length; j++) {
          const fileObject = allFiles[i].files[j];
          const validationResponse = await FileValidationsObj.checkValidation(
            fileObject,
            validationRules
          );
          if (!validationResponse.validationStatus) {
            validationStatus = false;
            validationMessage.push(validationResponse.validationMessage);
          }
        }

        let finalResponse = {
          validationMessage: validationMessage,
          fieldName: fieldName,
        };
        finalValidationResponse.push(finalResponse);
      }
    }

    finalValidationResponse.validationStatus = validationStatus;

    return finalValidationResponse;
  };

  parseResizeOptions = async (resizeOptionsList) => {
    let finalResizeOptions = [];
    let resizeOptions = resizeOptionsList.split("||");
    for (let m = 0; m < resizeOptions.length; m++) {
      let objectValue = {};
      let options = resizeOptions[m].split(",");
      for (let n = 0; n < options.length; n++) {
        let options2 = options[n].split(":");
        objectValue[options2[0]] = options2[1];
      }
      await finalResizeOptions.push(objectValue);
    }
    return finalResizeOptions;
  };

  uploadFiles = async (allFiles, formID, dec) => {
    let finalResponse = [];
    let inputData = [];

    // console.log(formID, "allFiles", allFiles);
    //@logic Loop through all the input type files
    for (let i = 0; i < allFiles.length; i++) {
      //@logic creating constant variables for storing props values.
      if (allFiles[i].attributes.allowedtype !== undefined) {
        const allowedType = allFiles[i].attributes.allowedtype.value;
        const maxFileSize = allFiles[i].attributes.maxfilesize.value;
        const maxFileSizeType = allFiles[i].attributes.maxfilesizetype.value;
        const maxFileAllowed = allFiles[i].attributes.maxfileallowed.value;
        const fieldName = allFiles[i].attributes.name.value;
        const resizeOptionsList = allFiles[i].attributes.resizeoptions.value;

        const checkvalidation = allFiles[i].attributes.checkvalidation.value;
        const uploadon = allFiles[i].attributes.uploadon.value;
        const refTableId = allFiles[i].attributes.refTableId.value;
        const displayFileName = allFiles[i].attributes.displayFileName.value;
        const documentTypeSlug = allFiles[i].attributes.documentTypeSlug.value;
        const mediaType = allFiles[i].attributes.mediaType.value;
        const sortOrder = allFiles[i].attributes.sortOrder.value;
        const statusId = allFiles[i].attributes.statusId.value;
        const destinationfolder =
          allFiles[i].attributes.destinationfolder.value;
        //@logic creating resize array with different size options given in props
        let finalResizeOptions = await this.parseResizeOptions(
          resizeOptionsList
        );
        //resizeOptions = resizeOptions.split(':');

        // let validationRules;
        let validationRules = {
          allowedType: allowedType,
          maxFileSize: maxFileSize,
          maxFileSizeType: maxFileSizeType,
          maxFileAllowed: maxFileAllowed,
        };

        //@logic now loop thorugh each uploaded files
        let allFilesData = [];
        for (let j = 0; j < allFiles[i].files.length; j++) {
          //@logic load percentbar component

          const fileObject = allFiles[i].files[j];

          //@logic file data array end
          let percentage = 0;
          const HEADER_TOKEN = localStorage.getItem("headerToken")
            ? localStorage.getItem("headerToken")
            : "";
          let axiosConfig = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + HEADER_TOKEN,
            },
            onUploadProgress: (progressEvent) => {
              percentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
              // console.log("percentage:", percentage);
              // this.setState({ progress: percentage })

              var progress = document.getElementById(
                "progress" + fieldName + j
              );
              if (progress !== null) {
                // var progressBarElement = document.getElementById(
                //   "progressBar" + fieldName + j
                // );
                // console.log("element", progressBarElement);

                progress.classList.remove("hidden");
                document.getElementById(
                  "progressBar" + fieldName + j
                ).style.width = percentage + "%";
                document.getElementById(
                  "progressBar" + fieldName + j
                ).innerHTML = percentage + " % ";
                // Do something with the progress details
              }
            },
          };

          /* now upload individual file on server */
          let path = process.env.REACT_APP_API_ENDPOINT + "v1/upload";
          let fileData = new FormData();
          fileData.append(fieldName, fileObject, fileObject.name);
          fileData.append("orgFileDestinationFolder", destinationfolder);
          fileData.append("fieldName", fieldName);
          fileData.append("uploadOn", uploadon);
          fileData.append("refTableId", refTableId);
          fileData.append("displayFileName", displayFileName);
          fileData.append("documentTypeSlug", documentTypeSlug);
          fileData.append("mediaType", mediaType);
          fileData.append("sortOrder", sortOrder);
          fileData.append("statusId", statusId);
          await axios
            .post(path, fileData, axiosConfig)
            .then((res) => {
              let uploadResponse = [];
              var element = document.getElementById(
                "progressBar" + fieldName + j
              );
              element.classList.remove("progress-bar-striped");
              element.classList.remove("progress-bar-animated");
              uploadResponse = {
                orgFileName: res.data?.originalName ?? res.data?.result?.originalName,
                newName: res.data?.newName ?? res.data?.result?.newName,
                fileStatus: res.status,
                fileDestination: destinationfolder,
                url :  res.data?.url ?? res.data?.result?.url,
                order: i,
              };
              allFilesData.push(uploadResponse);
            })
            .catch((err) => {
              // console.log("err", err);
            });
        }

        inputData = {
          fieldName: fieldName,
          uploadedTo: uploadon,
          refTableId: refTableId,
          displayFileName: displayFileName,
          documentTypeSlug: documentTypeSlug,
          mediaType: mediaType,
          sortOrder: sortOrder,
          statusId: statusId,
          checkValidation: checkvalidation,
          validationRules: validationRules,
          resizeOptions: finalResizeOptions,
          filesData: allFilesData,
        };
        await finalResponse.push(inputData);
      }
    }
    // return output
    return finalResponse;
  };
}

// Specifies the default values for props:
FileWidget.defaultProps = {
  uploadType: "single",
  fieldLabel: "",
  labelClassName: "",
  inputClassName: "",
  drag: false,
  preview: false,
  allowDelete: true,
  allowMultipleDelete: false,
  className: "",
  checkValidation: "true",
  allowedType: "", // string
  maxFileSize: 1000, // int max size
  maxFileSizeType: "mb", //enum (either kb or mb), in small case.
  maxFileAllowed: 5, // how many files allow to select.
  uploadOn: "s3",
  refTableId: "",
  displayFileName: "",
  documentTypeSlug: "",
  mediaType: "",
  sortOrder: "",
  statusId: "",
  helpText: "",
  sampleFileUrl: "",
  allowPartialSubmit: false,
  outputType: false,
  fileNamePrefix: "", // string
  resizeOptions: "", // string
};

FileWidget.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldTitle: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
};

export default FileWidget;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: Inspection Number card                             //
//  Application: Dashboard                                //
//  Option: to view Dashboard                             //
//  Developer: Kiran singh  						                  //
//  Date: 2024-03-14                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import InspectionIcon from '../../assets/images/inspection-icon.svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export class InspectionNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    userData: global.userData ? global.userData : {},
    inspection: this.props.inspection ? this.props.inspection :0,
      
    };

  }

  render() {
    const { isLoading, inspection } = this.props;
    const isPropertyUser = global.roleTypeId === 4;
    const Tag = isPropertyUser ? 'h6' : 'h2';
    const className = isPropertyUser ? 'property-user-title' : 'admin-user-title';
  
    return (
      <div className="col-md-12 col-12 mg-t-20">
        <div className="card card-dashboard-ratio">
          <div className="card-header">
            <h6 className="card-title tx-14 mg-b-0 dash_head">

              Inspections{" "}
              {global.userPermissions.checkPermission("inspection-list")  && inspection > 0 && (
              <Link to="/inspections" className="btn_view">
                View More
              </Link>
              )}
            </h6>
          </div>
          <div className="card-body justify-content-between">
            <div className="card-icon">
              {!isLoading ? (
                <img src={InspectionIcon} className="inspection-icon" />
              ) : (
                <Skeleton width={70} height={70} />
              )}
            </div>
            <Tag className={className}>
              {/* Show skeleton loader while data is loading */}
              {isLoading ? (
                <Skeleton width={50} height={30} />
              ) : (
                <>
                  <span className="total-title-inspection">Total Inspections</span>
                  {inspection !== 0 ? inspection : 0}
                </>
              )}
            </Tag>
          </div>
        </div>
      </div>
    );
  }
}
export default InspectionNumber;
